import React from "react";

// Customizable Area Start
import {
  Box,
  Switch,
  Typography,
  styled,InputBase
} from "@material-ui/core";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { ThemeProvider ,createTheme } from "@material-ui/core/styles";
import {
  lightTheme,
  darkTheme
} from "../../email-account-registration/src/EmailAccountRegistration.web";
// Customizable Area End

import NotificationsettingsController, {
  Props,
} from "./NotificationsettingsController";
import { ChevronLeft } from "@material-ui/icons";

export default class NotificationSettingWeb extends NotificationsettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    console.log(this.state.titleData[0],"STatedata")
    return (
      // Customizable Area Start
      <>
       <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>  
         <HamburgerMenu {...this.props} open={this.state.open} handleMenu={this.handleMenu}  themeDark={this.state.darkTheme} handleTheme={this.handleTheme} />
        <MainContainer>
        <Box style={{ paddingLeft: this.state.open?273:150, paddingRight: 48, transition: 'all 300ms linear' }}>
          <SubContainer>
              <Container>
              <Box>
                <TitleBox>
                  {this.state.titleData[0]?.attributes.title}
                </TitleBox>
                <SubTitleBox>
                  {this.state.titleData[0]?.attributes.description}
                </SubTitleBox>
              </Box>
              <div style={{ display: 'flex', gap: 26, alignItems: 'center' }}>
                <Box>
                    <SwitchTheme style={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'nowrap', height: 24 }}>
                      <ToggleTitle>Push</ToggleTitle>
                      <Switch
                        name="push"
                        onChange={(e)=>this.handleUpdateNotification({"push":e.target.checked})}
                        checked={this.state.togglePush}
                        data-test-id="toggle-field"
                      />
                    </SwitchTheme>
                  <Box>
                  <SwitchTheme style={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'nowrap', height: 24 }}>
                      <ToggleTitle>Email</ToggleTitle>
                      <Switch
                        name="email"
                        onChange={(e)=>this.handleUpdateNotification({"email":e.target.checked})}
                        checked={this.state.toggleEmail}
                        data-test-id="toggle-field2"
                      />
                    </SwitchTheme>
                  </Box>
                </Box>
                <Box style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
                  <Box>
                    <MainText>Schedule Summary</MainText>
                    <SubText className="day-dropdown" data-test-id="DropdownOpen" onClick={() => this.setState({dropdownOpen:!this.state.dropdownOpen})} style={{display: 'flex', alignItems: 'center', gap: 10, position: 'relative'}}>
                        <span style={{lineHeight: 1}}>{this.state.titleData[0]?.attributes.schedule_summary}</span>
                        <span  data-test-id="Dropdownopen"><ChevronLeft style={{transform:"rotate(-90deg)", width: 20, height: 16,color: "#1A469C"}}/></span> 
                        {this.state.dropdownOpen && (
                          <DropdownBox className="day-dropbox">
                              {[ 'Saturday','Sunday','Monday','Tuesday','Wednesday','Thursday','Friday'].map(day => (
                                <DayList 
                                  themeDark={this.state.darkTheme}
                                  key={day} 
                                  data-test-id="selectDay"
                                  className="dayList1" 
                                  onClick={() => this.handleDaySelection({"schedule_summary":day})}
                            >
                              {day}
                            </DayList>
                          ))}
                          </DropdownBox>
                        )}
                    </SubText>
                  </Box>
                  <div>
                  <MainText>Time Zone</MainText>
                  <SubText className="day-dropdown" data-test-id="TimeZoneDropdownOpen" onClick={() => this.handleZoneDropDown()} style={{display: 'flex', alignItems: 'center', gap: 10, position: 'relative'}}>
                        <span style={{lineHeight: 1}}>{this.state.titleData[0]?.attributes.time_zone}</span>
                        <span  data-test-id="ZoneDropdownopen"><ChevronLeft style={{transform:"rotate(-90deg)", width: 20, height: 16,color: "#1A469C"}}/></span> 
                        {this.state.timeZonedropdown && (
                          <DropdownBox className="day-dropbox">
                              {[ 'EST','CST','MST','PST'].map(timeZone => (
                                <DayList 
                                  themeDark={this.state.darkTheme}
                                  key={timeZone} 
                                  data-test-id="selectTimezone"
                                  className="dayList" 
                                  onClick={() => this.handleTimeZoneSelection({"time_zone":timeZone})}
                            >
                              {timeZone}
                            </DayList>
                          ))}
                          </DropdownBox>
                        )}
                    </SubText>
                  </div>
                  <div>
                    <MainText>CT time zone</MainText>
                    <SubText className="day-dropdown" data-test-id="Ct_TimeZoneDropdownOpen" onClick={() => this.handleCTZoneDropDown()} style={{display: 'flex', alignItems: 'center', gap: 10, position: 'relative'}}>
                        <span style={{lineHeight: 1}}>{this.state.titleData[0]?.attributes.ct_time}</span>
                        <span  data-test-id="CTZoneDropdownopen"><ChevronLeft style={{transform:"rotate(-90deg)", width: 20, height: 16,color: "#1A469C"}}/></span> 
                        {this.state.CTtimeZonedropdown && (
                          <DropdownBox className="day-dropbox dropdown-height">
                              {[ '00:00','00:15','00:30','00:45','01:00','01:15','01:30','01:45','02:00','02:15','02:30','02:45','03:00','03:15','03:30','03:45','04:00','04:15','04:30','04:45','05:00',
                                '05:15','05:30','05:45','06:00','06:15','06:30','06:45','07:00','07:15','07:30','07:45','08:00','08:15','08:30','08:45','09:00','09:15','09:30','09:45','10:00','10:15','10:30','10:45',
                                '11:00','11:15','11:30','11:45','12:00','12:15','12:30','12:45'
                              ].map(CT_timeZone => (
                                <DayList 
                                  themeDark={this.state.darkTheme}
                                  key={CT_timeZone} 
                                  data-test-id="selectTimezone1"
                                  className="dayList" 
                                  onClick={() => this.handleCT_TimeZoneSelection({"ct_time":CT_timeZone})}
                            >
                              {CT_timeZone}
                            </DayList>
                          ))}
                          </DropdownBox>
                        )}
                    </SubText>
                  </div>
                </Box>
              </div>
              </Container> 
          </SubContainer>

          <Box style={{ maxWidth: 1225, width: "100%", marginBottom: 20, display: 'flex', gap: 20, flexWrap: 'wrap' }}>
            {this.state.data.map((item: any) => (
              <MainBox>
                <TitleText>
                  {item.attributes.title}
                </TitleText>
                <SubTitleText>
                 {item.attributes.description}
                </SubTitleText>
                <Box style={{ display: "flex", gap: "10px", alignItems: "center", marginTop: 25, marginBottom: 16 }}>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <ToggleTitle>Push</ToggleTitle>
                    <SwitchTheme style={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'nowrap', height: 24 }}>
                      <Switch
                        data-test-id="toggle-field3"
                        name="push"
                        onChange={(e)=>this.toggledButton(item,{"push":e.target.checked})}
                        checked={item.attributes.push}
                      />
                    </SwitchTheme>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <ToggleTitle>Email</ToggleTitle>
                    <SwitchTheme style={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'nowrap', height: 24 }}>
                      <Switch
                        name="email"
                        onChange={(e)=>this.toggledButton(item,{"email":e.target.checked})}
                        data-test-id="toggle-field4"
                        checked={item.attributes.email}
                      />
                    </SwitchTheme>
                  </Box>
                </Box>
                <Box style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
                  <Box>
                    <MainText>Schedule Summary</MainText>
                    <SubText className="day-dropdown"  data-test-id="DropdownOpen1"  onClick={()=>this.handleClick(item.id)} style={{display: 'flex', alignItems: 'center', gap: 10, position: 'relative'}}>
                        <span style={{lineHeight: 1}}>{item.attributes.schedule_summary}</span>
                        <span  data-test-id="dropdown"><ChevronLeft style={{transform:"rotate(-90deg)", width: 20, height: 16,color: "#1A469C"}}/></span> 
                  {(this.state.dayDropdown ==item.id)&& (
                    <DropdownBox className="day-dropbox">
                         {['Saturday','Sunday','Monday','Tuesday','Wednesday','Thursday','Friday'].map(categoriesDay => (
                          <DayList 
                            themeDark={this.state.darkTheme}
                            key={categoriesDay} 
                            data-test-id="selectDay1"
                            className="dayList" 
                            onClick={() => this.handleCategoriesDay(item,{"schedule_summary":categoriesDay})}
                       >
                        {categoriesDay}
                      </DayList>
                     ))}
                    </DropdownBox>
                  )}
                    </SubText>
                  </Box>
                  <div>
                    <MainText>CT time zone</MainText>
                    <SubText className="day-dropdown" data-test-id="Ct_TimeDropdown" onClick={() => this.handleZoneClick(item.id)} style={{display: 'flex', alignItems: 'center', gap: 10, position: 'relative'}}>
                        <span style={{lineHeight: 1}}>{item.attributes.ct_time}</span>
                        <span  data-test-id="CTZoneDropdownopen"><ChevronLeft style={{transform:"rotate(-90deg)", width: 20, height: 16,color: "#1A469C"}}/></span> 
                        {(this.state.timeDropdown ==item.id)&& (
                          <DropdownBox className="day-dropbox dropdown-height">
                              {[ '00:00','00:15','00:30','00:45','01:00','01:15','01:30','01:45','02:00','02:15','02:30','02:45','03:00','03:15','03:30','03:45','04:00','04:15','04:30','04:45','05:00',
                                '05:15','05:30','05:45','06:00','06:15','06:30','06:45','07:00','07:15','07:30','07:45','08:00','08:15','08:30','08:45','09:00','09:15','09:30','09:45','10:00','10:15','10:30','10:45',
                                '11:00','11:15','11:30','11:45','12:00','12:15','12:30','12:45'
                              ].map(timeZone => (
                                <DayList 
                                  themeDark={this.state.darkTheme}
                                  key={timeZone} 
                                  data-test-id="selectTimeZone2"
                                  className="dayList" 
                                  onClick={() => this.handleTimeSelection(item,{"ct_time":timeZone})}
                            >
                              {timeZone}
                            </DayList>
                          ))}
                          </DropdownBox>
                        )}
                    </SubText>
                  </div>
                </Box>
              </MainBox>
            ))}
          </Box>
        </Box>
        </MainContainer>
       </ThemeProvider>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SwitchTheme = styled(Box)({
  "& .MuiSwitch-thumb": {
    backgroundColor: 'white',
    height: 16,
    width: 16,
    position: 'relative',
    top: 4,
    left: 0,
    boxShadow: 'none'
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    left: -8,
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: '#18408E !important',
    opacity: 1
  },
    '& .MuiSwitch-track': {
      backgroundColor: '#E2E8F0',
      opacity: 1,
      height: '18px',
borderRadius: '15px',
    },
    '& .MuiSwitch-root':{
      height:42,
      width: 50,
      paddingLeft: 6
    },
    '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
      backgroundColor: 'transparent !important'
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent !important'
    },
    '& .MuiTouchRipple-root': {
      display: 'none'
    }
  })
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: theme.palette.background.default,
  '& .MuiPaper-root': {
      backgroundColor: theme.palette.primary.main
  }
}))

const DropdownBox =styled(Box)(({theme})=>({
  position: 'absolute', 
  zIndex: 9999, 
  boxShadow: '0px 2px 4px 0px #00000026', 
  top: 28.3, 
  right: 20, 
  borderRadius: 20, 
  minWidth: 200, 
  padding:2,
  background: theme.palette.background.default,
  '&.dropdown-height': {
    height: 250,
    overflow: 'auto'
  }
}))

const SubContainer = styled(Box)(({ theme }) => ({
  maxWidth: 1225,
  width: "100%",
  backgroundColor: theme.palette.action.focus,
  borderRadius: 18,
  marginTop: 20,
  marginBottom: 20
}))

const Container = styled (Box)(({ theme })=>({
  padding: "26px 52px 26px 39px",
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  gap: "10px"
}))

const DayList = styled (Typography)(({ theme ,themeDark}: any)=>({
  padding: '13px 16px', 
  cursor: 'pointer',
  color: theme.palette.info.light,
  fontWeight: 400,
  fontSize: 14, 
  fontFamily: "Rubik",
  '&:hover':{
    background: themeDark? "#121B29":"#E8EDF5",
    color: themeDark ? "#70A1FF":"#1A469C",
    borderRadius:8
  }
}))

const TitleBox =styled (Typography)(({ theme })=>({
  fontWeight: 500, 
  fontSize: "30px",
  lineHeight: "40px",
  color: theme.palette.info.main, 
  fontFamily: "Rubik"
}))

const SubTitleBox = styled (Typography)(({ theme })=>({
  fontWeight: 400, 
  fontSize: "14px",
  lineHeight: "14px", 
  color: theme.palette.info.main,
  letterSpacing: 0.41, 
  fontFamily: "Rubik"
}))

const ToggleTitle = styled (Typography) (({theme}) => ({
  color: theme.palette.info.light, 
  fontWeight: 400, 
  fontSize: 16,
  lineHeight: "10px", 
  fontFamily: "Rubik"
}))

const MainText = styled (Typography)(({ theme })=>({
  fontWeight: 400,
  fontSize: 12, 
  fontFamily: "Rubik", 
  color: theme.palette.text.primary
}))

const SubText = styled (Typography)(({ theme })=>({
  borderRadius: 8,
  border: '1px solid #CBD5E1', 
  padding: '5px 15px', 
  color:  theme.palette.info.main, 
  lineHeight: 1, 
  fontWeight: 400, 
  fontFamily: "Rubik", 
  fontSize: 16,
  backgroundColor: theme.palette.background.default,
  cursor: 'pointer',
  // '& .day-dropbox': {
  //   display: 'none',
  // },
  '& .day-dropbox p': {
    cursor: 'pointer'
  },
  // '&.day-dropdown:hover .day-dropbox': {
  //   display: 'block'
  // },
}))

const SubInput= styled(InputBase)(({theme})=>({
  borderRadius: 8,
  border: '1px solid #CBD5E1', 
  padding: '0px 10px', 
  color:  theme.palette.info.main, 
  lineHeight: 1, 
  fontWeight: 400, 
  fontFamily: "Rubik", 
  fontSize: 16,
  backgroundColor: theme.palette.background.default,
  maxWidth: 93,
  height: 28,
  '& .MuiInputBase-input': {
    padding: 0
  }
}))

const MainBox = styled (Typography)(({ theme })=>({
  maxWidth: '49%', 
  minWidth: '45%',
  flex: 1, 
  padding: "34px 50px", 
  backgroundColor: theme.palette.action.focus, 
  borderRadius: 18
}))

const TitleText = styled (Typography)(({ theme })=>({
  fontWeight: 500, 
  fontSize: 18, 
  fontFamily: "Rubik", 
  color: theme.palette.info.main, 
}))

const SubTitleText = styled (Typography)(({ theme })=>({
  fontWeight: 400, 
  fontSize: 14, 
  fontFamily: "Rubik", 
  color: theme.palette.info.light,
  minHeight: 63
}))
// Customizable Area End
