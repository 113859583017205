Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "BreadcrumbNavigation";
exports.labelBodyText = "BreadcrumbNavigation Body";
exports.InputPlaceHolder = "Create Pages"
exports.BTNSubmit = "Submit"
exports.btnExampleTitle = "CLICK ME";
exports.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NCwiZXhwIjoxNjc1NjA1NzIzfQ.bOycOneJ13Y9SliH6mvwlRJtZuUQQ1ryuEUg_UbCs16iZc1KZhf40g6_A66dk_6_F2opqIjtPqW8soi-lNDd4Q";
// Customizable Area End