import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  Grid,
  Divider,
  Switch,
  ListItem,
  ListItemIcon, Dialog, DialogTitle,DialogActions, DialogContent
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ThemeProvider } from "@material-ui/core/styles";
import { lightTheme, BackButton, NextFormButton, ValidationSection, PasswordChecked, PasswordText, NextButton, darkTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { FieldModal } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import { ToastContainer } from "react-toastify";
import OtpInput from 'react-otp-input'
import { fullLogo, smallLogo, mobileIcon, verifiedIcon, verification_dark, mobileIcon1, darkIcon, lightIcon, systemIcon, system_dark,overviewIcon,coursesIcon, fontSide, backArrow, backArrow_dark, light_dark, dark_dark } from './assets'
import Loader from "../../../components/src/Loader.web";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import LockIcon from "@material-ui/icons/Lock";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import CustomLogoModalWeb from '../../../components/src/CustomLogoModal.web';

// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  passwordModal = () => (
    <FieldModal
      data-test-id="passwordModal"
      open={this.state.passwordModal}
      onCancel={this.closePasswordModal}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <ModalHeading> Security</ModalHeading>
          <hr style={{ width: '150px', height: '2px', color: 'rgba(215, 215, 215, 0.5)' }} />
          <SubHeadingModal> Change Password </SubHeadingModal>
          <hr style={{ width: '150px', height: '2px', color: 'rgba(215, 215, 215, 0.5)' }} />
        </div>
      }
      centered
      closable={false}
      footer={null}
      destroyOnClose
      width={766}
      style={{ height: 'auto' }}
    >
      <Box sx={{ padding: "10px 0px" }}>
        <Typography color="secondary" variant="subtitle1" component="div" style={{ fontFamily: 'Rubik', fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
          New Password
        </Typography>
        <InputField
          data-test-id="newPassword"
          type={this.state.enableNewPasswordField ? "password" : "text"}
          placeholder="Enter your new password"
          fullWidth
          value={this.state.newPassword}
          onChange={this.changeNewPassword}
          onKeyPress={this.spaceReturn}
          startAdornment={
            <InputAdornment position="start">
              <IconButton disabled={!this.state.newPassword}>
                <LockIcon />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.showNewPassword}
                edge="end"
                disabled={!this.state.newPassword}
              >
                {this.state.enableNewPasswordField ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>
      <Box sx={{ padding: "10px 0px" }}>
        <Typography color="secondary" variant="subtitle1" component="div" style={{ fontFamily: 'Rubik', fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
          Confirm New Password
        </Typography>
        <InputField
          data-test-id="confirmNewPassword"
          type={this.state.enableConfirmPasswordField ? "password" : "text"}
          placeholder="Confirm your new password"
          fullWidth
          value={this.state.confirmNewPassword}
          onChange={this.changeConfirmNewPassword}
          onKeyPress={this.spaceReturn}
          startAdornment={
            <InputAdornment position="start">
              <IconButton disabled={!this.state.confirmNewPassword}>
                <LockIcon />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.showConfirmPassword}
                edge="end"
                disabled={!this.state.confirmNewPassword}
              >
                {this.state.enableConfirmPasswordField ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>
      {
        !(this.state.charLength && this.state.numberChar && this.state.lowerChar && this.state.specialChar && this.state.upperChar) &&
        <div style={{ margin: '15px' }}>
          <ValidationSection>
            <div>
              <PasswordChecked defaultChecked style={{ backgroundColor: this.state.charLength ? '#1A469C' : '#B8C6E0' }} />
              <PasswordText>Use 8 or more characters</PasswordText>
            </div>
            <div>
              <PasswordChecked defaultChecked style={{ backgroundColor: this.state.numberChar ? '#1A469C' : '#B8C6E0' }} />
              <PasswordText>One number</PasswordText>
            </div>
            <div>
              <PasswordChecked defaultChecked style={{ backgroundColor: this.state.lowerChar ? '#1A469C' : '#B8C6E0' }} />
              <PasswordText>One lowercase character</PasswordText>
            </div>
          </ValidationSection>
          <ValidationSection>
            <div>
              <PasswordChecked defaultChecked style={{ backgroundColor: this.state.specialChar ? '#1A469C' : '#B8C6E0' }} />
              <PasswordText>One special character</PasswordText>
            </div>
            <div>
              <PasswordChecked defaultChecked style={{ backgroundColor: this.state.upperChar ? '#1A469C' : '#B8C6E0' }} />
              <PasswordText>One Uppercase character</PasswordText>
            </div>
          </ValidationSection>
        </div>
      }
      <BackButton
        onClick={this.closePasswordModal}
        style={{ marginBottom: '20px' }}
      >
        Cancel
      </BackButton>
      <NextFormButton
        data-test-id="changePassword"
        onClick={this.changePassword}
        style={{ width: '65%', marginLeft: '10px', marginBottom: '20px' }}
      >
        Next
      </NextFormButton>
      <ToastContainer containerId="A" position="top-right" />
    </FieldModal >
  )

  SecuritySection = () => (
    <div style={{ width: '100%' }}>
      <HeadingSection>
        <div>
          <SettingHeading>Security</SettingHeading>
          <SettingDescription>here where you can find all your privacy data</SettingDescription>
        </div>
      </HeadingSection>
      <OuterSection>
        <InnerSection>
          <MainContainer>
            <SubDiv container spacing={2}>
              <Grid item xs={6}>
                <SettingSubHeading>Email Address</SettingSubHeading>
                <SettingSubDescription>The email address associated with your account.</SettingSubDescription>
              </Grid>
              <Grid item xs={4}>
                <SettingSubDescription>Email</SettingSubDescription>
                <SettingSubHeading style={{ fontWeight: 400, fontSize: '14px' }}>{this.state.userProfile.email}</SettingSubHeading>
              </Grid>
              <Grid item xs={2}>
                <LinkDescription />
              </Grid>
            </SubDiv>
            <Divider variant="middle" />
            <SubDiv container spacing={2}>
              <Grid item xs={6}>
                <SettingSubHeading>Password</SettingSubHeading>
                <SettingSubDescription>Set a unique password to protect your account.</SettingSubDescription>
              </Grid>
              <Grid item xs={3}>
                <SettingSubDescription></SettingSubDescription>
                <SettingSubDescription style={{ fontWeight: 700 }}></SettingSubDescription>
              </Grid>
              <Grid item xs={3}>
                <LinkDescription
                  data-test-id="openPasswordModal"
                  onClick={this.openPasswordModal}
                >
                  Change Password
                </LinkDescription>
              </Grid>
            </SubDiv>
            <Divider variant="middle" />
            <SubDiv container spacing={2}>
              <Grid item xs={6}>
                <SettingSubHeading>2-Steps Verification</SettingSubHeading>
                <SettingSubDescription style={{ lineHeight: '16px' }}>Make your account extra secure. Along with your password, you'll need to enter a code through phone number</SettingSubDescription>
              </Grid>
              <Grid item xs={3} style={{ display: 'flex', flexDirection: 'row' }}>
                {this.state.userProfile.phone_number_verified && <img src={this.state.selectedTheme ? verification_dark : verifiedIcon} height={40} width={40} />}
                <div>
                  <SettingSubDescription>Phone Number</SettingSubDescription>
                  <SettingSubHeading style={{ fontWeight: 400, fontSize: '14px' }}>{this.state.userProfile.full_phone_number}</SettingSubHeading>
                </div>
              </Grid>
              <Grid item xs={3}>
                <LinkDescription
                  data-test-id="openPhoneVerificationModal"
                  onClick={this.openPhoneVerificationModal}
                >
                  {this.state.userProfile.phone_number_verified ? 'Update Phone Number' : 'Verify Phone Number'}
                </LinkDescription>
              </Grid>
            </SubDiv>
          </MainContainer>
        </InnerSection>
      </OuterSection>
      {this.passwordModal()}
      <FieldModal
        data-test-id="phoneVerificationModal"
        open={this.state.phoneVerificationModal}
        onCancel={this.closePhoneVerificationModal}
        title={
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <ModalHeading style={{ fontSize: '24px' }}>Phone Verification </ModalHeading>
            <hr style={{ width: '34px', height: '2px', color: 'rgba(215, 215, 215, 0.5)' }} />
            <SubHeadingModal style={{ fontSize: '17px' }}>{this.state.userProfile.full_phone_number}</SubHeadingModal>
            <hr style={{ width: '34px', height: '2px', color: 'rgba(215, 215, 215, 0.5)' }} />
          </div>}
        centered
        closable={true}
        footer={null}
        destroyOnClose
        width={574}
        style={{ height: 'auto' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <img src={mobileIcon} style={{ margin: '15px' }} />
          <div style={{ marginLeft: '10px' }}>
            <VerificationSubText variant="body2">
              A 6-digit verification code was just sent to {this.state.userProfile.full_phone_number}
            </VerificationSubText>
            <OtpInput
              data-test-id="changeOtpInput"
              value={this.state.otpInput}
              onChange={this.changeOtpInput}
              numInputs={6}
              renderSeparator={<span>&nbsp;&nbsp;</span>}
              inputStyle="otpInputStyle"
              renderInput={(props) => <input {...props} />}
            />
          </div>
        </div>
        <ErrorMessage>{this.state.verifyOtpErrorMsg}</ErrorMessage>
        <NextFormButton
          data-test-id="verifyOtp"
          onClick={this.verifyOtp}
          style={{ width: '100%', marginLeft: '10px' }}
        >
          Next
        </NextFormButton>
        <VerificationSubText variant="body1" style={{ marginBottom: '20px', fontSize: '18px', fontWeight: 400, textAlign: 'center', marginTop: '10px' }}>
          Didn't get the code? <ResendText onClick={this.sendVerificationCode}>Resend it</ResendText>
        </VerificationSubText>
        <ToastContainer containerId="B" position="top-right" />
      </FieldModal>
      <FieldModal
        open={this.state.verifiedModal}
        onCancel={this.closeVerifiedModal}
        closable={false}
        centered
        destroyOnClose
        footer={null}
        style={{ height: '430px' }}
        width={696}
      >
        <div style={{ display: 'flex', justifyContent: "center", padding: '20px', flexDirection: 'column', alignItems: 'center' }}>
          <img src={this.state.selectedTheme ? verification_dark : verifiedIcon} alt="verificationModal" style={{ height: '176px' }} />
          <SuccessMsg>Phone Number Verified Successfully</SuccessMsg>
        </div>
        <NextButton
          data-test-id="closeVerifiedModal"
          onClick={this.closeVerifiedModal}
          fullWidth
          style={{ color: '#F8FAFC', textTransform: 'none' }}
        >
          Back to Settings
        </NextButton>
      </FieldModal>
      <FieldModal
        open={this.state.passwordSuccessModal}
        onCancel={this.closeVerifiedModal}
        closable={true}
        centered
        destroyOnClose
        footer={null}
        style={{ height: '430px' }}
        width={696}
      >
        <div style={{ display: 'flex', justifyContent: "center", padding: '20px', flexDirection: 'column', alignItems: 'center' }}>
          <img src={this.state.selectedTheme ? verification_dark : verifiedIcon} alt="verificationModal" style={{ height: '176px' }} />
          <SuccessMsg>Password Changed Successfully</SuccessMsg>
        </div>
        <NextButton
          data-test-id="gotoLogin"
          onClick={this.goToLogin}
          fullWidth
          style={{ color: '#F8FAFC' }}
        >
          Logout From All Devices
        </NextButton>
      </FieldModal>
      <FieldModal
        data-test-id="passwordFieldModal"
        open={this.state.passwordFieldModal}
        onCancel={this.closePasswordModal}
        title={
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <ModalHeading>Verify It’s you</ModalHeading>
            <hr style={{ width: '80px', color: 'rgba(215, 215, 215, 0.5)', height: '2px' }} />
            <SubHeadingModal>Update Phone Number</SubHeadingModal>
            <hr style={{ width: '80px', color: 'rgba(215, 215, 215, 0.5)', height: '2px' }} />
          </div>}
        closable={false}
        centered
        destroyOnClose
        style={{ height: 'auto' }}
        width={766}
        footer={null}
      >
        <Box sx={{ padding: "10px 0px" }}>
          <Typography color="secondary" variant="subtitle1" component="div" style={{ fontFamily: 'Rubik', fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
            Password
          </Typography>
          <InputField
            data-test-id="password"
            type={this.state.enableNewPasswordField ? "password" : "text"}
            placeholder="Enter Your Password"
            fullWidth
            onChange={this.changeNewPassword}
            value={this.state.newPassword}
            startAdornment={
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={this.showNewPassword}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {this.state.enableNewPasswordField ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
        <ErrorMessage style={{ textAlign: 'left', marginLeft: '10px' }}>{this.state.passwordErrorMsg}</ErrorMessage>
        <BackButton
          style={{ marginBottom: '20px' }}
          onClick={this.closePasswordModal}
        >
          Cancel
        </BackButton>
        <NextFormButton
          data-test-id="verifyPassword"
          onClick={this.verifyPassword}
          style={{ width: '65%', marginLeft: '10px', marginBottom: '20px' }}
        >
          Next
        </NextFormButton>
        <ToastContainer containerId="D" position="top-right" />
      </FieldModal>
      <FieldModal
        data-test-id="phoneFieldModal"
        open={this.state.phoneFieldModal}
        onCancel={this.closePasswordModal}
        title={
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <ModalHeading>Security</ModalHeading>
            <hr style={{ width: '80px', height: '2px', color: 'rgba(215, 215, 215, 0.5)' }} />
            <SubHeadingModal>Update Phone Number</SubHeadingModal>
            <hr style={{ width: '80px', height: '2px', color: 'rgba(215, 215, 215, 0.5)' }} />
          </div>}
        style={{ height: 'auto' }}
        width={766}
        destroyOnClose
        footer={null}
        closable={false}
        centered
      >
        <Box sx={{ padding: "10px 0px" }}>
          <Typography color="secondary" variant="subtitle1" component="div" style={{ fontFamily: 'Rubik', fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
            Phone Number
          </Typography>
          <InputField
            data-test-id="phoneNumber"
            placeholder="Enter your new phone number with country code"
            fullWidth
            onChange={this.changePhoneNumber}
            onKeyPress={this.acceptPhoneNumber}
            value={this.state.phoneNumber}
            inputProps={{ maxLength: 15 }}
            startAdornment={
              <InputAdornment position="start">
                <img src={mobileIcon1} />
              </InputAdornment>
            }
          />
        </Box>
        <ErrorMessage style={{ textAlign: 'left', marginLeft: '10px' }}>{this.state.phoneErrorMsg}</ErrorMessage>
        <BackButton style={{ marginBottom: '20px' }} onClick={this.closePasswordModal}>
          Cancel
        </BackButton>
        <NextFormButton
          data-test-id="updatePhoneNumber"
          onClick={this.updatePhoneNumber}
          style={{ width: '65%', marginLeft: '10px', marginBottom: '20px' }}
        >
          Next
        </NextFormButton>
        <ToastContainer containerId="E" position="top-right" />
      </FieldModal>
      <FieldModal
        open={this.state.updatePhoneSuccessModal}
        onCancel={this.closeVerifiedModal}
        closable={false}
        centered
        destroyOnClose
        footer={null}
        style={{ height: '430px' }}
        width={696}
      >
        <div style={{ display: 'flex', justifyContent: "center", padding: '20px', flexDirection: 'column', alignItems: 'center' }}>
          <img src={this.state.selectedTheme ? verification_dark : verifiedIcon} alt="verificationModal" style={{ height: '176px' }} />
          <SuccessMsg>Phone Number Updated Successfully</SuccessMsg>
        </div>
        <NextButton
          data-test-id="closeModal"
          onClick={this.closePasswordModal}
          fullWidth
          style={{ color: '#F8FAFC', textTransform: 'none' }}
        >
          Back to Settings
        </NextButton>
      </FieldModal>
      <ToastContainer position="top-right" containerId='C' />
    </div >
  )

  MessageSection = () => (
    <div style={{ width: '100%' }}>
      <HeadingSection>
        <div>
          <SettingHeading>Messages</SettingHeading>
          <SettingDescription>Select when and how to be messaged</SettingDescription>
        </div>
        <div>
          <SwitchSection style={{ marginTop: '0px', height: '26px' }}>
            <Typography>Push</Typography>
            <Switch data-test-id="allPush" onChange={(event) => this.changeAllPush(event, this.state.messageHeading, 'push')} checked={Boolean(this.state.messageHeading.attributes.push)} />
          </SwitchSection>
          <SwitchSection style={{ marginTop: '0px', height: '26px' }}>
            <Typography>Email</Typography>
            <Switch data-test-id="allEmail" onChange={(event) => this.changeAllPush(event, this.state.messageHeading, 'email')} checked={Boolean(this.state.messageHeading.attributes.email)} />
          </SwitchSection>
        </div>
      </HeadingSection>
      <OuterSection>
        <InnerSection>
          <SubDiv container spacing={5}>
            {this.state.messageCategoriesList.map((item) => (
              <MessageCard item xs={4}>
                <MessagesSubHeading>{item.data.attributes.title}</MessagesSubHeading>
                <MessagesSubDescription>{item.data.attributes.description}</MessagesSubDescription>
                <div style={{ display: 'flex' }}>
                  <SwitchSection>
                    <Typography>Push</Typography>
                    <Switch data-test-id="changePush" onChange={(event) => this.changePush(event, item, 'push')} checked={Boolean(item.data.attributes.push)} />
                  </SwitchSection>
                  <SwitchSection>
                    <Typography>Email</Typography>
                    <Switch data-test-id="changeEmail" onChange={(event) => this.changePush(event, item, 'email')} checked={Boolean(item.data.attributes.email)} />
                  </SwitchSection>
                </div>
              </MessageCard>
            ))}
          </SubDiv>
        </InnerSection>
      </OuterSection>
    </div>
  )

  innerImage = () => (
    <div>
      <ThemeImage />
      <div style={{ position: 'relative', top: '-45px', left: '22px', display: 'flex' }}>
        <AppearanceCircle style={{ background: '#DC2626' }} />
        <AppearanceCircle style={{ background: '#D97706' }} />
        <AppearanceCircle style={{ background: '#059669' }} />
      </div>
    </div>
  )

  AppearanceSection = () => (
    <div style={{ width: '100%' }}>
      <HeadingSection>
        <div>
          <SettingHeading>Appearance</SettingHeading>
          <SettingDescription>Change how PetroPal looks and feels in your browser.</SettingDescription>
        </div>
        <div>
          {!(this.state.selectedAppearance === 'system' && this.state.selectedFontSize === 'default_size') && <ResetAllButton data-test-id="resetAll" onClick={this.resetAppearance}>Reset All Changes</ResetAllButton>}
        </div>
      </HeadingSection>
      <OuterSection>
        <InnerSection>
          <SubDiv container spacing={5}>
            <Grid item xs={4} className="newFaces" style={{ padding: '25px 40px', maxWidth: 333, width: '100%' }}>
              <MessagesSubHeading>Company Logo</MessagesSubHeading>
              <MessagesSubDescription>Update Your Company Logo</MessagesSubDescription>
              <CustomLogoModalWeb
                isModalOpen={this.state.ModalOpen}
                handleClose={this.handleClose}
                logotitle="Change Logo"
                midContent="Select Logo"
                logodescription="description"
                btnTitle="Next"
                cancelbtnTitle="Cancel"
                theme={this.state.selectedTheme} />
              <Box style={{ background: 'white', borderRadius: 21, border: '1px solid lightgray', padding: 10 }}>
                <img src={fullLogo} style={{ width: '100%' }} />
              </Box>
              <Box style={{ display: 'flex', alignItems: 'flex-end', gap: 26, marginTop: 16 }}>
                <Box style={{ background: 'white', borderRadius: 21, border: '1px solid lightgray', padding: 10, width: 'fit-content' }}>
                  <img src={smallLogo} />
                </Box>
                <Button style={{ border: '1px solid #1A469C', borderRadius: 30, padding: '10px 16px', background: 'white', color: '#1A469C', fontSize: 16, fontWeight: 500 }} onClick={this.handleOpenModal}>
                  Change logo
                </Button>
              </Box>
            </Grid>
            <Grid item xs={7} className="newFaces">
              <MessagesSubHeading>Interface Theme</MessagesSubHeading>
              <MessagesSubDescription>Select or customize your interface theme</MessagesSubDescription>
              <Grid container>
                <AppearanceCard
                  data-test-id="lightTheme"
                  item xs={4} onClick={() => this.selectTheme('light')}
                  style={{ border: `${this.state.selectedAppearance === 'light' ? "1px solid #1A469C" : "1px solid #D7D7D780"}` }}
                >
                  <div style={{ margin: "10px" }}>
                    <img src={this.state.selectedTheme ? light_dark : lightIcon} />
                    <AppearanceSubHeading>Light</AppearanceSubHeading>
                    {this.state.selectedAppearance === 'light' && <ActiveLabel>ACTIVE</ActiveLabel>}
                  </div>
                  <AppearanceColor style={{ background: "#FFFFFF" }}>
                    {this.innerImage()}
                  </AppearanceColor>
                </AppearanceCard>
                <AppearanceCard
                  data-test-id="darkTheme"
                  item xs={4} onClick={() => this.selectTheme('dark')}
                  style={{ border: `${this.state.selectedAppearance === 'dark' ? "1px solid #1A469C" : "1px solid #D7D7D780"}` }}
                >
                  <div style={{ margin: "10px" }}>
                    <img src={this.state.selectedTheme ? dark_dark : darkIcon} />
                    <AppearanceSubHeading>Dark</AppearanceSubHeading>
                    {this.state.selectedAppearance === 'dark' && <ActiveLabel>ACTIVE</ActiveLabel>}
                  </div>
                  <AppearanceColor style={{ background: "#000000" }}>
                    {this.innerImage()}
                  </AppearanceColor>
                </AppearanceCard>
              </Grid>
            </Grid>
            <Grid item xs={4} className="newFaces" style={{ marginTop: '20px' }}>
              <MessagesSubHeading>Accent Color</MessagesSubHeading>
              <MessagesSubDescription>Update Your Dashboard To Your Brand Color</MessagesSubDescription>
              {this.fontColorSection()}
            </Grid>
            <Grid item xs={4} className="newFaces" style={{ marginTop: '20px' }}>
              <MessagesSubHeading>Font Size</MessagesSubHeading>
              <MessagesSubDescription>Size of texts and headings</MessagesSubDescription>
              {this.fontSizeSection()}
            </Grid>
          </SubDiv>
        </InnerSection>
      </OuterSection>
      <ToastContainer containerId='F' />
    </div >
  )

  fontSizeSection = () => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FontSizeSection>
        <FontSizeButton variant="text"
          data-test-id="default_size"
          onClick={() => this.selectFontSize('default_size')}
          className={this.state.selectedFontSize === 'default_size' ? 'selectedColor' : ""}
        >
          Default
        </FontSizeButton>
        <FontSizeButton variant="text"
          data-test-id="medium_size"
          onClick={() => this.selectFontSize('medium_size')}
          className={this.state.selectedFontSize === 'medium_size' ? 'selectedColor' : ""}
        >
          Medium
        </FontSizeButton>
        <FontSizeButton variant="text"
          data-test-id="large_size"
          onClick={() => this.selectFontSize('large_size')}
          className={this.state.selectedFontSize === 'large_size' ? 'selectedColor' : ""}
        >
          Large
        </FontSizeButton>
      </FontSizeSection>
      <div style={{ textAlign: 'center', marginTop: 19, marginBottom: 15 }}>
        <Button onClick={this.handleFontSizeModal} style={{ border: '1px solid #1A469C', borderRadius: 30, padding: '10px 16px', background: '#1A469C', color: 'white', fontSize: 16, fontWeight: 500, textTransform: "unset" }}>
          customize Font
        </Button>
      </div>
      <CustomDialog open={this.state.fontSizeModal} onClose={this.handleFontSizeModal}>
          <CustomDialogTitle>Customize Font</CustomDialogTitle>
          <CustomDialogContent>       
            Select Or Customize Your Font
          </CustomDialogContent>
          <DialogActions>
            <Button onClick={this.handleFontSizeModal} color="primary">
              Apply
            </Button>
          </DialogActions>
        </CustomDialog>

      <PreviewSection>
        <PreviewText>Preview</PreviewText>
        <PreviewImageDiv>
          <img src={this.state.selectedTheme ? backArrow_dark : backArrow} style={{ width: "16px", height: "16px", alignSelf: "flex-end" }} />
          <img src={fontSide} />
        </PreviewImageDiv>
      </PreviewSection>
    </div>
  )

  fontColorSection = () => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <FontSizeSection>
        <FontSizeButton variant="text"
          data-test-id="default_color"
          onClick={() => this.selectColor('#18408E')}
        // className={this.state.selectedFontSize === 'default_size' ? 'selectedColor' : ""}
        >
          Default
        </FontSizeButton>
        <ColorSubText onClick={() => this.selectColor('#100843')} data-test-id="set_color" style={{ background: '#100843' }}></ColorSubText>
        <ColorSubText onClick={() => this.selectColor('#1A469C')} data-test-id="set_second_color" style={{ background: '#1A469C' }}></ColorSubText>
        <ColorSubText onClick={() => this.selectColor('#00ABE6')} data-test-id="set_third_color" style={{ background: '#00ABE6' }}></ColorSubText>
        <Box onClick={() => this.selectColor('#7357A5')} data-test-id="set_forth_color"
          style={{ border: '1px solid #1A469C', padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 12 }}>
          <ColorSubText style={{ background: '#7357A5' }}></ColorSubText></Box>
      </FontSizeSection>
      <FontSizeSection style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8, paddingLeft: 0, marginTop: 19, border: 'none' }}>
        <input type="text" placeholder="#####" data-test-id="input_field" value={this.state.selectedColor}
          onChange={(e) => this.setState({ selectedColor: e.target.value })}
          style={{ maxWidth: '70%', border: '1px solid #CBD5E1', height: 44, paddingInline: 15, borderRadius: 8 }} id="" />
        <Box style={{ maxWidth: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box style={{ width: 26, height: 26, borderRadius: 26, background: this.state.selectedColor }}></Box>
        </Box>
      </FontSizeSection>
      <div style={{ textAlign: 'center', marginTop: 19, marginBottom: 15 }}>
        <Button style={{ border: '1px solid #1A469C', borderRadius: 30, padding: '10px 16px', background: '#1A469C', color: 'white', fontSize: 16, fontWeight: 500, textTransform: "unset" }}>
          customize color
        </Button>
      </div>
      <PreviewText>Preview</PreviewText>
      <PreviewSection>
        <PreviewImageDiv>
          <img src={this.state.selectedTheme ? backArrow_dark : backArrow} style={{ width: "16px", height: "16px", alignSelf: "flex-end" }} />
          <ListParentBox>
            <ListItem 
              data-test-id="studentdrawerItem" 
              style={{ height: 23, marginInline: 15, paddingInline: 15, borderLeft: '2px solid #1A469C',borderRadius: 5,color: "#1A469C" }}
            >
              <ListItemIcon style={{ minWidth: 17 }}><img src={overviewIcon} style={{width:10}}/></ListItemIcon>
              <Typography className='list-text' style={{color: this.state.selectedColor, fontSize:"6.2px"}}>
                Overview
              </Typography>
            </ListItem>
            <ListItem
              data-test-id="studentdrawerItem" style={{ height: 44, marginInline: 15, paddingInline: 15 }}
            >
              <ListItemIcon style={{ minWidth: 17 }}><img src={coursesIcon} style={{width:10}}/></ListItemIcon>
              <Typography className='list-text' style={{fontSize:"6.2px"}}>
                Courses
              </Typography>
            </ListItem>
          </ListParentBox>
        </PreviewImageDiv>
      </PreviewSection>
    </div>
  )
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={this.state.selectedTheme ? darkTheme : lightTheme}>
        <HamburgerMenu navigation={this.props.navigation} handleMenu={this.toggleSideMenu} open={this.state.sidOpen} themeDark={this.state.selectedTheme} handleTheme={this.changeTheme} />
        <ContainerBody style={{ paddingLeft: this.state.sidOpen ? 273 : 130, paddingRight: 48, transition: 'all 100ms linear', justifyContent: 'flex-start' }}>
          <Loader loading={this.state.loading} />
          {this.state.enableSecurity && this.SecuritySection()}
          {this.state.enableMessage && this.MessageSection()}
          {this.state.enableAppearance && this.AppearanceSection()}
        </ContainerBody>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ContainerBody = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  background: theme.palette.background.default,
}))

const OuterSection = styled(Box)(({ theme }) => ({
  background: theme.palette.action.focus,
  width: '100%',
  maxWidth: '1070px',
  alignSelf: 'center',
  borderRadius: "8px 8px 50px 8px",
  maxHeight: '698px',
  padding: '0px 3px 3px 0px'
}))

const InnerSection = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  width: '100%',
  maxWidth: '1070px',
  alignSelf: 'center',
  borderRadius: "0px 0px 80px 0px",
  maxHeight: '678px',
  padding: '0px 20px 20px 0px'
}))
const HeadingSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  maxHeight: '113px',
  margin: '20px 0px',
  width: '100%',
  maxWidth: '1070px',
  alignSelf: 'center',
  padding: '30px',
  justifyContent: 'space-between'
}))

const SettingHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '30px',
  fontWeight: 500,
  lineHeight: '30px',
  letterSpacing: '0.41111114621162415px',
  textAlign: 'left',
  color: theme.palette.info.main
}))

const SettingDescription = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Rubik',
  lineHeight: '14px',
  fontWeight: 400,
  textAlign: 'left',
  letterSpacing: '0.4px',
  marginTop: '5px',
  color: theme.palette.info.main
}))

const MainContainer = styled(Container)(({ theme }) => ({
  maxHeight: '658px',
  width: '100%',
  maxWidth: '1070px',
  flexDirection: 'column',
  display: 'flex',
  background: theme.palette.action.focus,
  borderRadius: "8px 8px 60px 8px"
}))

const SettingSubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.41px',
  textAlign: 'left',
  color: theme.palette.info.main
}))

const SettingSubDescription = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  textAlign: 'left',
  color: theme.palette.info.light,
  maxWidth: '426px',
  wordBreak: 'break-word'
}))

const MessagesSubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '18px',
  letterSpacing: '0.41px',
  marginTop: '50px',
  textAlign: 'left',
  color: theme.palette.info.main
}))

const MessagesSubDescription = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16.52px',
  textAlign: 'left',
  letterSpacing: '0.41px',
  color: theme.palette.info.light,
  maxWidth: '281px',
  wordBreak: 'break-word',
  height: '50px',
  marginTop: '10px',
  textTransform: 'capitalize'
}))

const MessageCard = styled(Grid)(({ theme }) => ({
  maxWidth: '305.85px',
  maxHeight: '198px',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  padding: '15px',
  margin: '5px'
}))

const LinkDescription = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  textAlign: 'right',
  color: theme.palette.info.contrastText,
  marginTop: '10px',
  cursor: 'pointer'
}))

const SubDiv = styled(Grid)(({ theme }) => ({
  padding: '16px',
  "& .newFaces": {
    background: theme.palette.action.focus,
    borderRadius: '18px',
    marginLeft: '15px'
  }
}))

const ErrorMessage = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '18px',
  textAlign: 'center',
  color: '#DC2626'
}))

const SuccessMsg = styled('span')(({ theme }) => ({
  fontFamily: 'Rubik',
  fontWeight: 700,
  fontSize: '30px',
  lineHeight: '40px',
  textAlign: 'center',
  color: theme.palette.secondary.main
}))

export const InputField = styled(Input)(({ theme }) => ({
  height: '44px',
  padding: '10px 12px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.info.dark}`,
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  textAlign: 'left',
  background: theme.palette.background.default,
  color: theme.palette.info.main,
  "& .MuiInputBase-input": {
    border: 'none',
    padding: "10px 12px 10px 0px"
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  },
  '& .MuiIconButton-root.Mui-disabled .MuiSvgIcon-root': {
    fill: `${theme.palette.secondary.contrastText} !important`
  }
}))

const SwitchSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '20px',
  "& p": {
    marginTop: '5px',
    color: '#475569',
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left'
  },
  '& .MuiSwitch-track': {
    borderRadius: '30px'
  },
  '& .MuiSwitch-root': {
    padding: '10px'
  },
  '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#18408E'
  },
  '& .MuiSwitch-colorSecondary.Mui-checked': {
    color: '#FFF'
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    opacity: 1
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: '#FFF',
    width: 16,
    height: 16,
    top: 2,
    position: 'relative',
    boxShadow: 'none',
    left: 3,
  },

}))

const AppearanceSubHeading = styled('span')(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '11.18px',
  fontWeight: 500,
  lineHeight: '11.18px',
  letterSpacing: '0.41px',
  textAlign: 'left',
  color: theme.palette.info.main,
  marginLeft: '5px'
}))

const AppearanceCard = styled(Grid)(({ theme }) => ({
  maxWidth: "165px",
  maxHeight: "140px",
  borderRadius: "12.58px",
  border: '1px solid #D7D7D780',
  marginRight: "15px"
}))

const AppearanceColor = styled('div')({
  borderRadius: "12.58px",
  marginTop: "10px",
  height: "100px",
  width: "100%"
})

const ThemeImage = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: "26px",
  left: "36px",
  opacity: 0.2,
  background: theme.palette.text.disabled,
  border: `1px solid ${theme.palette.info.dark}`,
  borderRadius: '10.2px 0px 12.58px 0px',
  height: '74px',
  width: "127px",
  display: 'flex'
}))

const AppearanceCircle = styled(Box)({
  position: 'relative',
  top: "26px",
  left: "26px",
  opacity: 1,
  height: '15px',
  width: "15px",
  borderRadius: '50%',
  marginRight: "10px",
  border: "0.7px solid #FFF"
})

const ActiveLabel = styled('span')(({ theme }) => ({
  width: "100%",
  height: "13.97px",
  borderRadius: '27.93px',
  padding: "1.4px 5.59px",
  color: '#FFF',
  background: '#1A469C',
  marginLeft: "15px",
  fontSize: "10px",
  fontFamily: "Rubik",
  lineHeight: "18px",
  fontWeight: 500
}))

const FontSizeSection = styled('div')(({ theme }) => ({
  minWidth: "305px",
  height: '44px',
  padding: '6px 10px 6px 10px',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.info.dark}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: theme.palette.background.default,
  '& .selectedColor': {
    // color: theme.palette.info.contrastText,
    color: "white",
    border: `1px solid ${theme.palette.info.contrastText}`,
    background: "#1A469C"
  }
}))

const FontSizeButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  textTransform: 'capitalize',
  color: theme.palette.info.light,
  borderRadius: '26px'
}))

const PreviewSection = styled(Box)(({ theme }) => ({
  minWidth: "305px",
  minHeight: "73px",
  borderRadius: "18px",
  border: `1px solid ${theme.palette.info.dark}`,
  background: theme.palette.background.default,
  display: "flex",
  flexDirection: "column"
}))

const PreviewText = styled('span')(({ theme }) => ({
  maxWidth: '63px',
  minHeight: "12px",
  borderRadius: "30px",
  padding: "0px 8px",
  background: theme.palette.background.default,
  fontFamily: "Rubik",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "12px",
  letterSpacing: "0.31px",
  color: theme.palette.text.primary,
  // position: 'relative',
  // top: '-5px',
  // left: "5px"
}))

const PreviewImageDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.action.focus,
  maxWidth: "106px",
  alignSelf: 'center'
}))
const ResetAllButton = styled(Button)(({ theme }) => ({
  minWidth: '171px',
  height: '44px',
  padding: '10px 16px',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.info.contrastText}`,
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  textTransform: 'capitalize',
  color: theme.palette.info.contrastText
}))

const ModalHeading = styled('h1')(({ theme }) => ({
  fontSize: '28px',
  fontWeight: 500,
  fontFamily: 'Rubik',
  textAlign: 'center',
  color: theme.palette.info.main
}))

const SubHeadingModal = styled('span')(({ theme }) => ({
  fontSize: '22px',
  fontWeight: 400,
  fontFamily: 'Rubik',
  color: theme.palette.text.primary
}))

const VerificationSubText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  lineHeight: '24px',
  fontWeight: 400,
  color: theme.palette.info.main
}))

const ResendText = styled('span')(({ theme }) => ({
  color: theme.palette.info.contrastText,
  cursor: 'pointer',
  textDecoration: 'underline'
}))

const ColorSubText = styled(Box)(({ theme }) => ({
  width: 26,
  height: 26,
  borderRadius: 26,
}))

const ListParentBox = styled(Box)(({ themeDark, open }: any) => ({
  '& .MuiListItem-button': {
    width: 'unset',
    borderRadius: 30,
    transition: 'all 200ms linear',
    '&:hover': {
      paddingLeft: open,
      background: themeDark,
      '& .list-text': {
        color: "#1A469C"
      }
    }
  },
  '& .list-text': {
    color: "#475569", fontWeight: 500, fontSize: 16, fontFamily: "Rubik",
  },
  '& .asdf': {
    '& .list-text': {
      color: "#1A469C !important"
    }
  },
}))

const CustomDialog = styled(Dialog)({
  '& .MuiDialog-paperWidthSm': {
    width: 256,
    borderRadius: 15
  },
})


const CustomDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiTypography-h6': {
    fontSize: 16,
  },
  '& .MuiDialogTitle-root': {
    padding: 0
  }
})
const CustomDialogContent = styled(Typography)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiTypography-h6': {
    fontSize: 14,
    fontWeight: 400,
    color: "#717171"
  }
})

// Customizable Area End
