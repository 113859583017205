// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const attachPin = require("../assets/attachPin.svg");
export const attachPinBlack = require("../assets/attach_Black.svg");
export const downloadIcon = require("../assets/downloadIcon.svg");
export const CameraBlack = require("../assets/CameraBlack.svg");
export const CameraWhite = require("../assets/CameraWhite.svg");
export const groupBlack = require("../assets/groupBlack.svg");
export const groupWhite = require("../assets/groupWhite.svg");
export const edit_Black = require("../assets/edit_Black.svg");
export const edit_white = require("../assets/edit_white.svg");
export const filter_list_black = require("../assets/filter_list_black.svg");
export const filter_list_White = require("../assets/filter_list_White.svg");
export const visibilityBlack = require("../assets/visibilityBlack.svg");
export const visibilityWhite = require("../assets/visibilityWhite.svg");
export const searchWhite = require("../assets/searchWhite.svg");
export const deleteBlack = require("../assets/deleteBlack.svg");
export const deleteWhite = require("../assets/deleteWhite.svg");
export const reportFlag = require("../assets/reportFlag.svg");
// Customizable Area End
