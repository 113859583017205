import React from "react";

// Customizable Area Start
import { Box, Button,styled, Typography,Menu,Modal,Paper,Dialog,Grid,TextField,InputAdornment,Badge,Breadcrumbs,Accordion,AccordionSummary,AccordionDetails,TextareaAutosize } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ArrowDropDownRounded, Search,NavigateNext, ExpandMore } from "@material-ui/icons";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { oneStarIcon,twoStarIcon,threeStarIcon,fourStarIcon,fiveStarIcon,selectedStarIcon,messageIcon,profileImage,progressIcon,completedIcon,timeIcon,certificateIcon,averageScoreIcon, courseTwo, arrowView, chatIcon,person1,person2, personsIcons, starRatingIcon, closeIcon, pinIcon, sendIcon } from "./assets";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,BarChart,Legend,Bar } from 'recharts';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import PerformanceTrackerController, {
  Props,
  configJSON,
} from "./PerformanceTrackerController";

export default class PerformanceTracker extends PerformanceTrackerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderUserDetails=()=>{
    return (
      <UserDetailBox>
               <EachUserDetailBox style={{width:"44px"}}>
                 <HeadingTypo>
                   ID
                 </HeadingTypo>
                 <DetailTypo>
                   5686
                 </DetailTypo>
               </EachUserDetailBox>
               <ProfileImageNameBox>
                 <img src={profileImage} style={{width:"40px",height:"40px"}}/>
                 <NameBox>
                   <NameTypography>Dep/Worker</NameTypography>
                   <PositionTypography>Yash Mathur</PositionTypography>
                 </NameBox> 
               </ProfileImageNameBox>
               <EachUserDetailBox>
                 <HeadingTypo>
                   Company Name
                 </HeadingTypo>
                 <DetailTypo>
                   PetroPal
                 </DetailTypo>
               </EachUserDetailBox>
               <EachUserDetailBox>
                 <HeadingTypo>
                   Group Name
                 </HeadingTypo>
                 <DetailTypo>
                   Interns
                 </DetailTypo>
               </EachUserDetailBox>
               <EachUserDetailBox>
                 <HeadingTypo>
                   Manager Name
                 </HeadingTypo>
                 <DetailTypo>
                   Gourav K.
                 </DetailTypo>
               </EachUserDetailBox>
                </UserDetailBox>
    )
  }
  renderAwardGrid=()=>{
    return (
      <AwardGrid container>
      <Grid item md={2}>
        <AwardListBox>
          <img src={progressIcon}  style={{width:"42.94px",height:"42.94px"}}/>
          <AwardListItemBox>
            <ItemHeading>In Progress</ItemHeading>
            <ItemCount>0 Courses</ItemCount>
          </AwardListItemBox>
        </AwardListBox>
      </Grid>
      <Grid item md={2}>
        <AwardListBox style={{borderLeft: "1px solid #E2E8F0"}}>
        <img src={completedIcon}  style={{width:"42.94px",height:"42.94px"}}/>
          <AwardListItemBox>
            <ItemHeading>Completed</ItemHeading>
            <ItemCount>0 Courses</ItemCount>
          </AwardListItemBox>
        </AwardListBox>
      </Grid>
      <Grid item md={2}>
        <AwardListBox style={{borderLeft: "1px solid #E2E8F0"}}>
        <img src={averageScoreIcon}  style={{width:"42.94px",height:"42.94px"}}/>
          <AwardListItemBox>
            <ItemHeading>Average Score</ItemHeading>
            <ItemCount>49</ItemCount>
          </AwardListItemBox>
        </AwardListBox>
      </Grid>
      <Grid item md={2}>
        <AwardListBox style={{borderLeft: "1px solid #E2E8F0"}}>
        <img src={timeIcon}  style={{width:"42.94px",height:"42.94px"}}/>
          <AwardListItemBox>
            <ItemHeading>Watch Time</ItemHeading>
            <ItemCount>2 hr 30 min</ItemCount>
          </AwardListItemBox>
        </AwardListBox>
      </Grid>
      <Grid item md={2}>
        <AwardListBox style={{borderLeft: "1px solid #E2E8F0"}}>
        <img src={certificateIcon}  style={{width:"42.94px",height:"42.94px"}}/>
          <AwardListItemBox>
            <ItemHeading>Certificates</ItemHeading>
            <ItemCount>1</ItemCount>
          </AwardListItemBox>
        </AwardListBox>
      </Grid>
   </AwardGrid>
    )
}
  renderWelcomeTopBar=(roleId:string)=>{
    if(roleId === "student"){
      return (
        <WelcomeBar>
          <WelcomeGrid>
            <Grid item md={4}>
              <BreadcrumbBox>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" aria-label="breadcrumb"/>}>
                  <BreadcrumbTypo>
                    Dashboard
                  </BreadcrumbTypo>
                  <BreadcrumbTypo>
                    Certificate
                  </BreadcrumbTypo>
                  <BreadcrumbActiveTypo>
                    Overall Progress
                  </BreadcrumbActiveTypo>
               </Breadcrumbs>
               </BreadcrumbBox>
            </Grid>
            <Grid item md={8}>
              <UserDetailBox>
                <EachUserDetailBox style={{width:"44px"}}>
                  <HeadingTypo>
                    ID
                  </HeadingTypo>
                  <DetailTypo>
                    5686
                  </DetailTypo>
                </EachUserDetailBox>
                <ProfileImageNameBox>
                  <img src={profileImage} style={{width:"40px",height:"40px"}}/>
                  <NameBox>
                    <HeadingTypo>Dep/Worker</HeadingTypo>
                    <DetailTypo>Yash Mathur</DetailTypo>
                  </NameBox> 
                </ProfileImageNameBox>
                <EachUserDetailBox>
                  <HeadingTypo>
                    Company Name
                  </HeadingTypo>
                  <DetailTypo>
                    PetroPal
                  </DetailTypo>
                </EachUserDetailBox>
                <EachUserDetailBox>
                  <HeadingTypo>
                    Group Name
                  </HeadingTypo>
                  <DetailTypo>
                    Interns
                  </DetailTypo>
                </EachUserDetailBox>
                <EachUserDetailBox>
                  <HeadingTypo>
                    Manager Name
                  </HeadingTypo>
                  <DetailTypo>
                    Gourav K.
                  </DetailTypo>
                </EachUserDetailBox>
                 </UserDetailBox>
            </Grid>
            </WelcomeGrid>
            {this.renderAwardGrid()}
        </WelcomeBar>
      )
    } else if(roleId === "educator"){
      return (
        <WelcomeBar>
          <WelcomeGrid>
            <Grid item md={4}>
              <BreadcrumbBox>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" aria-label="breadcrumb"/>}>
                  <BreadcrumbTypo>
                    Dashboard
                  </BreadcrumbTypo>
                  <BreadcrumbTypo>
                    Work Progress
                  </BreadcrumbTypo>
                  <BreadcrumbActiveTypo>
                    Performance Tracker
                  </BreadcrumbActiveTypo>
               </Breadcrumbs>
               </BreadcrumbBox>
            </Grid>
            <Grid item md={8}>
            <UserDetailBox style={{marginLeft:"50px",gap:"20px"}}>
            <EachUserDetailBox style={{width:"44px"}}>
              <HeadingTypo>
                ID
              </HeadingTypo>
              <DetailTypo>
                5686
              </DetailTypo>
            </EachUserDetailBox>
            <ProfileImageNameBox>
              <img src={profileImage} style={{width:"40px",height:"40px"}}/>
              <NameBox>
                <HeadingTypo>Dep/Worker</HeadingTypo>
                <DetailTypo>Yash M.</DetailTypo>
              </NameBox> 
            </ProfileImageNameBox>
            <EachUserDetailBox>
              <HeadingTypo>
                Company Name
              </HeadingTypo>
              <DetailTypo>
                PetroPal
              </DetailTypo>
            </EachUserDetailBox>
            <EachUserDetailBox>
              <HeadingTypo>
                Group Name
              </HeadingTypo>
              <DetailTypo>
                Interns
              </DetailTypo>
            </EachUserDetailBox>
            <EachUserDetailBox>
              <HeadingTypo>
                Manager Name
              </HeadingTypo>
              <DetailTypo>
                Gourav K.
              </DetailTypo>
            </EachUserDetailBox>
            <EachUserDetailBox>
              <img src={messageIcon} style={{width:"34px",height:"34px"}} onClick={this.handleDMModal}/>
            </EachUserDetailBox>
            </UserDetailBox>
            </Grid>
            </WelcomeGrid>
            {this.renderAwardGrid()}
        </WelcomeBar>
      )
    } else if(roleId === "supervisor/manager"){
      return (
        <WelcomeBar>
          <WelcomeGrid>
        <Grid item md={4}>
          <BreadcrumbBox style={{width:"411px",marginLeft:"20px"}}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" aria-label="breadcrumb"/>}>
              <BreadcrumbTypo>
                Dashboard
              </BreadcrumbTypo>
              <BreadcrumbTypo>
                Team Members
              </BreadcrumbTypo>
              <BreadcrumbActiveTypo>
                Performance Tracker
              </BreadcrumbActiveTypo>
           </Breadcrumbs>
           </BreadcrumbBox>
        </Grid>
        <Grid item md={8}>
          <UserDetailBox style={{marginLeft:"50px",gap:"20px"}}>
            <EachUserDetailBox style={{width:"44px"}}>
              <HeadingTypo>
                ID
              </HeadingTypo>
              <DetailTypo>
                5686
              </DetailTypo>
            </EachUserDetailBox>
            <ProfileImageNameBox>
              <img src={profileImage} style={{width:"40px",height:"40px"}}/>
              <NameBox>
                <HeadingTypo>Dep/Worker</HeadingTypo>
                <DetailTypo>Yash M.</DetailTypo>
              </NameBox> 
            </ProfileImageNameBox>
            <EachUserDetailBox>
              <HeadingTypo>
                Company Name
              </HeadingTypo>
              <DetailTypo>
                PetroPal
              </DetailTypo>
            </EachUserDetailBox>
            <EachUserDetailBox>
              <HeadingTypo>
                Group Name
              </HeadingTypo>
              <DetailTypo>
                Interns
              </DetailTypo>
            </EachUserDetailBox>
            <EachUserDetailBox>
              <HeadingTypo>
                Manager Name
              </HeadingTypo>
              <DetailTypo>
                Gourav K.
              </DetailTypo>
            </EachUserDetailBox>
            <EachUserDetailBox>
              <img src={messageIcon} style={{width:"34px",height:"34px"}} onClick={this.handleDMModal}/>
            </EachUserDetailBox>
            </UserDetailBox>
        </Grid>
        </WelcomeGrid>
        {this.renderAwardGrid()}
        </WelcomeBar>
      )
    }
  }
  renderAverageCourseChart=(chartData:{name:string,averageScore:number}[])=>{
    return (
      <ResponsiveContainer width="100%" height="80%">
      <AreaChart
        width={420}
        height={448}
        data={chartData}
        margin={{ top: 15, right: 30, left: 10, bottom: 0 }}
      >
        <defs>
          <linearGradient id="gradientColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="38.59%" stopColor="#6683BD" stopOpacity={1} />
            <stop offset="100%" stopColor="rgba(102, 131, 189, 0)" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
        <XAxis dataKey="name"  type="category"/>
        <YAxis dataKey="averageScore" type="number" axisLine={false}/>
        <Tooltip />
        <Area type="monotone" dataKey="averageScore" stroke="#8884d8" fill="url(#gradientColor)" />
      </AreaChart>
    </ResponsiveContainer>
    )

  }
  renderCourseSection=()=>{
    return (
      <>
        <MainCourseSection>
          <Grid container spacing={3}>  
            <Grid md={4} lg={4} item>
                <AverageGraphSection>
                  <GraphHeadingTypo>Average Scores Per Courses</GraphHeadingTypo>
                  {this.renderAverageCourseChart(
                    [ 
                      { name: 'Courses 1', averageScore: 28,  },
                      { name: 'Courses 2', averageScore: 38,  },
                      { name: 'Courses 3', averageScore: 48, },
                      { name: 'Courses 4', averageScore: 58,  },
                      { name: 'Courses 5', averageScore: 88,  }]
                  )}
                </AverageGraphSection>
            </Grid>
            <Grid md={8} lg={8} item>
              <CourseSection>
                <CourseHeaderSection>
                  <GraphHeadingTypo>Courses</GraphHeadingTypo>
                  <CourseTabSection>
                      <SelectedFilter style={{width:"42px"}}><SelectedFilterTypo>All</SelectedFilterTypo></SelectedFilter>
                      <DeselectedFilter style={{width:"105px"}}><DeselectedFilterTypo>In Progress</DeselectedFilterTypo></DeselectedFilter>
                      <DeselectedFilter style={{width:"87px"}}><DeselectedFilterTypo>Assigned</DeselectedFilterTypo></DeselectedFilter>
                      <DeselectedFilter style={{width:"98px"}}><DeselectedFilterTypo>Completed</DeselectedFilterTypo></DeselectedFilter>
                      <DeselectedFilter style={{width:"97px"}}><DeselectedFilterTypo>Feedback</DeselectedFilterTypo></DeselectedFilter>
                    </CourseTabSection>
                </CourseHeaderSection>
                {this.renderAllCourses()}
              </CourseSection>
            </Grid>
          </Grid>
        </MainCourseSection>
      </>
    )
  }
  renderAssignedCourses=()=>{
    return (
      <CourseBox>
        <Grid container spacing={6} style={{justifyContent:"space-between"}}>
          <Grid item md={3} lg={3}>
            <img src={courseTwo} style={{height:"121.85px",borderRadius: "18px"}}/>
          </Grid>
          <Grid item md={7} lg={7}>
        <CourseHeadingBox>
          <CourseNameBox>
            <Box>
            <CourseHeading>Course Name</CourseHeading>
            <CourseName>UI/UX Principles</CourseName>
            </Box>
            <img src={arrowView} style={{width: "32px", height: "28px", gap: "10px"}}/>
          </CourseNameBox>
          <InstructorBox>
            <InstructorHeading>Instructor Name</InstructorHeading>
            <InstructorName>Julia Lee Maxos</InstructorName>
          </InstructorBox>
          <CourseDetailsBox>
            <StatusBox>
              <StatusHeading>Status</StatusHeading>
              <StatusTypo>COMPLETED</StatusTypo>
            </StatusBox>
            <StatusBox>
              <StatusHeading>Expiration Date</StatusHeading>
              <StatusTypo>WITHIN 1 WEEK</StatusTypo>
            </StatusBox>
            <ProgressTypo>100%</ProgressTypo>
          </CourseDetailsBox>
        </CourseHeadingBox>
        </Grid>
        </Grid>
        </CourseBox>
    )
  }
  renderFeedbackCourses=()=>{
    return (
      <CourseFeedbackBox>
        <Grid container spacing={6} style={{justifyContent:"space-between"}} >
        <Grid md={4} lg={4} item style={{display:"flex"}}>
        <ImageBox>
          <img src={courseTwo} style={{width: "150%",height:"100%",borderRadius: "18px"}}/>
        </ImageBox>
        </Grid>
        <Grid md={7} lg={7} item>
        <CourseSideBox>
          <CourseNameBox>
            <Box>
            <CourseHeading>Course Name/Instructor Name</CourseHeading>
            <CourseName>React JS/Nicolas Cage</CourseName>
            </Box>
            <img src={arrowView} style={{width: "32px", height: "28px", gap: "10px"}}/>
          </CourseNameBox>
          <CourseDetailsBox>
            <StatusBox>
              <StatusHeading>Status</StatusHeading>
              <StatusTypo>COMPLETED</StatusTypo>
            </StatusBox>
            <StatusBox>
              <StatusHeading>Expiration Date</StatusHeading>
              <StatusTypo>WITHIN 1 WEEK</StatusTypo>
            </StatusBox>
            <ProgressTypo>100%</ProgressTypo>
          </CourseDetailsBox>
            <CourseAccordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>} style={{
                   display: "flex",justifyContent:"space-between",
              }}>
                <img src={personsIcons} style={{width: "37.94px", height: "23.47px"}}/>
              </AccordionSummary>
              <CourseAccordionDetails>
                <ChatBox>
                  <ChatPersonDetail>
                    <PersonImageBox>
                      <img src={person1} style={{width: "23.47px",
                        height: "23.47px",
                        borderRadius: "17.6px",}}/>
                      <PersonNameBox>
                        <PersonNameTypo>Yash</PersonNameTypo>
                        <PersonPositionTypo>Instructor</PersonPositionTypo>
                      </PersonNameBox>
                     </PersonImageBox>
                     <img src={chatIcon} style={{width: "26px",
                        height: "26px",}} onClick={this.handleChatModal}/>
                  </ChatPersonDetail>
                  <PersonCommentBox>
                    <CommentTypo>
                    “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </CommentTypo>
                  </PersonCommentBox>
                  <RatingsBox>
                    <DateBox>
                      <DateTypo>20/4/2024-Edited</DateTypo>
                    </DateBox>
                    <StarBox>
                      <StarTypo>4.5</StarTypo><img src={starRatingIcon} style={{ width: "67.37px",height: "13.47px",}}/>
                    </StarBox>
                  </RatingsBox>
                </ChatBox>
                <ChatBox>
                  <ChatPersonDetail>
                    <PersonImageBox>
                      <img src={person2} style={{width: "23.47px",
                        height: "23.47px",
                        borderRadius: "17.6px",}}/>
                      <PersonNameBox>
                        <PersonNameTypo>M Yash.</PersonNameTypo>
                        <PersonPositionTypo>Student</PersonPositionTypo>
                      </PersonNameBox>
                     </PersonImageBox>
                     <img src={chatIcon} style={{width: "26px",
                        height: "26px",}}/>
                  </ChatPersonDetail>
                  <PersonCommentBox>
                    <CommentTypo>
                    “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </CommentTypo>
                  </PersonCommentBox>
                  <RatingsBox>
                    <DateBox>
                      <DateTypo>22/4/2024</DateTypo>
                    </DateBox>
                    <StarBox>
                      <StarTypo>4.5</StarTypo><img src={starRatingIcon} style={{ width: "67.37px",height: "13.47px",}}/>
                    </StarBox>
                  </RatingsBox>
                </ChatBox>
              </CourseAccordionDetails>
            </CourseAccordion>
          </CourseSideBox>
        </Grid>
        </Grid>
      </CourseFeedbackBox>
    )
  }
  renderAllCourses=()=>{
    return (
      <AllCourses>
          {this.renderAssignedCourses()}
          {this.renderFeedbackCourses()}
          {this.renderAssignedCourses()}
          {this.renderFeedbackCourses()}
          {this.renderAssignedCourses()}
      </AllCourses>
    )
  }

  renderMessageBox=()=>{
    return (
      <MyMessageBox>
            <PersonImageBox>
              <img src={person2} style={{width: "23.47px",
                height: "23.47px",
                borderRadius: "17.6px",}}/>
              <PersonNameBox>
                <PersonNameTypo>M Yash.</PersonNameTypo>
                <PersonPositionTypo>Student</PersonPositionTypo>
              </PersonNameBox>
            </PersonImageBox>
            <CommentBoxTypo>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </CommentBoxTypo>      
            <TimeTypo>
              Yestreday 21:34 P.M.
            </TimeTypo>  
            </MyMessageBox>
            
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
      <HamburgerMenu   navigation={this.props.navigation} open={this.state.open} handleMenu={this.handleMenu}  themeDark={this.state.darkTheme} handleTheme={this.handleTheme}/>
      <NavigationMenu navigation={this.props.navigation} id={""} theme={this.state.darkTheme} open={this.state.open}/>
      <MainContainer data-test-id="mainContainer" style={{paddingLeft: this.state.open ? 190 : 50}}>
      <ChatModal open={this.state.openChatModal} onClose={this.closeChatModal}>
        <ModalPaper>
          <ReplyBox>
            <ReplyTypo>Reply to feedback</ReplyTypo>
            <DividerLine></DividerLine>
            <InstructorTypo>Instructor/Manager Name</InstructorTypo>
            <DividerLine></DividerLine>
            <img src={closeIcon} style={{width:"20px",height:"20px"}} onClick={this.closeChatModal}/>
          </ReplyBox>
          <MessageBox>
            {this.renderAssignedCourses()}
            {this.renderMessageBox()}
          </MessageBox>
        
            <TextFieldBox>
              <InputContainer>
                <InputField placeholder="Type your Reply" />
                <IconPinned src={pinIcon}/>
              </InputContainer>
              <img src={sendIcon} style={{width:"28.13px",height:"26.98px"}}/>
            </TextFieldBox>
        </ModalPaper>
      </ChatModal>          
      <ChatModal open={this.state.openFeedbackModal} onClose={this.closeFeedbackModal}>
        <ModalPaper>
          <ReplyBox>
            <ReplyTypo>Leave Feedback</ReplyTypo>
            <DividerLine></DividerLine>
            <InstructorTypo>Worker Name</InstructorTypo>
            <DividerLine></DividerLine>
            <img src={closeIcon} style={{width:"20px",height:"20px"}} onClick={this.closeFeedbackModal}/>
          </ReplyBox>
          {this.renderUserDetails()}
          <MessageBox>
            {this.renderAssignedCourses()}

            <MyMessageBox>
              <LeaveFeedbackTypo>Leave your Feedback</LeaveFeedbackTypo>
              <FormTextArea placeholder="Type here your feedback" minRows={4}></FormTextArea>
              <CounterTypo>0/100</CounterTypo>
            </MyMessageBox>
            <StarReviewBox>
              <img src={oneStarIcon} style={{height:"32px"}}/>
              <img src={twoStarIcon} style={{height:"32px"}}/>
              <img src={threeStarIcon} style={{height:"32px"}}/>
              <img src={fourStarIcon} style={{height:"32px"}}/>
              <img src={selectedStarIcon} style={{height:"32px"}}/>
              <img src={fiveStarIcon} style={{height:"32px"}}/>
            </StarReviewBox>
            <ButtonBox>
              <CloseButton>Close</CloseButton>
              <PublishButton>Publish</PublishButton>
            </ButtonBox>
            </MessageBox>
        </ModalPaper>
      </ChatModal>
      <ChatModal open={this.state.openDirectMessageModal} onClose={this.closeDMModal}>
        <ModalPaper>
          <ReplyBox>
            <ReplyTypo>Direct Message</ReplyTypo>
            <DividerLine></DividerLine>
            <InstructorTypo>Worker Name</InstructorTypo>
            <DividerLine></DividerLine>
            <img src={closeIcon} style={{width:"20px",height:"20px"}} onClick={this.closeDMModal}/>
          </ReplyBox>
          {this.renderUserDetails()}
          <MessageBox>
          <MyMessageBox>
            <PersonImageBox>
              <img src={person2} style={{width: "23.47px",
                height: "23.47px",
                borderRadius: "17.6px",}}/>
              <PersonNameBox>
                <PersonNameTypo>M Yash.</PersonNameTypo>
                <PersonPositionTypo>Student</PersonPositionTypo>
              </PersonNameBox>
            </PersonImageBox>
            <CommentBoxTypo>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </CommentBoxTypo>      
            <TimeTypo>
              Yestreday 21:34 P.M.
            </TimeTypo>  
            </MyMessageBox>
            <MyMessageBox>
            <PersonImageBox>
              <img src={person2} style={{width: "23.47px",
                height: "23.47px",
                borderRadius: "17.6px",}}/>
              <PersonNameBox>
                <PersonNameTypo>M Yash.</PersonNameTypo>
                <PersonPositionTypo>Student</PersonPositionTypo>
              </PersonNameBox>
            </PersonImageBox>
            <CommentBoxTypo>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </CommentBoxTypo>      
            <TimeTypo>
              Yestreday 21:34 P.M.
            </TimeTypo>  
            </MyMessageBox>
            
          </MessageBox>
          <TextFieldBox>
              <InputContainer>
                <InputField placeholder="Type your Reply" />
                <IconPinned src={pinIcon}/>
              </InputContainer>
              <img src={sendIcon} style={{width:"28.13px",height:"26.98px"}}/>
            </TextFieldBox>
        </ModalPaper>
      </ChatModal>

          
          {this.renderWelcomeTopBar(this.state.profileData.attributes.role_id)}  
          {this.renderCourseSection()}
        </MainContainer>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Grid)(({ theme }) => ({
  paddingTop:'10px',
  paddingRight: 48,
  paddingBottom: 50,
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"flex-start",
  background: theme.palette.background.default,
  '& .MuiPaper-root': {
      backgroundColor: theme.palette.primary.main
  }
}))
const ProfileImageNameBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px"
}))
const NameBox = styled(Box)(({ theme }) => ({

  
}))
const NameTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.main
}))
const PositionTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.light,
  textTransform:"capitalize",
}))
const ImageBox = styled(Box)(({ theme }) => ({
  flex:1,
  display:"flex",
  flexDirection:"column",
}))

const WelcomeBar = styled(Box)(({ theme }) => ({
  width: "80%",
  height: "186px",
  gap: "15px",
  opacity: "0px",
  borderRadius: "18px",
  padding: "8px 12px 8px 12px",
  background: theme.palette.background.paper,
  margin:"23px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"space-between",
}))
const WelcomeGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width:"100%",
  gap:"20px"
}))
const BreadcrumbBox = styled(Box)(({ theme }) => ({
  height: "68px", 
  gap: "2px", 
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"center",
  borderRadius:"30px",
  backgroundColor:theme.palette.background.default,
}))
const BreadcrumbTypo = styled(Typography)(({ theme }) => ({
  color: "#475569",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  textAlign: "left"
}))
const BreadcrumbActiveTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  textAlign: "left",
  color: "#0F172A"
}))
const NavigateNextIcon = styled(NavigateNext)(({ theme }) => ({
  width: "20px",
  height: "20px",
  color: "#475569",
}))
const UserDetailBox = styled(Box)(({ theme }) => ({
  height: "47.64px",
  justifyContent: "space-between",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
}))
const EachUserDetailBox = styled(Box)(({ theme }) => ({
  width: "144px", height: "47.64px",
  justifyContent: "center",
  display:"flex",
  flexDirection:"column",
  alignItems:"baseline"
}))
const HeadingTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#475569"
}))
const DetailTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
  color: "#0F172A"
}))
const AwardGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width:"100%",
  paddingBottom:"38px",
  paddingTop:"24px",
}))
const AwardListBox = styled(Box)(({ theme }) => ({
  borderLeft: "1px solid #E2E8F0",
  padding: "0px 8px 0px 8px",
  gap: "12px",
  display:"flex",
  flexDirection:"row",
  justifyContent:"center",
  alignItems:"center",
}))
const AwardListItemBox = styled(Box)(({ theme }) => ({
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  flexDirection:"column",
}))
const ItemHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.action.disabled
}))
const ItemCount = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const MainCourseSection = styled(Box)(({ theme }) => ({
  width: "80%",
  height: "547px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"center",
  gap:"20px",
}))
const GraphSection = styled(Box)(({ theme }) => ({
  height: "547px", gap: "20px",
  display:"flex",
  flexDirection:"column",

}))
const GraphHeadingTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "26px",
  textAlign: "left",
  color: "#0F172A",
  width: "260px", height: "26px"
}))
const AverageGraphSection = styled(Box)(({ theme }) => ({
  height: "547px", gap: "20px",
  display:"flex",
  flexDirection:"column",
  borderRadius:"18px",
  backgroundColor:theme.palette.background.paper,
  padding:"24px 25px",
  alignItems:"first baseline",
  justifyContent:"center",
}))
const CourseSection = styled(Box)(({ theme }) => ({
  height: "547px",
  gap: "0px",
  borderRadius:"18px",
  display:"flex",
  flexDirection:"column",
  backgroundColor:theme.palette.background.paper,
  alignItems:"first baseline",
  padding:"24px 42.26px"

}))
const CourseHeaderSection = styled(Box)(({ theme }) => ({
  height: "36px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "20px",
  margin: "15px",
  alignItems: "center",
  width:"90%",
}))
const CourseTabSection = styled(Box)(({ theme }) => ({
  height: "40px",
  padding: "4px",
  borderRadius: "50px",
  justifyContent: "space-between",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  backgroundColor:theme.palette.background.default
}))
const SelectedFilter = styled(Box)(({ theme }) => ({
  width: "98px",
  height: "28px",
  padding: "6px 12px 6px 12px",
  gap: "6px",
  borderRadius: "50px",
  opacity: "0px",
  backgroundColor: "#EDF1F6",
  display:"flex",
  alignItems:"center"
}))
const SelectedFilterTypo = styled(Typography)(({ theme }) => ({
  width: "74px",
  height: "22px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  textAlign: "left",
  color: "#1A469C"
}))
const DeselectedFilter = styled(Box)(({ theme }) => ({
  width: "105px",
  height: "28px",
  padding: "6px 12px 6px 12px",
  gap: "6px",
  borderRadius: "50px",
  display:"flex",
  alignItems:"center",
}))
const DeselectedFilterTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  textAlign: "left",
  width: "100px",
  height: "22px",
  color: "#475569"
}))
const AllCourses = styled(Box)(({ theme }) => ({
  width:"90%",
  height: "448px",
  gap: "8px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  overflowY:"auto",
  scrollbarWidth: "thin", 
  "&::-webkit-scrollbar": {
    width: "13px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "30px 30px 90px 30px",
    backgroundColor: "transparent",
    border: "1px solid #CBD5E1",
  },
  "&::-webkit-scrollbar-thumb": {
    width: "13px",
    height: "43px",
    borderRadius: "30px 30px 90px 30px",
    backgroundColor: "#EDF1F6",
    border: "1px solid #94A3B8",
  },
}))
const CourseBox = styled(Box)(({ theme }) => ({
  height: "121.85px",
  gap: "16px",
  borderRadius: "18px",
  opacity: "0px",
  backgroundColor: "#FFFFFF",
  display:"flex",
  flexDirection:"row",
  justifyContent:"space-between",
  alignItems:"center"
}))
const CourseFeedbackBox = styled(Box)(({ theme }) => ({
  height: "auto",
  gap: "16px",
  borderRadius: "18px",
  opacity: "0px",
  backgroundColor: "#FFFFFF",
  display:"flex",
  flexDirection:"row",
  justifyContent:"space-between",
  alignItems:"center",
}))
const CourseHeadingBox = styled(Box)(({ theme }) => ({
  height: "121.85px",
  padding: "10px 14px 10px 0px",
  gap: "8px",
  display:"flex",
  flexDirection:"column",
}))
const CourseSideBox = styled(Box)(({ theme }) => ({
  height: "auto",
  padding: "10px 14px 10px 0px",
  gap: "8px",
  display:"flex",
  flexDirection:"column",
}))
const CourseNameBox = styled(Box)(({ theme }) => ({
  height: "30px",
  gap: "0px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  justifyContent:"space-between"
}))
const CourseHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "11.85px",
  textAlign: "left",
  color: "#475569"
}))
const CourseName = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#0F172A"
}))
const InstructorBox = styled(Box)(({ theme }) => ({
  height: "30px",
  gap: "0px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column"
}))
const InstructorHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "11.85px",
  textAlign: "left",
  color: "#475569"
}))
const InstructorName = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#0F172A"
}))
const CourseDetailsBox = styled(Box)(({ theme }) => ({
  height: "25.85px",
  gap: "0px",
  justifyContent: "space-between",
  opacity: "0px",
  display:"flex",
  flexDirection:"row"
}))
const CourseAccordion = styled(Accordion)(({ theme }) => ({
  height: "auto",
  padding: "8px 0px 0px 0px",
  justify: "space-between",
  opacity: "0px",
  borderTop: "1px solid #D7D7D7",
  display:"flex",
  flexDirection:"column",
  boxShadow: "none",
}))
const CourseAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  height: "auto",
  display:"flex",
  flexDirection:"column",
  gap:"20px",
}))
const StatusBox = styled(Box)(({ theme }) => ({
  width: "101px",
  height: "25.85px",
  gap: "2px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column"
}))

const StatusHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "11.85px",
  textAlign: "left",
  color: "#475569"
}))

const StatusTypo = styled(Typography)(({ theme }) => ({
  width: "78.85px",
  height: "11.85px",
  padding: "1.48px 5.93px 1.48px 5.93px",
  gap: "1.48px",
  borderRadius: "29.63px",
  opacity: "0px",
  backgroundColor: "#D1FAE5",
  fontFamily: "Rubik",
  fontSize: "8.89px",
  fontWeight: 500,
  lineHeight: "13.33px",
  textAlign: "left",
  color: "#059669"
}))
const CommentBoxTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "19px",
  textAlign: "left",
  width: "602px",
  height: "95px",
  gap: "0px",
  opacity: "0px",
  color: "#0F172A"
}))
const ProgressTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  color: "#0F172A"
}))
const ExpandMoreIcon = styled(ExpandMore)(({ theme }) => ({
  color: "#1A469C",
  width: "24px",
  height: "24px",
}))
const ChatBox = styled(Box)(({ theme }) => ({
  height: "149.88px",
  gap: "6px",
  border: "1px 0px 0px 0px",
  display:"flex",
  flexDirection:"column",
  }))
  const ChatPersonDetail = styled(Box)(({ theme }) => ({
    height: "26.4px",
    justifyContent: "space-between",
    display:"flex",
    flexDirection:"row",
  }))
  const PersonImageBox = styled(Box)(({ theme }) => ({
    width: "80px",
    height: "26.4px",
    padding: "0.23px 165.56px 0.36px 0px",
    display:"flex",
    flexDirection:"row"
  }))
  const PersonNameBox = styled(Box)(({ theme }) => ({
    width: "43px",
    height: "25.81px",
    gap: "4.69px",
    opacity: "0px",
    display:"flex",
    flexDirection:"column"
  }))
  const PersonNameTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Rubik",
      fontSize: "8.21px",
      fontWeight: 700,
      lineHeight: "10.56px",
      textAlign: "left",
      color: "#21272A",
      width: "43px",
      height: "22px",
      gap: "0px",
      opacity: "0px"
    }))
    
    const PersonPositionTypo = styled(Typography)(({ theme }) => ({
      fontFamily: "Rubik",
      fontSize: "7.04px",
      fontWeight: 500,
      lineHeight: "10.56px",
      textAlign: "left",
      width: "43px",
      height: "22px",
      gap: "0px",
      opacity: "0px",
      color: "#848484"
    }))
  const PersonCommentBox = styled(Box)(({ theme }) => ({
    height: "auto",
    gap: "0px",
    opacity: "0px",
  }))
  const RatingsBox = styled(Typography)(({ theme }) => ({
    height: "13.47px",
    justifyContent: "space-between",
    display:"flex",
    flexDirection:"row"
  }))
  const DateBox = styled(Typography)(({ theme }) => ({
    width: "90px",
    height: "12px",
  }))
  const StarBox = styled(Box)(({ theme }) => ({
    width: "94.72px",
    height: "13.47px",
    gap: "7.35px",
    opacity: "0px",
    display:"flex",
    flexDirection:"row"
  }))
  const LeaveFeedbackTypo = styled(Typography)(({ theme }) => ({
    width: "142px",
   height: "22px",
   gap: "0px",
   opacity: "0px",
   fontFamily: "Rubik",
   fontSize: "14px",
   fontWeight: 400,
   lineHeight: "22px",
   textAlign: "left",
   color: "#334155"
  }))
  const CounterTypo = styled(Typography)(({ theme }) => ({
   width: "670px",
   height: "18px",
   gap: "0px",
   opacity: "0px",
   fontFamily: "Rubik",
   fontSize: "12px",
   fontWeight: 400,
   lineHeight: "18px",
   textAlign: "right",
   color: "#334155"
  }))
  const FormTextArea=styled(TextareaAutosize)(({theme})=>({
  color: "#475569",
   fontFamily: "Rubik",
   fontSize: "16px",
   fontWeight: 400,
   lineHeight: "24px",
   textAlign: "left",
   width: "670px",
   height: "98px",
   gap: "10px",
   borderRadius: "8px",
   opacity: "0px",
   border: "1px solid #CBD5E1",
   backgroundColor:theme.palette.background.default
  }))
  const StarReviewBox = styled(Box)(({ theme }) => ({
     width: "670px",
    height: "32px",
    gap: "10px",
    opacity: "0px",
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"center",
  }))
  const ButtonBox =styled(Box)(({ theme }) => ({
    width: "670px",
    height: "44px",
    gap: "14px",
    opacity: "0px",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center"
  }))
  const CloseButton = styled(Button)(({ theme }) => ({
    width: "183px",
     height: "44px",
     padding: "10px 16px 10px 16px",
     gap: "8px",
     borderRadius: "30px",
     opacity: "0px",
     border: "1px solid #1A469C",
     textTransform:"capitalize",
     "& .MuiButton-label":{
       width: "55px",
     height: "24px",
     gap: "0px",
     opacity: "0px",
     fontFamily: "Rubik",
     fontSize: "16px",
     fontWeight: 700,
     lineHeight: "24px",
     textAlign: "left",
   color:"#1A469C",
     },
     "&:hover": {
       background: "#FFFFFF",  
       color: "#1A469C"
     }
   }))
   const PublishButton = styled(Button)(({ theme }) => ({
    width: "473px",
     height: "44px",
     padding: "10px 16px 10px 16px",
     gap: "8px",
     borderRadius: "30px",
     opacity: "0px",
     backgroundColor: "#1A469C",
     textTransform:"capitalize",
     "& .MuiButton-label":{
      width: "60px",
     height: "24px",
     gap: "0px",
     opacity: "0px",
     fontFamily: "Rubik",
     fontSize: "16px",
     fontWeight: 700,
     lineHeight: "24px",
     textAlign: "left",
     color: "#F8FAFC",
     },
     "&:hover": {
       background: "#1A469C",  
       color: "#F8FAFC"
     }
   }))
  
  const CommentTypo = styled(Typography)(({ theme }) => ({
    height: "auto",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "10.8px",
    letterSpacing: "0.41111114621162415px",
    textAlign: "left",
    color: "#475569"
  }))
  
  const DateTypo = styled(Typography)(({ theme }) => ({
    width: "90px",
    height: "12px",
    fontFamily: "Rubik",
    fontSize: "9.19px",
    fontWeight: 400,
    lineHeight: "11.02px",
    textAlign: "left",
    color: "#848484"
  }))
  const StarTypo = styled(Typography)(({ theme }) => ({
    width: "20px",
    height: "12px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "9.19px",
    fontWeight: 400,
    lineHeight: "11.02px",
    textAlign: "left",
    color: "#475569"
  }))
  const ReplyBox = styled(Box)(({ theme }) => ({
    width: "700px", height: "32px", gap: "12.48px", display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",
}))
const MessageBox = styled(Box)(({ theme }) => ({
width: "700px", height: "400px", gap: "8px",
display:"flex",
flexDirection:"column",
overflowY:"auto",
"&::-webkit-scrollbar": {
  width: "13px",
},
"&::-webkit-scrollbar-thumb": {
  background: "#EDF1F6",
  height: "43px",
  width:"13px",  
  borderRadius: "30px 30px 90px 30px", 
  border: "1px solid  #94A3B8", 
  opacity: "1",
},
"&::-webkit-scrollbar-thumb:hover": {
  opacity: 1,
},
"&::-webkit-scrollbar-track": {
  background: "transparent", 
  borderRadius: "30px 30px 90px 30px", 
  border: "1px solid  #94A3B8", 
  opacity:"1"
},

scrollbarWidth: "thin",  
scrollbarColor: "var(--Slider-Slider-Bg, #EDF1F6) transparent", 
}))
const TextFieldBox = styled(Typography)(({ theme }) => ({
  width: "700px",
  height: "44px",
  gap: "20px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  justifyContent:"center",
  alignItems:"center",
}))
const MyMessageBox =styled(Box)(({ theme }) => ({
  width: "655px",
  height: "203px",
  top: "0.25px",
  left: "14.92px",
  padding: "12px 24px 12px 24px",
  gap: "14px",
  borderRadius: "14.8px",
  opacity: "0px"
}))
const TimeTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "19px",
  textAlign: "right",
  width: "607px",
  height: "19px",
  gap: "0px",
  opacity: "0px",
  color: "#475569"
}))
const ReplyTypo = styled(Typography)(({ theme }) => ({
fontFamily: "Rubik",
  fontSize: "24.96px",
  fontWeight: 500,
  lineHeight: "29.58px",
  textAlign: "center",
  width: "225px",
  height: "30px",
  gap: "0px",
  opacity: "0px",
  color: "#0F172A"
}))

const InstructorTypo = styled(Typography)(({ theme }) => ({
 fontFamily: "Rubik",
  fontSize: "17.16px",
  fontWeight: 400,
  lineHeight: "20.34px",
  textAlign: "left",
  width: "208px",
  height: "20px",
  gap: "0px",
  opacity: "0px",
  color: "#334155"
}))

const DividerLine = styled('hr')(({ theme }) => ({
  width: "92.08px",
  height: "1.56px",
  gap: "0px",
  opacity: "0px",
  backgroundColor: "#D7D7D7",
  border:"none",
}))

const InputField = styled('input')(({ theme }) => ({
 width: "651.87px",
  height: "44px",
  padding: "10px 24px 10px 24px",
  gap: "8px",
  borderRadius: "8px",
  opacity: "0px",
  backgroundColor:theme.palette.background.default,
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color: "#475569",
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #D7D7D7'
  },
  '& .MuiInput-underline:after': {
      borderBottom: '1px solid #D7D7D7'
  }
}))
const IconPinned = styled('img')({
  position: 'absolute', 
  right: '10px', 
  top: '50%',  
  transform: 'translateY(-50%)',
  width: '24px',  
  height: '24px',
});
const InputContainer = styled('div')({
  position: 'relative',
  width: '100%',
});
const ChatModal = styled(Modal)(({ theme }) => ({
  width: "772px",
  height: "564px",
  padding: "30px 36px 30px 36px",
  gap: "14px",
  borderRadius: "23.4px 0px 0px 0px",
  border: "0.78px 0px 0px 0px",
  opacity: "0px",
  boxShadow: "0px 6.24px 24.96px 0px #0000000F",
  top:"25% !important",
  left:"22% !important",
}))
const ModalPaper = styled(Paper)(({ theme }) => ({
  width: "772px",
  height: "564px",
  padding: "30px 36px 30px 36px",
  gap: "14px",
  borderRadius: "23.4px",
  backgroundColor: "#F9F9F9",
  top:"25% !important",
  left:"22% !important",
}))
const FontStyle = {
  fontFamily: 'Rubik',
  h1: { fontSize: '32px' },
  h2: { fontSize: '30px' },
  h3: { fontSize: '28px' },
  h4: { fontSize: '26px' },
  h5: { fontSize: '24px' },
  h6: { fontSize: '22px' },
  subtitle1: { fontSize: '20px' },
  subtitle2: { fontSize: '18px' },
  body1: { fontSize: '16px' },
  body2: { fontSize: '14px' }
}

export const lightTheme = createTheme({
  palette: {
      primary: {
          main: '#FFFFFF',
          light:"010101"
      },
      secondary: {
          main: '#E2E8F0',
          light:"#D1FAE5",
          dark:"#0F172A"
      },
      background: {
          default: '#FFFFFF',
          paper: 'rgba(238,238,238,1)'
      },
      text: {
          primary: '#334155',
          secondary: '#475569',
          hint: '#0F172A',
      },
      action: {
          active: '#F1F5F9',
          selected: '#1A469C',
          focus: '#059669',
          disabled: '#475569'
      },
      info: {
          main: "#21272A",
          light: '#848484',
          dark: '#CBD5E1',
          contrastText:'#1A469C'
      },
  },
  typography: FontStyle
});
export const darkTheme = createTheme({
  palette: {
      primary: {
          main: '#161616',
          light:"#BDCFF4"
      },
      secondary: {
          main: '#18212E',
          light:"#074525",
          dark:"#BDCFF4"
      },
      background: {
          default: '#0D0D0D',
          paper: 'rgba(26,26,26,1)',
      },
      text: {
          primary: '#D5DDF0',
          secondary: '#93A1B5',
          hint: '#D5DDF0'
      },
      action: {
          active: '#70A1FF',
          selected: '#70A1FF',
          focus: '#42EBB5',
          disabled: '#99A7BB'
      },
      info: {
          main: "#D5DDF0",
          light: '#99A7BB',
          dark: '#273444',
          contrastText:'#12326F'
      },
  },
  typography: FontStyle
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
