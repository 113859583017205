export const mailIcon = require("../assets/mailIcon.svg");
export const bellIcon = require("../assets/bellIcon.svg");
export const profileImage = require("../assets/profilePic.svg");
export const calendarIcon = require("../assets/calendarIcon.svg");
export const mouseIcon = require("../assets/mouseIcon.svg");
export const progressIcon = require("../assets/progress.svg");
export const progressIconDark = require("../assets/progressDark.png");
export const timeIcon = require("../assets/timeIcon.svg");
export const averageScoreIcon = require("../assets/averageScoreIcon.svg");
export const completedIcon = require("../assets/completedIcon.svg");
export const certificateIcon = require("../assets/certificateIcon.svg");
export const ceritficateIconDark = require("../assets/badgeIconDark.png");
export const backgroundImage = require("../assets/backgroundImage.svg");
export const backgroundImageDark = require("../assets/backgroundImageDark.png");
export const courseOne = require("../assets/courseOne.png");
export const courseTwo = require("../assets/courseTwo.png");
export const courseThree = require("../assets/courseThird.png");
export const viewAllIcon = require("../assets/viewAllIcon.png");
export const assignedImage = require("../assets/assignedImage.png");
export const courseAddIcon = require("../assets/courseAddIcon.png");
export const degreeIcon = require("../assets/degreeIcon.png");
export const degreeIconDark = require("../assets/degreeIconDark.png");
export const editIcon = require("../assets/editIcon.png");
export const enrollIcon = require("../assets/enrollIcon.png");
export const enrollIconDark = require("../assets/enrollIconDark.png");
export const fileUploadIcon = require("../assets/fileUploadIcon.png");
export const fileUploadIconDark = require("../assets/fileUploadIconDark.png");
export const personButton = require("../assets/personButtonIcon.png");
export const scoreIcon = require("../assets/scoreIcon.png");
export const scoreIconDark = require("../assets/scoreIconDark.png");
export const taskPinIcon = require("../assets/taskPinIcon.png");
export const timerIcon = require("../assets/timerIcon.png");
export const timerIconDark = require("../assets/timerIconDark.png");
export const uploadCourseIcon = require("../assets/uploadCourseIcon.png");
export const uploadCourseIconDark = require("../assets/uploadCourseIconDark.png");
export const bulbIcon = require("../assets/bulbIcon.png");
export const bulbIconDark = require("../assets/bulbIconDark.png");
export const completedCoursesIcon = require("../assets/completedCoursesIcon.png");
export const completedCoursesIconDark = require("../assets/completedCoursesIconDark.png");
export const mediaLibray1 = require("../assets/mediaLibrary1.png");
export const mediaLibray2 = require("../assets/mediaLibrary2.png");
export const mediaLibray3 = require("../assets/mediaLibrary3.png");
export const mediaLibray4 = require("../assets/mediaLibrary4.png");
export const messageIcon = require("../assets/messageIcon.png");
export const messageIconDark = require("../assets/messageIconDark.png");
export const teamMember = require("../assets/teamMemberIcon.png");
export const addSqureIcon = require("../assets/addSquareIcon.png");
export const completeTick = require("../assets/completeTickDark.png");
export const completeTickDark = require("../assets/completeTick.png");
export const darktheme = require("../assets/darktheme.png")
export const whitetheme = require("../assets/whitetheme.png")