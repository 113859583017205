import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
  styled,Button
} from "@material-ui/core";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import { templateOne,moreIcon,order } from "./assets";
import { Pagination } from "@material-ui/lab";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import SearchIcon from '@material-ui/icons/Search';
import { Select } from "antd";
import { Course } from "./PtTestsForCourseScreenController";
const theme = createTheme({
  palette: {
    primary: {
      main: "#afe9ed",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const StatusOptions = [
  { label: 'Assigned', value: 'assigned' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Completed', value: 'completed' }
]

const sortOptions = [
  { value: "asc", label: "Ascending" },
  { value: "desc", label: "Descending" },
];

// Customizable Area End
import PtTestsForCourseScreenController, {
  Props,
  configJSON,
} from './PtTestsForCourseScreenController';
export default class PtTestsForCourseScreen extends PtTestsForCourseScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  LeftFilterOptions = () => {
    return <div style={{ display: 'flex' }}>
      <CourseTabSection>
        <SelectedFilter style={{width:"42px"}}><SelectedFilterTypo>All</SelectedFilterTypo></SelectedFilter>
        <DeselectedFilter style={{width:"87px"}}><DeselectedFilterTypo>Downloaded</DeselectedFilterTypo></DeselectedFilter>
        <DeselectedFilter style={{width:"135px"}}><DeselectedFilterTypo style={{width:"135px"}}>About to expire</DeselectedFilterTypo></DeselectedFilter>
        <DeselectedFilter style={{width:"98px"}}><DeselectedFilterTypo>Completed</DeselectedFilterTypo></DeselectedFilter>
      </CourseTabSection>
     </div>
  }

  RightFilterOptions = () => {
    const open = Boolean(this.state.anchorSort);
    return <div style={{ display: 'flex', alignItems: 'center',gap:"8px",justifyContent:"space-between" }}>
       <FilterSelect
            data-test-id="Group"
            className={this.state.selectedGroup ? "selectFilter" : ""}
            value={this.state.selectedGroup || undefined}
            
            placeholder="Course Category"
            dropdownMatchSelectWidth={false}
          >
            {this.state.allGroupName?.map((item) => (
              <Select.Option value={item.group_name}>
                {item.group_name}
              </Select.Option>
            ))}
          </FilterSelect>
        <SearchBox
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ fill: "#1A469C", width: "24px", height: '24px' }} />
              </InputAdornment>
            ),
          }}
          data-test-id="searchParam"
          placeholder="Search"
          style={{ width: '200px' }}
        />
        
        <img src={order}
          data-test-id="enableSearch"
          style={{ height: '32px', width: '32px', fill: "#1A469C" }}
        />
        
      
      {(this.state.selectedStatus || this.state.enableExpDate) && <>
        <img
          src={order}
          alt="sort"
          style={{ cursor: "pointer" }}
          data-test-id="openAnchorSort"
          id="basic-button"
          onClick={(event: React.MouseEvent<HTMLImageElement>) => {
            this.setState({ anchorSort: event.currentTarget })
          }}
        />
        <Menu
          data-test-id="closeSort"
          id="basic-menu"
          anchorEl={this.state.anchorSort}
          open={open}
          onClose={() => this.setState({ anchorSort: null })}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          style={{ top: "50px" }}
        >
          <MenuItem data-test-id="removeImage" disabled>
            SORT BY
          </MenuItem>
          {sortOptions.map((item) => (
            <MenuItem data-test-id="removeImage">
              <span
                data-test-id="sortEmployee"
                style={{
                  color: "#1A469C",
                  fontFamily: "Rubik",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "22px",
                }}
                
              >
                {item.label}
              </span>
            </MenuItem>
          ))}
        </Menu> </>}
    </div>
  }
  renderEachCourseBox=(allCourse:Course[])=>{
    return allCourse.map((course)=>(
      <EachCourseBox onClick={()=>this.setCourseIdProjectPage(course.id)} data-test-id="createTestButton">
        <img src={templateOne} style={{width: "50%",
          height: "92px",
          gap: "0px",
          borderRadius: "14px",
          opacity: "0px",
          backgroundColor: "#00000033"}}/>
        <CourseDetailBox>
          <SubDetailBox>
          <CourseNameBox>
            <CourseNameTypo>Course Category/Course Name</CourseNameTypo>
            <NameTypo data-test-id="categoryTestId">
            {JSON.stringify(this.renderCategoryName(course.attributes.category))} / {JSON.stringify(course.attributes.course_name)}
            </NameTypo>
          </CourseNameBox>
          <img src={moreIcon} style={{width:"21.28px",height:"28.24px"}}/>
          </SubDetailBox>
          <SubDetailBox>
          <CourseNameBox>
            <CourseNameTypo>Instructor Name/Lessons</CourseNameTypo>
            <NameTypo> { JSON.stringify(course.attributes.preferred_name)} / {course.attributes.course_contents_count ?? 0}</NameTypo>
          </CourseNameBox>
          <ExpirationProgressBox>
            <ExpirationBox>
              <CourseNameTypo style={{width:"72px"}}>Expiration Date</CourseNameTypo>
              <YellowBox><YellowTypo> {typeof course.attributes.due_date === "string" ? course.attributes.due_date : "No Expiration"}</YellowTypo></YellowBox>
            </ExpirationBox>
            <ProgressTypo>100%</ProgressTypo>
          </ExpirationProgressBox>
          </SubDetailBox>
          <LinearProgressBar variant="determinate" value={50}></LinearProgressBar>
        </CourseDetailBox>
      </EachCourseBox>
    ))
  }
  renderMainComponent=()=>{
    return (
      <>
      <HamburgerMenu {...this.props} open={this.state.open} handleMenu={this.handleMenu}  themeDark={this.state.darkTheme} handleTheme={this.handleTheme}/>
        <NavigationMenu navigation={this.props.navigation} id={""} theme={this.state.darkTheme} open={this.state.open}/>
        <MainContainer style={{ paddingLeft: this.state.open ? 273 : 130, paddingRight: 48, transition: 'all 300ms linear' }}>
          <HeaderBox>
            <HeaderDetailsBox>
              <HeaderTypo>List of Courses</HeaderTypo>
              <HeaderLineTypo>Here you can find all Courses and downloaded courses for offline access</HeaderLineTypo>
            </HeaderDetailsBox>
          </HeaderBox>
          <AllCoursesBox>
          <FilterContainer>
              {this.LeftFilterOptions()}
              {this.RightFilterOptions()}
            </FilterContainer>

            <CourseSectionBox>
              {this.renderEachCourseBox(this.state.currentPageData)}
            </CourseSectionBox>
            <PaginationBox>
            <Pagination
              style={{ display: 'flex', alignSelf: "center" }}
              data-test-id="changePage"
              count={this.state.totalPage}
              page={this.state.currentPage}
              onChange={this.changePage}
            />
            </PaginationBox>
          </AllCoursesBox>
        </MainContainer>
        </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
        <Container maxWidth={false}>
          <Box>
            
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <Box>
              {this.renderMainComponent()}
            </Box>
            {/* Merge Engine UI Engine Code */}
            {/* Customizable Area End */}
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)(({ theme }) => ({
 justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column",gap:"20px",backgroundColor:theme.palette.background.default
  }))
  const HeaderBox = styled(Box)(({ theme }) => ({
    width: "90%",
    height: "113px",
    backgroundColor:theme.palette.background.paper,
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-start",
    justifyContent:"center",
    padding:"27px 37.78px",
    borderRadius:"18px"
  }))
  const HeaderDetailsBox = styled(Box)(({ theme }) => ({
    width: "632px",
    height: "59px",
    padding: "0px 301px 0px 0px",
    gap: "0px",
    opacity: "0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "self-start",
    justifyContent: "center"
  }))
  const HeaderTypo = styled(Typography)(({ theme }) => ({
    width: "315px",
    height: "30px",
    top: "5px",
    left: "1px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "30px",
    fontWeight: 500,
    lineHeight: "30px",
    letterSpacing: "0.41111114621162415px",
    textAlign: "left",
    color: theme.palette.text.hint,
  }))
  const HeaderLineTypo = styled(Typography)(({ theme }) => ({
    width: "510px",
    height: "14px",
    top: "43px",
    left: "1px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0.41111114621162415px",
    textAlign: "left",
    color: theme.palette.text.hint
  }))
  
export const FilterSelect = styled(Select)(({ theme }) => ({
  marginLeft: "6px",
  width: "176px",
  maxWidth: "176px",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.info.main,
  "& .ant-select:not(.ant-select-customize-input) .ant-select-selector":{
    borderRadius:"30px !important"
  },
  "& .ant-select-selector":{
    borderRadius:"30px !important"
  },
}));

  const AllCoursesBox = styled(Box)(({ theme }) => ({
    width: "90%",
    height: "621px",
    padding: "27px 34px 0px 34px",
    gap: "0px",
    borderRadius: "16.25px",
    opacity: "0px",    
    backgroundColor:theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center"
  }))
  const CourseSectionBox = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "492px",
    gap: "8px",
    opacity: "0px",
    display:"flex",
    flexDirection:"column",
    overflowY:"auto",
  scrollbarWidth: "thin", 
  "&::-webkit-scrollbar": {
    width: "13px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "30px 30px 90px 30px",
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.info.dark}`,
  },
  "&::-webkit-scrollbar-thumb": {
    width: "13px",
    height: "43px",
    borderRadius: "30px 30px 90px 30px",
    backgroundColor: "#EDF1F6",
    border: "1px solid #94A3B8",
  },
  }))
  
  const EachCourseBox = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "92px",
    gap: "16px",
    borderRadius: "18px",
    opacity: "0px",
    backgroundColor: theme.palette.background.default,
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between",
    cursor:"pointer",
  }))
  const CourseDetailBox = styled(Box)(({ theme }) => ({
    height: "92px",
    width:"48%",
    padding: "10px 14px 10px 0px",
    gap: "4px",
    opacity: "0px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    alignItems:"center",
  }))
  const SubDetailBox = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "30px",
    gap: "0px",
    justifyContent: "space-between",
    opacity: "0px",
    display:"flex",
    flexDirection:"row",
  }))
  const CourseNameBox = styled(Box)(({ theme }) => ({
    width: "293px", height: "30px", gap: "0px", opacity: "0px",display:"flex",flexDirection:"column",alignItems:"flex-start",
    justifyContent:"center"
  }))
  const ExpirationProgressBox = styled(Box)(({ theme }) => ({
    width: "132.85px",
    height: "25.85px",
    padding: "0px 8px 0px 8px",
    gap: "12px",
    opacity: "0px",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
  }))
  const ExpirationBox = styled(Box)(({ theme }) => ({
    width: "74.85px",
    height: "25.85px",
    gap: "4px",
    opacity: "0px",
    display:"flex",
    flexDirection:"column",
    alignItems:"Center",
    justifyContent:"Center",
  }))
  const MessageBox = styled(Box)(({ theme }) => ({
    width: "169px",
    height: "26.06px",
    padding: "8.03px 8px 8.03px 8px",
    gap: "8.03px",
    borderRadius: "30.11px",
    opacity: "0px",
    backgroundColor: "#F9F9F9",
  }))
  const WarningTypo = styled(Typography)(({ theme }) => ({
   width: "153px",
    height: "10px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "8.03px",
    fontWeight: 500,
    lineHeight: "9.99px",
    textAlign: "left",
    color: "#DC2626"
  }))
  const YellowBox = styled(Box)(({ theme }) => ({
   width: "74.85px",
    height: "11.85px",
    padding: "1.48px 5.93px 1.48px 5.93px",
    gap: "1.48px",
    borderRadius: "29.63px",
    opacity: "0px",
    backgroundColor: "#FEF3C7"
  }))
  const YellowTypo = styled(Typography)(({ theme }) => ({
   width: "70px",
    height: "14px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "8.89px",
    fontWeight: 500,
    lineHeight: "13.33px",
    textAlign: "left",
    color: "#D97706"
  }))
  const LinearProgressBar = styled(LinearProgress)(({ theme }) => ({
    width: "100%",
    height: "4px",
    padding: "0px 251.39px 0px 0px",
    gap: "0px",
    borderRadius: "10px",
    opacity: "0px",
    [`& .MuiLinearProgress-colorPrimary`]: {
      backgroundColor: theme.palette.secondary.main,
    },
    [`& .MuiLinearProgress-bar`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.action.selected,
    },
  }));
  
  const RedBox = styled(Box)(({ theme }) => ({
     width: "74.85px",
    height: "11.85px",
    padding: "1.48px 5.93px 1.48px 5.93px",
    gap: "1.48px",
    borderRadius: "29.63px",
    opacity: "0px",
    backgroundColor: "#FEE2E2"
  }))
  const RedTypo = styled(Typography)(({ theme }) => ({
    width: "63px",
    height: "14px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "8.89px",
    fontWeight: 500,
    lineHeight: "13.33px",
    textAlign: "left",
    color: "#DC2626"
  }))
  const GreenBox = styled(Box)(({ theme }) => ({
    width: "74.85px",
    height: "11.85px",
    padding: "1.48px 5.93px 1.48px 5.93px",
    gap: "1.48px",
    borderRadius: "29.63px",
    opacity: "0px",
    backgroundColor: "#D1FAE5"
  }))
  const GreenTypo = styled(Typography)(({ theme }) => ({
   width: "63px",
    height: "14px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "8.89px",
    fontWeight: 500,
    lineHeight: "13.33px",
    textAlign: "left",
    color: "#059669"
  }))
  
  
  const CourseNameTypo = styled(Typography)(({ theme }) => ({
   fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "11.85px",
    textAlign: "left",
    width: "293px",
    height: "12px",
    gap: "0px",
    opacity: "0px",
    color: theme.palette.text.secondary
  }))
  
  const NameTypo = styled(Typography)(({ theme }) => ({
   width: "293px",
    height: "18px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left",
    color: theme.palette.text.hint
  }))
  const ProgressTypo = styled(Typography)(({ theme }) => ({
   width: "30px",
    height: "14px",
    gap: "0px",
    opacity: "0px",
    fontFamily: "Rubik",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14.22px",
    textAlign: "left",
    color: theme.palette.text.secondary
  }))
  const CourseTabSection = styled(Box)(({ theme }) => ({
    width: "443px",
    height: "40px",
    padding: "4px",
    borderRadius: "50px",
    justifyContent: "space-between",
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    backgroundColor:theme.palette.background.default
  }))
  const SelectedFilter = styled(Box)(({ theme }) => ({
    width: "98px",
    height: "28px",
    padding: "6px 12px 6px 12px",
    gap: "6px",
    borderRadius: "50px",
    opacity: "0px",
    backgroundColor: "#EDF1F6",
    display:"flex",
    alignItems:"center"
  }))
  const SelectedFilterTypo = styled(Typography)(({ theme }) => ({
    width: "74px",
    height: "22px",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "left",
    color: theme.palette.action.selected
  }))
  const DeselectedFilter = styled(Box)(({ theme }) => ({
    width: "95px",
    height: "28px",
    padding: "6px 12px 6px 12px",
    gap: "6px",
    borderRadius: "50px",
    display:"flex",
    alignItems:"center",
  }))
  const DeselectedFilterTypo = styled(Typography)(({ theme }) => ({
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textAlign: "left",
    width: "71px",
    height: "22px",
    color: theme.palette.action.selected
  }))
  
  export const FilterContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    width:"100%",
    alignItems:"center",
    "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
      borderRadius: "30px",
      height: "43px",
      paddingTop: "5px",
      background: theme.palette.background.default
    },
    "& .MuiInput-underline::before": {
      borderBottom: "transparent",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      borderBottom: "transparent",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "transparent",
    },
    "& .ant-select-arrow": {
      color: theme.palette.info.main
    },
    "& .selectedOption": {
      background: '#1A469C !important',
      color: '#F8FAFC !important'
    },
    "& .selectFilter .ant-select-selector": {
      background: `${theme.palette.background.paper} !important`,
      color: `${theme.palette.info.contrastText} !important`
    }
  }));
  const PaginationBox = styled(Box)(({ theme }) => ({
    width: "366px",
    height: "29px",
    padding: "0px 8px 0px 8px",
    gap: "20px",
    borderRadius: "18px",
    opacity: "0px",
    "& .MuiPaginationItem-page.Mui-selected": {
      background: theme.palette.action.selected,
      color: '#FFF',
      minWidth: '18px',
      height: '18px'
    },
    "& .MuiPaginationItem-root": {
      fontFamily: "Rubik",
      background: theme.palette.action.active,
      fontWeight: 500,
      lineHeight: "18px",
      fontSize: "10px",
      minWidth: '18px',
      height: '18px'
    },
    "& .MuiPagination-ul": {
      background: theme.palette.background.default,
      padding: '5px',
      borderRadius: '40px'
    },
    "& .MuiSvgIcon-root": {
      fill: theme.palette.secondary.main,
      width: '18px'
    },
    "& .MuiCircularProgress-root": {
      transform: 'rotate(120deg) !important'
    }
  }))
  export const EditDescription = styled(Typography)(({ theme }) => ({
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0.4px',
    textAlign: 'left',
    marginTop: '5px',
    color: theme.palette.secondary.main
  }))
  const ExpirationLabel = styled(Typography)(({ theme }) => ({
    background: theme.palette.background.paper,
    color: theme.palette.info.contrastText,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    borderRadius: '26px',
    padding: '10px 12px',
    marginLeft: '5px',
    cursor: 'pointer'
  }))
  const ResetText = styled(Button)(({ theme }) => ({
    fontSize: "16",
    fontWeight: 500,
    textTransform: 'capitalize',
    color: theme.palette.primary.contrastText
  }))
  
  const SearchBox = styled(TextField)({
    minWidth: "230px",
    height: "44px",
    padding: "8px",
    borderRadius: "18px",
    border: `1px solid ${theme.palette.info.dark} `,
    backgroundColor: "#FFF",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
  });
  const RemindText = styled(Button)(({ theme }) => ({
    fontSize: "14",
    fontWeight: 500,
    textTransform: 'capitalize',
    color: theme.palette.info.contrastText
  }))
  
  const FontStyle = {
    fontFamily: 'Rubik',
    h1: { fontSize: '32px' },
    h2: { fontSize: '30px' },
    h3: { fontSize: '28px' },
    h4: { fontSize: '26px' },
    h5: { fontSize: '24px' },
    h6: { fontSize: '22px' },
    subtitle1: { fontSize: '20px' },
    subtitle2: { fontSize: '18px' },
    body1: { fontSize: '16px' },
    body2: { fontSize: '14px' }
  }
  
  export const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#FFFFFF',
            light:"010101"
        },
        secondary: {
            main: '#FFFFFF',
            light:"#D1FAE5",
            dark:"#0F172A"
        },
        background: {
            default: '#FFFFFF',
            paper: 'rgba(238,238,238,1)'
        },
        text: {
            primary: '#334155',
            secondary: '#475569',
            hint: '#0F172A',
        },
        action: {
            active: '#F1F5F9',
            selected: '#1A469C',
            focus: '#059669',
            disabled: '#475569'
        },
        info: {
            main: "#21272A",
            light: '#848484',
            dark: '#CBD5E1',
            contrastText:'#1A469C'
        },
    },
    typography: FontStyle
  });
  export const darkTheme = createTheme({
    palette: {
        primary: {
            main: '#161616',
            light:"#BDCFF4"
        },
        secondary: {
            main: '#2F2F2F',
            light:"#074525",
            dark:"#BDCFF4"
        },
        background: {
            default: '#0D0D0D',
            paper: 'rgba(26,26,26,1)',
        },
        text: {
            primary: '#D5DDF0',
            secondary: '#93A1B5',
            hint: '#D5DDF0'
        },
        action: {
            active: '#70A1FF',
            selected: '#70A1FF',
            focus: '#42EBB5',
            disabled: '#3C3E49'
        },
        info: {
            main: "#D5DDF0",
            light: '#99A7BB',
            dark: '#273444',
            contrastText:'#12326F'
        },
    },
    typography: FontStyle
  });

const webStyle = {
  mainWrapper: {
    fontFamily: "Roboto-Medium",
    alignItems: "center",
    paddingBottom: "30px",
    width: "100%",
    padding: 0,
    margin: 0,
    color: "black",
    backgroundColor: "white",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  selectStyle: {
    color: "#303233",
    text: "#303233",
    backgroundColor: "lightblue",
  },
  modal: {
    backgroundColor: "lightblue",
    alignSelf: "center",
    alignItems: "center",
    width: "75%",
    justifyContent: "space-between",
    margin: "2em",
  },
};
// Customizable Area End
