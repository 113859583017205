import React from "react";
// Customizable Area Start
import {
  ScrollView,
  SafeAreaView,
  StyleSheet,
} from "react-native";
import PostCreationController, { Props } from "./PostCreationController.web";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { All, allBlack, group, groupBlack, lightVerify, darkVerify, addFileIcon, screenPlay, Vector, circleMicrophone, document, albumCollection, nextIcon,chartUser, graphicStyle, iIcon, copyIcon,addChapterIcon,addIcon,smallBin,smallEdit, handbrakeIcon } from "../src/assets";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import { ThemeProvider } from "@material-ui/core/styles";
import { BackButton, NextButton, SwitchDiv, darkTheme, lightTheme } from "../../email-account-registration/src/EmailAccountRegistration.web";
import { Box, Button, Grid, Typography, styled, Input, Menu, FormControlLabel, Switch,Checkbox } from "@material-ui/core";
import { DatePicker, Radio, Select } from "antd";
import { EditHeading, FieldModal, ModalHeading, ModalLine, ModalSubHead } from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import { ToastContainer } from "react-toastify";
import Loader from "../../../components/src/Loader.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { CloseDiv } from "../../bulletinboard/src/BulletinBoard.web";
import moment from "moment";
import { deleteIcon, editIcon } from "../../customisableuserprofiles/src/assets";
import AssessmentTest from "../../assessmenttest/src/AssessmentTest.web";
import { DragIndicator, Edit } from "@material-ui/icons";
const ConfigJSON = require('./config.js')
// Customizable Area End

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  publishModal = () => (
    <FieldModal
      open={this.state.publishModal}
      onCancel={this.closeModals}
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ModalHeading variant="h5">Publish</ModalHeading>
          <ModalLine style={{ width: '150px' }} />
          <ModalSubHead variant="subtitle2">Publish info</ModalSubHead>
          <ModalLine style={{ width: '150px' }} />
        </div>}
      centered
      closable={true}
      footer={null}
      destroyOnClose
      width={769}
      style={{ paddingBottom: '40px' }}
    >
      <Radio.Group
        data-test-id='changePublishType'
        value={this.state.publishType}
        onChange={this.changePublishType}
        style={{ width: "100%" }}
      >
        <div style={{ display: 'flex' }}>
          <ArrowBackIosIcon style={{ marginRight: "200px", marginLeft: '20px' }} onClick={this.closeModals} />
          <ModalHeading variant="h5">where to publish</ModalHeading>
        </div>
        <FormControlLabel
          value='all_companies'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? allBlack : All} />
            <div>
              <RadioOptionHead>All</RadioOptionHead>
              <RadioOptionSubHead>The reminder will be sent to all companies “worker”.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
        <FormControlLabel
          value='all_groups'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? groupBlack : group} />
            <div>
              <RadioOptionHead>All Groups</RadioOptionHead>
              <RadioOptionSubHead>The Notification will be sent to all groups of all worker.</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
        <FormControlLabel
          value='specific_groups'
          control={<Radio />}
          label={<RadioDiv>
            <img src={this.state.darkTheme ? groupBlack : group} />
            <div>
              <RadioOptionHead>Specific Groups</RadioOptionHead>
              <RadioOptionSubHead>The Notification will be sent to specific groups.</RadioOptionSubHead>
              <RadioOptionSubHead>Ibrahim, Ibrahim1 and 3 others</RadioOptionSubHead>
            </div>
          </RadioDiv>}
          labelPlacement="start"
        />
      </Radio.Group>
      <BackButton data-test-id="closeModalButton" onClick={this.closeModals}>Back</BackButton>
      <NextButton
        data-test-id="addCourseButton"
        style={{ width: '65%', marginLeft: '10px', color: '#FFF' }}
        onClick={this.addNewCourse}
        disabled={!this.state.publishType}
      >
        Send
      </NextButton>
    </FieldModal>
  )

  successModal = () => (
    <FieldModal
      data-test-id="successModal"
      open={this.state.newChapterAdded}
      onCancel={this.closeSuccessModal}
      centered
      closable={false}
      footer={null}
      destroyOnClose
      width={696}
      style={{ height: '430px' }}
    >
      <div style={{ display: 'flex', justifyContent: "center", padding: '20px', flexDirection: 'column', alignItems: 'center' }}>
        <img src={this.state.darkTheme ? darkVerify : lightVerify} alt="verificationModal" style={{ height: '176px' }} />
        <EditHeading data-test-id="successfullMessage" style={{ textAlign: 'center', fontSize: "28px", marginTop: "15px" }}>
          Chapter Has Been Created Successfully
        </EditHeading>
      </div>
      <NextButton
        className="publishBg"
        data-test-id="newChapterAddedButton"
        onClick={() => { this.closeSuccessModal(); this.setState({ enableListChapter: true, enableChapter: false }) }}
        fullWidth
        style={{ color: '#F8FAFC' }}
      >
        Done
      </NextButton>
    </FieldModal>
  )

  checkMedia = () => {
    const open = Boolean(this.state.anchorEl);
    if (this.state.imagePreview && this.state.videoPreview && this.state.uploadDoc) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <div style={{ display: "flex", maxHeight: "106px" }}>
          <img style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.imagePreview} />
          <video style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.videoPreview} />
        </div>
        <FileViewDiv >
          <MenuLines />
          <div>
            <FileNameTxt>{this.state.uploadDoc.name}</FileNameTxt>
            <FileSizeTxt>{(this.state.uploadDoc.size / 1024).toFixed(0)}{' KB'}</FileSizeTxt>
          </div>
          <CloseIcon data-test-id="removeFile" style={{ width: '16px' }} onClick={() => this.setState({ uploadDoc: null })} />
        </FileViewDiv>
      </div>
    } else if (this.state.imagePreview && this.state.videoPreview) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <div style={{ display: "flex", maxHeight: "106px" }}>
          <img style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.imagePreview} />
          <video style={{ width: '50%', height: '100%', borderRadius: '20px' }} src={this.state.videoPreview} />
        </div>
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div>
    } else if (this.state.imagePreview) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <img style={{ width: '90%', height: '70%', borderRadius: '20px' }} src={this.state.imagePreview} />
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div>
    } else if (this.state.videoPreview) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <video style={{ width: '90%', height: '70%', borderRadius: '20px' }} src={this.state.videoPreview} />
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div>
    } else if (this.state.uploadDoc) {
      return <div style={{ display: 'flex', justifySelf: 'center', flexDirection: 'column', width: "100%", maxHeight: '148px' }}>
        <FileViewDiv >
          <MenuLines />
          <div>
            <FileNameTxt>{this.state.uploadDoc.name}</FileNameTxt>
            <FileSizeTxt>{(this.state.uploadDoc.size / 1024).toFixed(0)} {" KB"}</FileSizeTxt>
          </div>
          <CloseIcon style={{ width: '16px' }} onClick={() => this.setState({ uploadDoc: null })} />
        </FileViewDiv>
        <div
          data-test-id="uploadMenu"
          aria-controls={open ? 'basic-menu' : undefined}
          id="basic-button"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
        >
          <AddCircleOutlineRoundedIcon style={{ marginRight: '10px', float: 'right' }} />
        </div>
      </div >
    } else {
      return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'end' }}
        data-test-id="uploadMenu"
        aria-controls={open ? 'basic-menu' : undefined}
        id="basic-button"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLDivElement>) => this.setState({ anchorEl: event.currentTarget })}
      >
        <CloudUploadOutlinedIcon style={{ marginTop: '100px' }} />
        <UploadText>Upload media</UploadText>
      </div>
    }
  }

  renderSuggestionBox = () => {
    const { suggestions, showSuggestions, suggestionBoxPosition } = this.state;

    if (!showSuggestions) return null;

    return (
      <div
        style={{
          position: 'absolute',
          top: suggestionBoxPosition.top,
          left: suggestionBoxPosition.left,
          backgroundColor: 'white',
          border: '1px solid #ccc',
          borderRadius: '4px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
          zIndex: 1000
        }}
      >
        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
          {suggestions.map((sugg: any, index: any) => (
            <>
              {sugg?.suggestions.map((suggestion: any, i: any) => (
                <li
                  key={index}
                  style={{
                    padding: '8px 12px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #eee'
                  }}
                  onMouseDown={() => this.handleSuggestionClick(suggestion)}
                >
                  {suggestion}</li>
              ))}
            </>
          ))}
        </ul>
      </div>
    );
  }

  renderStepper = () => (
    <div style={{ width: "100%", display: "flex", padding: "15px" }}>
      {ConfigJSON.PostSteps.map((label: string,index:number) => (
        <TopMenuOption style={{color:(this.state.currentStage===index) ? "#1A469C" :"#475569"}} key={label}>
          {label}
        </TopMenuOption>
      ))}
    </div>
  )
  renderMainButton=()=>{
    return(
      <Grid container>
            <Grid item xs={8}>
              <div>
                {this.renderStepper()}
                <CourseInfoSection>
                  <CourseInfoTitle>Chapter list</CourseInfoTitle>
                  <hr style={{ width: '65%' }} />
                </CourseInfoSection>
              </div>
              <CourseTitleSection style={{ padding: "15px", fontWeight: 400 }}>
              <div style={{width:"30px",height:"50px",display:"flex",alignItems:"center",justifyContent:"center"}}>
              <img src={iIcon} style={{width:"16.95px",height:"16.98px"}}/>
              </div>
              <div>
                <AddTypo>
                  Don’t worry you can easily rearranged the order of your content by clicking the lesson and 
                  drabbing it into your  desired location 
                </AddTypo>
              </div>
              </CourseTitleSection>
            </Grid>
            <Grid item xs={8}>
            <MainButtonBox>
              <CreateChapterButton data-test-id="createChapterButton" onClick={()=>this.openLessonCreation()}>Create Chapter <img src={addIcon} style={{width:"24px",height:"24px"}}/></CreateChapterButton>
            </MainButtonBox>
            </Grid>
       </Grid>
    )
  }
  addCourse = () => (
    <Grid container spacing={2}>
      <LeftSection item xs={7}>
        {this.renderStepper()}
        <CourseInfoSection>
          <CourseInfoTitle>Course Info</CourseInfoTitle>
          <hr style={{ color:"#D7D7D780",width: '386px',height:"1px" }} />
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CourseTitleDescription>Offline Download</CourseTitleDescription>
            <SwitchDiv style={{ marginTop: 0 }}><Switch /></SwitchDiv>
          </div>
        </CourseInfoSection>
        <CourseDetailsSection>
          <SubHeading>Course Title</SubHeading>
          <InputField
            data-test-id="txtCourseTitle"
            value={this.state.CourseInfo['course_name']}
            onChange={(event) => this.changeCourseInfo('course_name', event.target.value)}
          />
          <SubHeading>Course Description</SubHeading>
          <div onDoubleClick={this.handleOpen} onContextMenu={this.handleSelectionChange}>
            {this.state.open && (
              <div style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, background: '#00000050', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 99999999 }}
              >
                <div
                  style={{ width: 715, height: 404, background: 'white', borderRadius: "8px" }}
                >
                  <ReactQuill
                    ref={this.quillRef}
                    data-test-id="txtDescription2"
                    placeholder={"Enter your Description"}
                    modules={
                      {clipboard:{
                      matchVisual:false,
                    }
                  }}
                    value={this.state.CourseInfo['course_description'] && String(this.state.CourseInfo['course_description'])}
                    onChange={(event) => this.changeCourseInfo('course_description', event)}
                    onChangeSelection={this.handleSelectionChange}
                    formats={formats}
                    style={{ height: 347 }}
                    className="modalTextEditor"
                  />
                  {this.renderSuggestionBox()}
                  <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end', paddingBlock: 8, marginInline: 18, borderTop: '1px solid lightgray' }}>
                    <button onClick={this.handleClose} style={{ backgroundColor: "white", border: '1px solid #1A469C', borderRadius: 30, padding: '10px 16px', color: '#1A469C', fontWeight: 700, fontSize: 14, fontFamily: "Rubik" }}>Cancel</button>
                    <button onClick={this.handleClose} style={{ backgroundColor: "#1A469C", borderRadius: 30, padding: '10px 24px', border: 'none', color: 'white', fontWeight: 700, fontSize: 14, fontFamily: "Rubik" }}>Save</button>
                  </div>
                </div>
              </div>
            )}

            <ReactQuill
              ref={this.quillRef}
              data-test-id="txtDescription"
              placeholder={"Enter your Description"}
              value={this.state.CourseInfo['course_description'] && String(this.state.CourseInfo['course_description'])}
              onChange={(event) => this.changeCourseInfo('course_description', event)}
              onChangeSelection={this.handleSelectionChange}      
              formats={formats}
            />
          </div>
          <ImageVideoSection>
            <div style={{ width: "50%", marginRight: '10px' }}>
              <SubHeading>Cover Image</SubHeading>
              {this.state.coverPhotoPreview ?
                <UploadMediaDiv style={{ marginBottom: "10px" }}>
                  <img src={this.state.coverPhotoPreview} style={{ width: "100%", height: "100%", borderRadius: "15px" }} />
                  <CloseDiv
                    data-test-id='removeCoverImage'
                    style={{ left: "40%" }}
                    onClick={this.removeCoverImage}
                  />
                </UploadMediaDiv>
                : <label htmlFor="coverImage" className="profile-upload-btn">
                  <input
                    data-test-id="coverImageBtn"
                    id="coverImage"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={this.uploadCover}
                  />
                  <ImageUploadDiv>
                    <img src={addFileIcon} />
                    <div style={{ alignItems: 'center', display: "flex" }}>
                      <LessonTime style={{ fontSize: "10px" }}>
                        Drag and drop image , or
                      </LessonTime>
                      <AddModuleButton variant="text" style={{ border: "none", fontSize: "12px", width: "auto", margin: 0 }}>
                        Browse file
                      </AddModuleButton>
                    </div>
                  </ImageUploadDiv>
                </label>}
            </div>
            <div style={{ width: "50%", marginRight: '10px' }}>
              <SubHeading>Promotional Video</SubHeading>
              {this.state.promotionalVideoPreview ?
                <UploadMediaDiv style={{ marginBottom: "10px" }}>
                  <video src={this.state.promotionalVideoPreview} style={{ width: "100%", height: "100%", borderRadius: "15px" }} />
                  <CloseDiv
                    data-test-id='removePromotionalVideo'
                    style={{ left: "40%" }}
                    onClick={this.removePromotionalVideo}
                  />
                </UploadMediaDiv>
                : <label htmlFor="promotionalVideo" className="profile-upload-btn">
                  <input
                    data-test-id="promotionalVideoBtn"
                    id="promotionalVideo"
                    type="file"
                    accept="video/*"
                    style={{ display: "none" }}
                    onChange={this.uploadCover}
                  />
                  <ImageUploadDiv>
                    <img src={addFileIcon} />
                    <div style={{ display: "flex", alignItems: 'center' }}>
                      <LessonTime style={{ fontSize: "10px" }}>
                        Drag and drop video , or
                      </LessonTime>
                      <AddModuleButton variant="text" style={{ fontSize: "12px", border: "none", width: "auto", margin: 0 }} >
                        Browse
                      </AddModuleButton>
                    </div>
                  </ImageUploadDiv>
                </label>}
            </div>
          </ImageVideoSection>
        </CourseDetailsSection>
      </LeftSection>

      <RightSection item xs={4}>
        <div style={{ display: 'flex' }}>
          <SubHeading>Category</SubHeading>
          <SubHeading style={{ fontSize: "8px" }}>{"("}Required<span style={{ color: "#DC2626" }}>*</span>{")"}</SubHeading>
        </div>
        <Select
          placeholder="select your category"
          data-test-id="txtCategory"
          onChange={(event) => this.changeCourseInfo('category_attributes', String(event))}
        >
          {this.state.allCategories?.map((item) => (
            <Select.Option value={item.attributes.id}>
              {item.attributes.name}
            </Select.Option>
          ))}
        </Select>
        <SubHeading>Sub-Category</SubHeading>
        <Select
          data-test-id="txtSubCategory"
          placeholder="select your sub-category"
          value={this.state.CourseInfo['sub_category']}
          onChange={(event) => this.changeCourseInfo('sub_category', String(event))}
        >
          {this.state.allSubCategories?.map((item) => (
            <Select.Option value={item.attributes.id}>
              {item.attributes.name}
            </Select.Option>
          ))}
        </Select>
        <SubHeading>Time For Supervisor To Review</SubHeading>
        <InputField
          data-test-id="txtReviewTime"
          value={this.state.CourseInfo['review_time']}
          onChange={(event) => this.changeCourseInfo('review_time', event.target.value)}
        />
        <CourseContentTxt>Course Duration</CourseContentTxt>
        <SubHeading>Start Date</SubHeading>
        <DateField
          data-test-id='start_date'
          placement="bottomRight"
          format="MM-DD-YYYY"
          showToday={false}
          disabledDate={(current)=>
            current && current < moment().startOf("day")
          }
          onChange={(event) => this.changeCourseInfo('start_date', String(event))}
          defaultValue={this.state.CourseInfo['start_date'] ? moment(this.state.CourseInfo['start_date']?.toString()) : undefined}
        />
        <SubHeading>End Date</SubHeading>
        <DateField
          data-test-id='end_date'
          placement="bottomRight"
          format="MM-DD-YYYY"
          showToday={false}
          onChange={(event) => this.changeCourseInfo("end_date", String(event))}
          defaultValue={
            this.state.CourseInfo["end_date"]
              ? moment(this.state.CourseInfo["end_date"].toString())
              : undefined
          }
          disabledDate={(current) =>
            current &&
            current.isBefore(moment(this.state.CourseInfo["start_date"].toString()), "day")
          }
        />
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <TagSubHeading>Tag Name</TagSubHeading>
          <InputField
            data-test-id="txtTags"
            placeholder="Enter tag name"
            style={{ borderRadius: "98px", width: "60%" }}
            value={this.state.CourseInfo['tags']}
            onChange={(event) => this.changeCourseInfo('tags', event.target.value)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <TagSubHeading>Tag Color</TagSubHeading>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div className={this.state.selectedTagColor === "#059669" ? "selected" : ""} style={{ borderRadius: "50%", margin: "5px" }}>
              <TagColor
                data-test-id="selectedGreen"
                style={{ background: "#059669" }}
                onClick={() => this.chooseTagColor("#059669")}
              />
            </div>
            <div className={this.state.selectedTagColor === "#D97706" ? "selected" : ""} style={{ borderRadius: "50%", margin: "5px" }}>
              <TagColor
                data-test-id="selectedOrange"
                style={{ background: "#D97706" }}
                onClick={() => this.chooseTagColor("#D97706")}
              />
            </div>
            <div className={this.state.selectedTagColor === "#B00606" ? "selected" : ""} style={{ borderRadius: "50%", margin: "5px" }}>
              <TagColor
                data-test-id="selectedRed "
                style={{ background: "#B00606" }}
                onClick={() => this.chooseTagColor("#B00606")}
              />
            </div>
          </div>
        </div>
        {
          this.disableCheck() ?         
          <ContinueButton
            data-test-id='btnContinueCourse'
            onClick={this.continueCourse}
            style={{ width: "100%", color: "#FFF", }}
            className="publishBg"
          >
          Continue
          </ContinueButton> :
          <DisableButton disabled={true}>
            continue
          </DisableButton>
        }
      </RightSection>
    </Grid>
  )

  addCurriculum = () => (
    <Grid container spacing={2}>
      {this.state.enableListChapter &&
        <>
          <Grid container>
            <Grid item xs={8}>
              <div>
                {this.renderStepper()}
                <CourseInfoSection>
                  <CourseInfoTitle>Chapter list</CourseInfoTitle>
                  <hr style={{ width: '65%' }} />
                </CourseInfoSection>
              </div>
              <div style={{ display: "flex", padding: "20px" }}>
                <img src={iIcon} />
                <CourseTitleDescription style={{ padding: "15px", fontWeight: 400 }}>
                  Don’t worry you can easily rearranged the order of your content by clicking the lesson and
                  drabbing it into your  desired location
                </CourseTitleDescription>
              </div>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: "flex-end", alignItems: "end" }}>
              <AddChapterButton
                data-test-id="addChapterBtn"
                onClick={this.openLessonCreation}
              >
                <img src={addChapterIcon} style={{width:"24px",height:"24px"}}/>
                Add Chapter
              </AddChapterButton>
            </Grid>
          </Grid>

              {
              this.state.allCourseDetails.course_content_attributes.map((chapter,index)=>(  
                <ChapterSection>
                  <ChapterHeaderBox>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <DragIndicator />
                    <ChapterHeadTypo style={{ margin: 0 }} data-test-id="chapterTitleTest">{chapter.chapter_name}</ChapterHeadTypo>
                  </div>
                  <div style={{ marginRight: "10px" }}>
                    <Button
                      data-test-id="editCertificate"
                      variant="text"
                      style={{ color: '#1A469C', fontSize: '14px', textTransform: 'capitalize' }}
                      startIcon={<Edit style={{ fill: "#1A469C" }} />}
                    // onClick={() => this.EditCertificate(item.attributes)}
                    >
                      Edit
                    </Button>
                    <Button data-test-id="deleteCertificate"
                      variant="text"
                      style={{ color: '#DC2626', fontSize: '14px', textTransform: 'capitalize' }}
                      // onClick={() => this.openDeleteModal(item.attributes.id, "certificate", "")}
                      startIcon={<img src={deleteIcon} style={{ height: '16px' }} />}
                    >
                      Delete
                    </Button>
                  </div>
                </ChapterHeaderBox>
                <div>
                  <ul>
                    { chapter.lessons.length===0 ? <NoLessonTypo data-test-id="noLessonsTest">No lessons yet</NoLessonTypo> : <></> }
                    {chapter.lessons.map((lesson,lesson_index)=>{
                      const isExpanded = this.state.expandedList[`${index}-${lesson_index}`]
                      return (
                      <>
                        <li onClick={()=>this.toggleExpandedList(index,lesson_index)} data-test-id="lessonNameTest">{lesson.lesson_name}</li>
                        {
                          isExpanded && (
                            <>
                              {lesson.sub_lessons.map((subs,sub_index)=>(
                                <>
                                  <li style={{display:"flex", flexDirection:"row",gap:"5px"}}>
                                    <div>
                                      <DragIndicator style={{width:"4.81px",height:"7px"}}/>
                                      <span data-test-id="subLessonNameTest">{subs.title}</span>
                                    </div>
                                    <div>
                                    <img src={smallEdit} style={{width:"10px",height:"10px"}}/>
                                    <img src={smallBin} style={{width:"10px",height:"10px"}}/>
                                    </div>
                                  </li>
                                </>
                              ))}
                            
                            <AddModuleButton
                              data-test-id='addLesson'
                              onClick={()=>this.continueSubLesson(index,lesson_index,lesson.lesson_name)}
                              startIcon={<AddCircleOutlineRoundedIcon />}
                              style={{ width: "auto", height: "32px", margin: 0 }}
                            >
                              Add Lesson
                            </AddModuleButton>
                            </>
                          )
                        }
                      </>
                      )})}
                  </ul>
                </div>
                <div style={{ display: "flex", justifyContent: "end",padding:"29.26px 19.73px" }}>
                  <AddModuleButton
                    data-test-id='addMainLesson'
                    onClick={()=>this.continueLesson(index)}
                    startIcon={<AddCircleOutlineRoundedIcon />}
                    style={{ width: "auto", height: "32px", margin: 0 }}
                  >
                    Add Lesson
                  </AddModuleButton>
                  <div>
                    <img src={copyIcon} />
                    <CopyLessonButton
                      data-test-id="copyLessonBtn"
                      variant="text"
                    >
                      Copy lesson from
                    </CopyLessonButton>
                  </div>
                </div>
                </ChapterSection>
              ))}
        </>}
      {this.state.enableChapter && <>
        <Grid item xs={7}>
          <div>
            {this.renderStepper()}
            <CourseInfoSection>
              <CourseInfoTitle>Add Chapter</CourseInfoTitle>
              <hr style={{ width: '65%' }} />
            </CourseInfoSection>
            <CourseTitleSection style={{ padding: "15px", fontWeight: 400 }}>
              <div style={{width:"30px",height:"50px",display:"flex",alignItems:"center",justifyContent:"center"}}>
              <img src={iIcon} style={{width:"16.95px",height:"16.98px"}}/>
              </div>
              <div>
                <AddTypo>
                  Don’t worry you can easily rearranged the order of your content by clicking the lesson and 
                  drabbing it into your desired location 
                </AddTypo>
              </div>
              </CourseTitleSection>
          </div>
        </Grid>
        <CourseDetailsSection>
          <CourseInfoSection style={{ padding: 0 }}>
            <CourseInfoTitle>New Chapter</CourseInfoTitle>
            <hr style={{ color:"#D7D7D780",width: '386px',height:"2px" }} />
          </CourseInfoSection>
          <SubHeading>Title</SubHeading>
          <InputField
            data-test-id="txtChapterTitle"
            placeholder="Enter chapter title"
            onChange={(event) => this.handleChapterName(event.target.value)}
            value={this.state.newChapterName}
          />
          <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}>
            <AddModuleButton
              className="cancelChapterTitle"
              data-test-id="cancelChapterTitle1"
              onClick={() => this.setState({ enableCurriculumChapter: false, addCurriculum: false, addNewCourse: true, enableChapter: false })}
              style={{ marginTop: "10px", width: "35%" }}
            >
              Cancel
            </AddModuleButton>
            <PublishButton
              className="publishBg"
              data-test-id='addChapterTitle'
              onClick={this.addChapter}
              style={{ marginTop: "10px", width: "35%" }}
            >
              Create
            </PublishButton>
          </div>
        </CourseDetailsSection>
        <NextIconButton data-test-id="nextButtonTest" onClick={()=>{this.nextStage()}}>Next <img src={nextIcon} style={{width:"24px",height:"24px"}}/></NextIconButton>
      </>}

      {this.publishModal()}
      {this.successModal()}
    </Grid>
  )

  lessonSection = () =>
    { const isDisabled=this.state.newLessonName.trim()==="";
      return (
        <>
          <LeftSection item xs={7}>
            {this.renderStepper()}
          </LeftSection>
          <CourseInfoSection>
            <div style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
              <CourseInfoTitle>{this.state.allCourseDetails.course_content_attributes[this.state.currChapterIndex].chapter_name}</CourseInfoTitle>
              <hr style={{ width: '100%', maxWidth: "268px",margin:"0px" }} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <CourseTitleDescription>Offline Download</CourseTitleDescription>
              <SwitchDiv style={{ marginTop: 0 }}><Switch /></SwitchDiv>
            </div>
          </CourseInfoSection>
          <div style={{ width: "100%", padding: "20px" }}>
            <SubHeading>Title</SubHeading>
            <InputField
              data-test-id="txtLessonTitle"
              value={this.state.newLessonName}
              onChange={(event) => this.handleLessonName( event.target.value)}
              style={{ maxWidth: "863px" }}
            />
          </div>
          <CourseDetailsSection style={{ maxWidth: "893px",opacity:isDisabled?"0.5":"1.0",pointerEvents:isDisabled?"none":"auto" }}>
            <div style={{ display: "flex" }}>
              <LessonTypeDiv data-test-id="videoLesson" onClick={() => this.setLessonType("video")}>
                <img src={screenPlay} />
                <LessonTypeText>Video</LessonTypeText>
              </LessonTypeDiv>
              <LessonTypeDiv data-test-id="textLesson" onClick={() => this.setLessonType("text")}>
                <img src={document} />
                <LessonTypeText>Text</LessonTypeText>
              </LessonTypeDiv>
              <LessonTypeDiv data-test-id="pdfLesson" onClick={() => this.setLessonType("pdf")}>
                <img src={screenPlay} />
                <LessonTypeText>Pdf</LessonTypeText>
              </LessonTypeDiv>
              <LessonTypeDiv data-test-id="audioLesson" onClick={() => this.setLessonType("audio")}>
                <img src={circleMicrophone} />
                <LessonTypeText>Audio</LessonTypeText>
              </LessonTypeDiv>
              <LessonTypeDiv data-test-id="quizLesson" onClick={() => this.setLessonType("quiz")}>
                <img src={screenPlay} />
                <LessonTypeText>Quiz</LessonTypeText>
              </LessonTypeDiv>
            </div >
            <div style={{ display: "flex" }}>
              <LessonTypeDiv data-test-id="mediaLesson" onClick={() => this.setLessonType("multimedia")}>
                <img src={albumCollection} />
                <LessonTypeText>Multimedia</LessonTypeText>
              </LessonTypeDiv>
              <LessonTypeDiv data-test-id="presentLesson" onClick={()  => this.setLessonType("presentation")}>
                <img src={chartUser} />
                <LessonTypeText>Presentation</LessonTypeText>
              </LessonTypeDiv>
              <LessonTypeDiv data-test-id="trainingLesson" onClick={() => this.setLessonType("training")}>
                <img src={Vector} />
                <LessonTypeText>Interactive Training</LessonTypeText>
              </LessonTypeDiv>
              <LessonTypeDiv data-test-id="imageLesson" onClick={() => this.setLessonType("image")}>
                <img src={graphicStyle} />
                <LessonTypeText>Image</LessonTypeText>
              </LessonTypeDiv>
            </div>
            <div style={{display:"flex",flexDirection:"row",gap:"5px"}}>
              <CheckedBoxButton/><CheckBoxTypo>Mark this chapter as pre-requisite*</CheckBoxTypo>
            </div>
          </CourseDetailsSection >
        </>
      )
    } 
    

  copyLessonSection = () => (
    <div>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "30%" }}>
          <div style={{ display: "flex" }}>
            <img src={copyIcon} />
            <SubHeading>Copy Lesson From</SubHeading>
          </div>
          <Select style={{ width: "90%" }}></Select>
        </div>
        <div style={{ width: "30%" }}>
          <Select style={{ width: "90%", marginTop: "28px" }}></Select>
        </div>
        <div style={{ width: "30%" }}>
          <SubHeading style={{ fontSize: "14px", fontWeight: 400 }}>Lesson Timer in Minutes and Seconds</SubHeading>
          <InputField
            data-test-id="lessonTime"
            value={this.state.CourseInfo['lesson_time']}
            onChange={(event) => this.changeCourseInfo('lesson_time', event.target.value)}
            style={{ width: "90%" }}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <BackButton
          data-test-id="saveCloseButton"
          onClick={this.saveAndClose}
          style={{ width: '40%' }}
        >
          Save and Close
        </BackButton>
        <NextButton
          data-test-id="saveAddButton"
          style={{ width: '40%', marginLeft: '10px', color: '#FFF' }}
          onClick={this.saveAndClose}
          disabled={!this.state.publishType}
        >
          Save and Add New Lesson Type
        </NextButton>
      </div>
    </div>
  )

  lessonTypeSection = () => (
    <>
      <LeftSection item xs={7}>
        {this.renderStepper()}
      </LeftSection>
      <CourseInfoSection>
        <CourseInfoTitle>{this.state.newLessonName}</CourseInfoTitle>
        <hr style={{ width: '55%' }} />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CourseTitleDescription>Offline Download</CourseTitleDescription>
          <SwitchDiv style={{ marginTop: 0 }}><Switch /></SwitchDiv>
        </div>
      </CourseInfoSection>
      {this.state.lessonType === "video" && <CourseDetailsSection>
        <LessonHeadDiv>Video lesson</LessonHeadDiv>
        <SubHeading>Lesson Title</SubHeading>
        <InputField
          data-test-id="videoLessonTitle"
          value={this.state.newSubLessonName}
          onChange={(event) => this.handleSubLessonName(event.target.value)}
        />
        <SubHeading>Videos From Your Library</SubHeading>
        <FileAddDiv>
          <SubHeading style={{ fontWeight: 400 }}>No file selected</SubHeading>
          <label htmlFor="videoLesson">
            <input
              data-test-id="videoLessonBtn"
              id="videoLesson"
              type="file"
              accept="video/*"
              style={{ display: "none" }}
            // onChange={this.uploadCover}
            />
            <FileBrowseButton>
              Browse file
            </FileBrowseButton>
          </label>
        </FileAddDiv>
        <SubHeading>Upload Video File</SubHeading>
        <FileAddDiv>
          <SubHeading style={{ fontWeight: 400 }}>No file selected</SubHeading>
          <label htmlFor="videoLesson">
            <input
              data-test-id="videoLessonBtn"
              id="videoLesson"
              type="file"
              accept="video/*"
              style={{ display: "none" }}
              onChange={this.uploadCover}
            />
            <FileBrowseButton>
              Browse file
            </FileBrowseButton>
          </label>
        </FileAddDiv>
        <InputField
          data-test-id="optionalText"
          placeholder="Add text (optional)"
          value={this.state.newOptionalText}
          onChange={(event) => this.handleOptionalText(event.target.value)}
          style={{ margin: "10px 0px", border: "none" }}
        />
        <SubHeading style={{ fontWeight: 400 }}>
          Max size GB. we suggest compressing your video using Handbrake<a href="https://handbrake.fr/">{" Handbrake"}<img src={handbrakeIcon} style={{width:"20px",height:"20px"}}/></a>
        </SubHeading>
        {this.copyLessonSection()}
      </CourseDetailsSection>}
      {this.state.lessonType === "text" && <CourseDetailsSection>
        <LessonHeadDiv>Text lesson</LessonHeadDiv>
        <SubHeading>Lesson Title</SubHeading>
        <InputField
          data-test-id="textLessonTitle"
          value={this.state.newSubLessonName}
          onChange={(event) => this.handleSubLessonName(event.target.value)}
        />
        <SubHeading>Content</SubHeading>
        <ReactQuill
          ref={this.quillRef}
          data-test-id="textLessonContent"
          placeholder={"Add your content here"}
          value={this.state.newTextContent}
          onChange={(event) => this.handleNewTextContent(event)}
          onChangeSelection={this.handleSelectionChange}
          formats={formats}
          style={{ height: 340 }}
          className="modalTextEditor"
        />
        {this.copyLessonSection()}
      </CourseDetailsSection>}
      {this.state.lessonType === "pdf" && <CourseDetailsSection>
        <LessonHeadDiv>PDF lesson</LessonHeadDiv>
        <SubHeading>Lesson Title</SubHeading>
        <InputField
          data-test-id="pdfLessonTitle"
          value={this.state.newSubLessonName}
          onChange={(event) => this.handleSubLessonName(event.target.value)}
        />
        <SubHeading>Upload Pdf File</SubHeading>
        <FileAddDiv>
          <SubHeading style={{ fontWeight: 400 }}>No file selected</SubHeading>
          <label htmlFor="pdfLesson">
            <input
              data-test-id="pdfLessonBtn"
              id="pdfLesson"
              type="file"
              accept="application/pdf"
              style={{ display: "none" }}
              onChange={this.uploadCover}
            />
            <FileBrowseButton>
              Browse file
            </FileBrowseButton>
          </label>
        </FileAddDiv>
        <SubHeading style={{ fontWeight: 400 }}>
          You can upload files with the extension :pdf
        </SubHeading>
        {this.copyLessonSection()}
      </CourseDetailsSection>}
      {this.state.lessonType === "audio" && <CourseDetailsSection>
        <LessonHeadDiv>Audio lesson</LessonHeadDiv>
        <SubHeading>Lesson Title</SubHeading>
        <InputField
          data-test-id="audioLessonTitle"
          value={this.state.newSubLessonName}
          onChange={(event) => this.handleSubLessonName(event.target.value)}
        />
        <SubHeading>Audio From Your Library</SubHeading>
        <FileAddDiv>
          <SubHeading style={{ fontWeight: 400 }}>No file selected</SubHeading>
          <label htmlFor="audioLesson">
            <input
              data-test-id="audioLessonBtn"
              id="audioLesson"
              type="file"
              accept="audio/*"
              style={{ display: "none" }}
            // onChange={this.uploadCover}
            />
            <FileBrowseButton>
              Browse file
            </FileBrowseButton>
          </label>
        </FileAddDiv>
        <SubHeading>Upload Audio File</SubHeading>
        <FileAddDiv>
          <SubHeading style={{ fontWeight: 400 }}>No file selected</SubHeading>
          <label htmlFor="audioLesson">
            <input
              data-test-id="audioLessonBtn"
              id="audioLesson"
              type="file"
              accept="audio/*"
              style={{ display: "none" }}
              onChange={this.uploadCover}
            />
            <FileBrowseButton>
              Browse file
            </FileBrowseButton>
          </label>
        </FileAddDiv>
        <InputField
          data-test-id="optionalAudioText"
          placeholder="Add text (optional)"
          value={this.state.CourseInfo['audioOptionalText']}
          onChange={(event) => this.changeCourseInfo('audioOptionalText', event.target.value)}
          style={{ margin: "5px 0px", border: "none" }}
        />
        {this.copyLessonSection()}
      </CourseDetailsSection>}
      {this.state.lessonType === "multimedia" && <CourseDetailsSection>
        <LessonHeadDiv>Multimedia lesson</LessonHeadDiv>
        <SubHeading>Lesson Title</SubHeading>
        <InputField
          data-test-id="multimediaLessonTitle"
          value={this.state.newSubLessonName}
          onChange={(event) => this.handleSubLessonName(event.target.value)}
        />
        <SubHeading>Content</SubHeading>
        <SubHeading style={{ fontWeight: 400 }}>Use externally hosted content </SubHeading>
        <SubHeading>{"URL (to your webinar tool  link)"}</SubHeading>
        <InputField
          data-test-id="multimediaURLText"
          placeholder="ex:"
          value={this.state.CourseInfo['multimediaURL']}
          onChange={(event) => this.changeCourseInfo('multimediaURL', event.target.value)}
          style={{ margin: "5px 0px", border: "none" }}
        />
        <SubHeading style={{ fontWeight: 400 }}>Dynamic variables can be added to your URL to automatically populate relevant fields in your content. </SubHeading>
        <SubHeading style={{ fontWeight: 400 }}><a>Learn more about how they can be used in your Multimedia Lesson</a></SubHeading>
        <SubHeading style={{ fontWeight: 400 }}>{"Available Variables (first name)), ((email), lastname)), ((date_created))"}</SubHeading>
        {this.copyLessonSection()}
      </CourseDetailsSection>}
      {this.state.lessonType === "presentation" && <CourseDetailsSection>
        <LessonHeadDiv>Presentation lesson</LessonHeadDiv>
        <SubHeading>Lesson Title</SubHeading>
        <InputField
          data-test-id="presentationLessonTitle"
          value={this.state.newSubLessonName}
          onChange={(event) => this.handleSubLessonName(event.target.value)}
        />
        <SubHeading>Upload PPT or PPTX file</SubHeading>
        <FileAddDiv>
          <SubHeading style={{ fontWeight: 400 }}>No file selected</SubHeading>
          <label htmlFor="pptLesson">
            <input
              data-test-id="pptLessonBtn"
              id="pptLesson"
              type="file"
              accept="application/ppt"
              style={{ display: "none" }}
              onChange={this.uploadCover}
            />
            <FileBrowseButton>
              Browse file
            </FileBrowseButton>
          </label>
        </FileAddDiv>
        <SubHeading style={{ fontWeight: 400 }}>
          You can upload files with the extension :PPT or PPTX
        </SubHeading>
        <SubHeading style={{ fontWeight: 400 }}>
          For increased student engagement, we recommend keeping your presentation under 20 slides.
        </SubHeading>
        {this.copyLessonSection()}
      </CourseDetailsSection>}
      {this.state.lessonType === "training" && <CourseDetailsSection>
        <LessonHeadDiv>Interactive Training Lesson</LessonHeadDiv>
        <SubHeading>Lesson Title</SubHeading>
        <InputField
          data-test-id="trainingLessonTitle"
          value={this.state.newSubLessonName}
          onChange={(event) => this.handleSubLessonName(event.target.value)}
        />
        <SubHeading>Upload file</SubHeading>
        <FileAddDiv>
          <SubHeading style={{ fontWeight: 400 }}>No file selected</SubHeading>
          <label htmlFor="trainingLesson">
            <input
              data-test-id="trainingLessonBtn"
              id="trainingLesson"
              type="file"
              accept="*"
              style={{ display: "none" }}
              onChange={this.uploadCover}
            />
            <FileBrowseButton>
              Browse file
            </FileBrowseButton>
          </label>
        </FileAddDiv>
        {this.copyLessonSection()}
      </CourseDetailsSection>}
      {this.state.lessonType === "image" && <CourseDetailsSection>
        <LessonHeadDiv>Image Lesson</LessonHeadDiv>
        <SubHeading>Lesson Title</SubHeading>
        <InputField
          data-test-id="imageLessonTitle"
          value={this.state.newSubLessonName}
          onChange={(event) => this.handleSubLessonName(event.target.value)}
        />
        <SubHeading>Upload Image</SubHeading>
        <label htmlFor="imageLesson">
          <input
            data-test-id="imageLessonBtn"
            id="imageLesson"
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={this.uploadCover}
          />
          <ImageUploadDiv style={{ height: "120px", width: "100%" }}>
            <img src={graphicStyle} />
            <div style={{ alignItems: 'center', display: "flex" }}>
              <LessonTime style={{ fontSize: "10px" }}>
                Drag and drop image , or
              </LessonTime>
              <AddModuleButton variant="text" style={{ border: "none", fontSize: "12px", width: "auto", margin: 0 }} >
                Browse file
              </AddModuleButton>
            </div>
          </ImageUploadDiv>
        </label>
        {this.copyLessonSection()}
      </CourseDetailsSection>}
    </>
  )
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <SafeAreaView style={styles.safeAreaView}>
        <ScrollView>
          {/* Customizable Area Start */}
          <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
            <Loader loading={this.state.loading} />
            <HamburgerMenu navigation={this.props.navigation} handleMenu={this.leftMenuHandle} open={this.state.openLeftMenu} handleTheme={this.handleTheme} themeDark={this.state.darkTheme} />
            <NavigationMenu id="" navigation={this.props.navigation} open={this.state.openLeftMenu} theme={this.state.darkTheme} />
            <MainContainer container spacing={4} style={{ paddingLeft: this.state.openLeftMenu ? 273 : 150 }}>
              {this.state.addNewCourse && this.addCourse()}
              {this.state.showMainButton && this.renderMainButton()}
              {this.state.enableCurriculumChapter && this.addCurriculum()}
              {this.state.enableLessonSection && this.lessonSection()}
              {this.state.enableLessonType && this.lessonTypeSection()}
              {this.state.enableLessonType && <AssessmentTest navigation={undefined} id={""}/>}
              <ToastContainer containerId='A' />
            </MainContainer>
          </ThemeProvider >
          {/* Customizable Area End */}
        </ScrollView>
      </SafeAreaView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    alignSelf: "center",
    width: "100%"
  }
})
const CheckedBoxButton = styled(Checkbox)(({ theme }) => ({
  width: "20px",
   height: "20px",
   gap: "0px",
   borderRadius: "6px",
   opacity: "0px",
   border: "1px solid #475569",
 "& .MuiButton-label":{
  width: "288px",
  height: "28px",
  fontFamily: "Rubik",
  fontSize: "17.58px",
  fontWeight: 400,
  lineHeight: "27.63px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#475569"
 },
 "&.Mui-checked":{
  backgroundColor:"#1A469C",
 }
 }))

const CheckBoxTypo = styled(Typography)(({ theme }) => ({
  width: "288px",
    height: "28px",
    fontFamily: "Rubik",
    fontSize: "17.58px",
    fontWeight: 400,
    lineHeight: "27.63px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#475569"
  }))
  
const CreateChapterButton = styled(Button)(({ theme }) => ({
  width: "294px", 
  height: "45px", 
  gap: "11px",
  padding: "10px 16px 10px 16px",
  borderRadius: "30px",
  border: "1px solid #1A469C",
  "& .MuiButton-label":{
    width: "168px",
    height: "22px",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#1A469C"
  }
}))
const ContinueButton = styled(Button)(({ theme }) => ({
  height: "41.56px",
  padding: "9.45px 15.11px 9.45px 15.11px",
  gap: "7.56px",
  borderRadius: "28.34px",
  backgroundColor: "#1A469C",
  textTransform:"capitalize",
  "& .MuiButton-label":{
    width: "68px",
    height: "23px",
    fontFamily: "Rubik",
    fontSize: "15.11px",
    fontWeight: 700,
    lineHeight: "22.67px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#F8FAFC"
  }
}))
const DisableButton = styled(Button)(({ theme }) => ({
  height: "41.56px",
  padding: "9.45px 15.11px 9.45px 15.11px",
  gap: "7.56px",
  borderRadius: "28.34px",
  backgroundColor: "#F1F5F9",
  textTransform:"lowercase",
  "& .MuiButton-label":{
    width: "68px",
    height: "23px",
    fontFamily: "Rubik",
    fontSize: "15.11px",
    fontWeight: 700,
    lineHeight: "22.67px",
    textAlign: "left",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#43536A"
  }
}))
const NextIconButton = styled(Button)(({ theme }) => ({
  width: "294px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  backgroundColor: "#1A469C",
  "& .MuiButton-label":{
     width: "38px",
  height: "24px",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#F8FAFC"
  },
  "&:hover":{
    backgroundColor: "#1A469C",
    color: "#F8FAFC",  
  }
}))

const AddChapterButton = styled(Button)(({ theme }) => ({
  width: "147px",
  height: "32px",
  padding: "6px 10px 6px 10px",
  gap: "6px",
  borderRadius: "30px",
  opacity: "0px",
  backgroundColor: "#1A469C",
  "& .MuiButton-label":{
     width: "120px",
     height: "22px",
     fontFamily: "Rubik",
     fontSize: "14px",
     fontWeight: 700,
      lineHeight: "22px",
      textAlign: "left",
      textUnderlinePosition: "from-font",
      textDecorationSkipInk: "none",
      color: "#F8FAFC",
      textTransform:"capitalize"
  },
  "&:hover":{
    backgroundColor: "#1A469C",
    color: "#F8FAFC",  
  }
}))

const MainContainer = styled(Grid)(({ theme }) => ({
  paddingTop: '10px',
  height: '100%',
  background: theme.palette.background.default,
  paddingRight: 48,
  paddingBottom: 50,
  transition: 'all 100ms linear',
  '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
    borderBottom: 'transparent'
  },
  '& .MuiInput-underline::after': {
    borderBottom: 'transparent'
  },
  "& .MuiInput-underline::before": {
    borderBottom: 'transparent'
  },
  '& .MuiSvgIcon-root': {
    fill: '#94A3B8'
  },
  "& .ant-select": {
    width: "auto",
    height: '38px',
    fontSize: '14px',
    fontFamily: 'Rubik',
    lineHeight: '18px',
    fontWeight: 400,
    color: theme.palette.secondary.main,
  },
  "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
    minWidth: '91px',
    borderRadius: "8px",
    padding: '4px 12px',
    height: '38px',
    borderColor: theme.palette.info.dark,
    background: theme.palette.background.default
  },
  "& .ant-select-dropdown": {
    fontFamily: 'Rubik',
    width: 'auto !important',
    fontSize: '12px',
    fontWeight: 400,
    background: theme.palette.action.focus,
    minWidth: "200px",
  },
  "& .ant-select-arrow": {
    color: theme.palette.info.main
  },
  "& .ql-container": {
    borderRadius: '8px',
    height: "174px",
    border: 'none'
  },
  '& .quill': {
    position: 'relative',
    borderRadius: '8px',
    paddingBottom: 55,
    border: `1px solid ${theme.palette.info.dark}`,
    borderTop: `1px solid ${theme.palette.info.dark} !important`
  },
  "& .ql-toolbar": {
    borderRadius: '8px',
    border: `none`,
    position: "absolute",
    bottom: "10px",
    left: 10,
    width: "fit-content",
    transform: "translateY",
    zIndex: 1000,
    '& .ql-formats': {
      marginRight: 0
    },
    '& .ql-formats:first-child': {
      display: 'none'
    },
    '& .ql-formats button': {
      border: '1px solid #94A3B8',
      marginRight: 2
    },
  },
  "& .ql-editor": {
    background: theme.palette.background.default,
    color: theme.palette.secondary.main,
    borderRadius: 8,
    fontSize: '14px',
    fontFamily: 'Rubik'
  },
  "& .ql-editor.ql-blank::before": {
    color: theme.palette.info.light,
    fontStyle: 'normal',
    fontFamily: 'Rubik',
    fontSize: '14px'
  },
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.primary.main,
  },
}))

const LeftSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  marginRight: '10px',
  flexDirection: 'column'
}))

const ChapterSection = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  minHeight: "109px",
  border: `1px solid ${theme.palette.info.dark}`,
  borderRadius: "8px",
  margin: "10px"
}))

const RightSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  borderRadius: '18px',
  background: theme.palette.action.focus,
  flexDirection: 'column',
  "& .publishBg:hover": {
    background: theme.palette.info.contrastText
  },
  "& .selected": {
    border: `1.5px solid ${theme.palette.info.contrastText}`
  }
}))

const CourseInfoSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  height: '38px',
  padding: "15px"
})

const CourseTitleSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  height: '50px',
  justifyContent:"flex-start"
})

const CourseTitleDescription = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '21px',
  textAlign: 'left',
  color: theme.palette.info.main,
}))

const AddTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "21px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#334155",
  width: "550px",
  height: "50px"
}))
const CourseInfoTitle = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  lineHeight: '37px',
  fontWeight: 500,
  color: theme.palette.secondary.main
}))

const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "22px",
  fontWeight: 700,
  color: theme.palette.text.primary,
  marginTop: '5px'
}))

const TagSubHeading = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "22px",
  fontWeight: 400,
  color: theme.palette.info.light,
  marginRight: "10px"
}))

const NoLessonTypo = styled(Typography)(({ theme }) => ({
  width: "665px",
  height: "21px",
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "21px",
  textAlign: "left",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#334155"
}))

const TagColor = styled(Box)(({ theme }) => ({
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  margin: "3px",
  padding: "10px"
}))

const MainButtonBox = styled(Box)(({ theme }) => ({
  width: "1030px",
  height: "376px",
  padding: "38px 25px 22px 25px",
  gap: "10px",
  borderRadius: "18px",
  opacity: "0px", 
  display:"flex",
  alignItems:"center",
  justifyContent:"center"
}))

const UploadMediaDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '190px',
  borderRadius: '22px',
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: '0px 8px 32px 0px #0000000F',
  flexDirection: 'column'
}))

const FileAddDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: "center",
  height: '93px',
  width: "100%",
  padding: '10px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.info.dark}`,
  marginTop: '10px'
}))

const AddModuleButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '45px',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.info.contrastText}`,
  marginTop: '32px',
  textTransform: 'capitalize',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  color: theme.palette.info.contrastText,
  '& .MuiSvgIcon-root': {
    fill: theme.palette.info.contrastText
  }
}))

const CopyLessonButton = styled(Button)(({ theme }) => ({
  height: '32px',
  textTransform: 'capitalize',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '22px',
  color: theme.palette.text.primary,
  '& .MuiSvgIcon-root': {
    fill: theme.palette.text.primary
  }
}))

const PublishButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '44px',
  borderRadius: '30px',
  marginTop: '10px',
  textTransform: 'capitalize',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  background: theme.palette.info.contrastText,
  color: '#FFF',
  "&:hover": {
    background: theme.palette.info.contrastText
  },
}))

const InputField = styled(Input)(({ theme }) => ({
  width: '100%',
  height: '42px',
  gap: '8px',
  borderRadius: '8px',
  padding: '10px 12px',
  border: `1px solid ${theme.palette.info.dark}`,
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  textAlign: 'left',
  color: theme.palette.secondary.main,
  marginTop: '5px',
  background: theme.palette.background.default,
}))

const LessonTypeDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.action.active,
  width: "157px",
  height: "135px",
  margin: "8px",
  borderRadius: "11px"
}))
const ChapterHeaderBox = styled(Box)(({ theme }) => ({
  height: "42px",
  padding: "8px 4px 8px 4px",
  borderRadius: "7px",
  justify: "space-between",
  backgroundColor: "#F9F9F9",
  display:"flex",
  flexDirection:"row",
  justifyContent:"space-between",
  alignItems:"center"
}))

const CourseDetailsSection = styled(Box)(({ theme }) => ({
  overflowY: 'scroll',
  marginTop: '30px',
  width: "100%",
  height:"376px",
  padding: "38px 25px 22px 25px",
  gap: "10px",
  borderRadius: "18px",
  backgroundColor: "#F9F9F9",
  '& .modalTextEditor .ql-container.ql-snow': {
    height: '100%',
    border: 'none'
  }
}))

const ImageVideoSection = styled(Box)({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  maxHeight: "271px"
})

const ImageUploadDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  borderRadius: "22px",
  height: "190",
  border: `1px dashed ${theme.palette.secondary.contrastText}`
}))

const UploadText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '26px',
  color: theme.palette.text.secondary
}))

const CourseContentTxt = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: "26px",
  marginTop: '5px',
  color: theme.palette.info.main
}))

const ChapterHeadTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "26px",
  textAlign: "center",
  textUnderlinePosition: "from-font",
  textDecorationSkipInk: "none",
  color: "#0F172A"
}))

const LessonTypeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontFamily: 'Rubik',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '30px',
}))

export const RadioDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row'
}))

export const RadioOptionHead = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.41px',
  color: theme.palette.info.main,
  marginLeft: '10px'
}))

export const RadioOptionSubHead = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  color: theme.palette.info.light,
  marginLeft: '10px'
}))

const ModuleDiv = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  borderRadius: '18px',
  padding: '12px',
  display: 'flex',
  flexDirection: 'column'
}))

const AddContentButton = styled(Button)(({ theme }) => ({
  width: '145px',
  height: '44px',
  borderRadius: '30px',
  padding: '10px 16px',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  color: '#FFF',
  marginTop: '20px',
  background: theme.palette.action.selected,
  textTransform: 'capitalize',
  alignSelf: 'center'
}))

const LessonHeadDiv = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: "center",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "23px",
  color: theme.palette.info.main
}))

const FileBrowseButton = styled('div')(({ theme }) => ({
  background: theme.palette.action.selected,
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  width: "208px",
  height: "44px",
  borderRadius: "30px",
  color: "#FFF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}))

const LessonTime = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '26px',
}))

export const FileNameTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '20px',
}))

export const FileSizeTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.light,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
}))

export const FileViewDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'row',
  padding: '8px',
  justifyContent: 'space-around',
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '8px',
  alignItems: 'center'
}))

const UploadMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.background.default,
    boxShadow: `rgba(0, 0, 0, 0.15) 0px 2px 4px 0px`,
    borderRadius: '18px',
    border: `1px solid ${theme.palette.action.focus}`,
    width: '200px',
  },
  '& .MuiListItem-button:hover': {
    backgroundColor: theme.palette.warning.main
  }
}))

export const MenuLines = styled(MenuIcon)(({ theme }) => ({
  height: '42px',
  width: '42px',
  background: theme.palette.background.paper,
  padding: '12px',
  borderRadius: "6px"
}))

const DateField = styled(DatePicker)(({ theme }) => ({
  background: theme.palette.background.default,
  width: '100%',
  padding: '10px 12px',
  borderRadius: '8px',
  height: '34.95px',
  border: `0.94px solid ${theme.palette.info.dark}`,
  margin: '5px auto',
  '& .ant-picker-input > input': {
    color: theme.palette.info.light,
    fontFamily: 'Rubik',
    fontSize: '13.22px',
    fontWeight: 700,
    lineHeight: '21px',
    textAlign: 'left',
  },
  '& .ant-picker-suffix': {
    color: theme.palette.info.light
  },
  '& .ant-picker-input:hover .ant-picker-clear': {
    opacity: 1,
    display: 'none !important'
  },
  '& .ant-picker-focused': {
    boxShadow: 'none'
  }
}))

const TopMenuOption = styled('span')(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '18px',
  textAlign: 'center',
  marginBottom: "10px",
  padding: "10px",
  borderBottom: `2px solid ${theme.palette.info.contrastText}`,
  color: theme.palette.info.light,
  width: "16.67%"
}))

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color"
];
// Customizable Area End
