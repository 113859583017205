Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "bx_block_dashboard/dashboard/index";
exports.profileGetURL = "bx_block_profile/profiles/show_profile";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboarPutApiMethodType = "PUT";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.updateCourseAPIEndPoint = "bx_block_profile/courses/";
exports.getBulletinAPIEndPoint = "/bx_block_dashboard/dashboard/fetch_posts_for_instructor";
exports.getUserMediaAPIEndPoint= "/bx_block_dashboard/dashboard/fetch_media_attributes";
// Customizable Area End