import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Moment } from "moment";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  dateOfBirth: Moment | null;
  darkMode: boolean;
  enableEmailSection: boolean;
  enableDetailSection: boolean;
  enablePasswordSection: boolean;
  enableSuccessSection: boolean;
  role: string;
  checkTerms: boolean;
  showPassword: boolean;
  showLoginOtp: boolean;
  ShowConfirmPassword: boolean;
  containCharLength: boolean;
  containSpecial: boolean;
  containNumber: boolean;
  containLower: boolean;
  containUpper: boolean;
  showTermsSelect: boolean;
  loading: boolean;
  userName: string;
  termsModal: boolean;
  loginOtp: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  getUserRoleApiCallId: string = "";
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      dateOfBirth: null,
      darkMode: false,
      enableEmailSection: true,
      enableDetailSection: false,
      enablePasswordSection: false,
      enableSuccessSection: false,
      role: 'Instructor',
      checkTerms: false,
      showPassword: false,
      showLoginOtp: false,
      ShowConfirmPassword: false,
      containCharLength: false,
      containLower: false,
      containNumber: false,
      containSpecial: false,
      containUpper: false,
      showTermsSelect: false,
      loading: false,
      userName: "",
      termsModal: false,
      loginOtp: ""
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.createAccountResponse(message);
        this.userRoleResponse(message);
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  createAccountResponse(message: Message) {
    const apiRequestCallId1 = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    let responseJson1 = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId1 === this.createAccountApiCallId) {
      this.setState({ loading: false })
      if (responseJson1.hasOwnProperty("email")) {
        this.setState({
          userName: responseJson1.first_name,
          enableSuccessSection: true,
          enableDetailSection: false,
          enableEmailSection: false,
          enablePasswordSection: false,
        })
      } else if (responseJson1.hasOwnProperty("base")) {
        toast.error(responseJson1.base[0])
      } else if (responseJson1.full_phone_number[0] === "has already been taken") {
        toast.error("Phone Number already registered")
        this.setState({ checkTerms: false, password: "", reTypePassword: "" })
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson1);
      }
    }
  }

  userRoleResponse(message: Message) {
    const apiRequestCallId2 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId2 === this.getUserRoleApiCallId) {
      this.setState({ loading: false })
      if (responseJson2.hasOwnProperty("account")) {
        this.setState({
          role: responseJson2.account.role_id,
          phone: responseJson2.account.full_phone_number,
          dateOfBirth: responseJson2.account.date_of_birth,
          userName: responseJson2.account.preferred_name,
          enableDetailSection: true,
          enableEmailSection: false,
          enablePasswordSection: false,
          enableSuccessSection: false
        })
      } else if (responseJson2.hasOwnProperty("message")) {
        toast.error(responseJson2.message);
      } else {
        toast.error(responseJson2.errors[0].account);
      }
    }
  }

  async componentDidMount() {
    const mode = await getStorageData("darkMode");
    if (mode === 'true') {
      this.setState({ darkMode: true })
    }
    this.setState({ email: window.location.href.split("/")[4] })
  }
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount() {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      "email": this.state.email,
      "password": this.state.password,
      "preferred_name": this.state.firstName,
      "full_phone_number": this.state.phone,
      "date_of_birth": this.state.dateOfBirth,
      "accepted_terms": this.state.checkTerms
    };

    const body = {
      "account": attrs
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  toggleMode = async (check: React.ChangeEvent<HTMLInputElement>) => {
    setStorageData("darkMode", `${check.target.checked}`)
    this.setState({ darkMode: check.target.checked })
  }

  changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value })
  }

  changeLoginOtp = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ loginOtp: event.target.value })
  }

  changeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstName: event.target.value })
  }

  changePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ phone: event.target.value })
  }

  changeDOB = (value: Moment | null) => {
    this.setState({ dateOfBirth: value })
  }

  changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target.value
    if (/\d/.test(value)) {
      this.setState({ containNumber: true })
    } else {
      this.setState({ containNumber: false })
    }
    if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
      this.setState({ containSpecial: true })
    } else {
      this.setState({ containSpecial: false })
    }
    if (/\p{Lu}/u.test(value)) {
      this.setState({ containUpper: true })
    } else {
      this.setState({ containUpper: false })
    }
    if (value !== value.toUpperCase()) {
      this.setState({ containLower: true })
    } else {
      this.setState({ containLower: false })
    }
    if (value.length >= 8) {
      this.setState({ containCharLength: true })
    } else {
      this.setState({ containCharLength: false })
    }
    this.setState({ password: value })
  }

  changeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ reTypePassword: event.target.value })
  }

  changeTerms = (value: boolean) => {
    this.setState({ checkTerms: value, showTermsSelect: false })
  }

  acceptNumber = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
  }

  acceptAlphabet = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!/^[a-zA-Z ]*$/.test(event.key)) {
      event.preventDefault();
    }
  }

  gotoEmailSection = () => {
    this.setState({
      enableDetailSection: false,
      enableEmailSection: true,
      enablePasswordSection: false,
      enableSuccessSection: false
    })
  }

  gotoDetailSection = () => {
    if (this.state.email && this.state.loginOtp) {
      this.setState({ loading: true })
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail
      };

      let body = {
        "email": this.state.email,
        "default_password": this.state.loginOtp
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getUserRoleApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getRoleAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    else {
      this.showAlert(configJSON.errorTitle,
        "Please enter received email and password")
    }
  }

  gotoPasswordSection = () => {
    if (this.state.phone && this.state.phone.length < 10) {
      toast.error("Phone number cannot be less than 10 digits");
    }
    else {
      this.setState({
        enableDetailSection: false,
        enableEmailSection: false,
        enablePasswordSection: true,
        enableSuccessSection: false
      })
    }
  }

  gotoFinalStep = () => {
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&#-]{8,}$/.test(this.state.password)) {
      toast.error(configJSON.errorPasswordNotValid);
      return false;
    }

    else if (this.state.password !== this.state.reTypePassword) {
      toast.error(configJSON.errorBothPasswordsNotSame);
      return;
    }
    else if (!this.state.checkTerms) {
      this.setState({ showTermsSelect: true })
      return;
    }
    else {
      this.createAccount()
    }
  }
  gotoLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  openTermsModal = () => {
    this.setState({ termsModal: true })
  }

  closeTermsModal = () => {
    this.setState({ termsModal: false })
  }

  closeTnCModal = () => {
    this.setState({ termsModal: false })
  }

  disableFutureDate = (current: Moment) => {
    return current && current.valueOf() > Date.now()
  }

  getRole = (role: string) => {
    if (role === "worker") {
      return "Worker"
    } else if (role === "instructor") {
      return "Instructor"
    } else {
      return "Supervisor"
    }
  }
  // Customizable Area End
}
