// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const mailIcon = require("../assets/mailIcon.svg");
export const bellIcon = require("../assets/bellIcon.svg");
export const profileImage = require("../assets/profilePic.svg");
export const addIcon = require("../assets/addIcon.png");
export const attachIcon = require("../assets/attachIcon.png");
export const attachIconDark = require("../assets/attachIconDark.png");
export const atIcon = require("../assets/atIcon.png");
export const atIconDark = require("../assets/atIconDark.png");
export const cloudIcon = require("../assets/cloudIcon.png");
export const cloudIconDark = require("../assets/cloudIconDark.png");
export const videoIcon = require("../assets/videoIcon.png");
export const videoIconDark = require("../assets/videoIconDark.png");
export const closeIcon = require("../assets/closeIcon.png");
export const greenDot = require("../assets/greenDotIcon.png");
export const orangeDot = require("../assets/orangeDotIcon.png");
export const viewAll = require("../assets/viewAllIcon.png");
export const addSqureIcon = require("../assets/addSquareIcon.png");
export const moreIcon = require("../assets/moreIcon.png");
export const trashIcon = require("../assets/trashIcon.png");
export const editIcon = require("../assets/pencilIcon.png");
export const minimiseIcon = require("../assets/minimiseIcon.png");
export const playIcon = require("../assets/playIcon.png");
export const courseVideo = require("../assets/courseVideo.png");
export const downloadIcon = require("../assets/downloadIcon.png");
export const playButton = require("../assets/playButton.png");
export const downloaded = require("../assets/downloaded.png");
export const instructorImage = require("../assets/instructorImage.png");
export const submittedIcon = require("../assets/submittedIcon.png");
export const deleteWarningIcon = require("../assets/delete_warning.png");
export const reviewAddIcon = require("../assets/reviewAddIcon.png");
export const starRatingIcons = require("../assets/starRatingIcons.png");
// Customizable Area End
