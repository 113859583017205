import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { FieldValue } from "../../customisableuserprofiles/src/CustomisableUserProfilesController";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  passwordModal: boolean;
  newPassword: string;
  confirmNewPassword: string;
  userProfile: { [key: string]: FieldValue };
  loading: boolean;
  charLength: boolean;
  numberChar: boolean;
  lowerChar: boolean;
  specialChar: boolean;
  upperChar: boolean;
  phoneVerificationModal: boolean;
  otpToken: string;
  otpInput: string;
  verifyOtpErrorMsg: string;
  verifiedModal: boolean;
  passwordSuccessModal: boolean;
  passwordFieldModal: boolean;
  phoneFieldModal: boolean;
  updatePhoneSuccessModal: boolean;
  phoneNumber: string;
  passwordErrorMsg: string;
  phoneErrorMsg: string;
  enableNewPasswordField: boolean;
  enableConfirmPasswordField: boolean;
  enableSecurity: boolean;
  enableMessage: boolean;
  enableAppearance: boolean;
  messageCategoriesList: { data: { id: string, attributes: { [key: string]: FieldValue } } }[];
  urlLocation: string | null;
  messageHeading: { id: string, attributes: { [key: string]: FieldValue } };
  selectedAppearance: string;
  selectedFontSize: string;
  selectedTheme: boolean;
  sidOpen: boolean;
  ModalOpen: boolean;
  selectedColor: string;
  fontSizeModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileCallId: string = "";
  updatePasswordApiCallId: string = "";
  sendVerificationCodeApiCallId: string = "";
  verifyOtpApiCallId: string = "";
  updatePhoneNumberApiCallId: string = "";
  getMessageListApiCallId: string = "";
  updateCategoryApiCallId: string = "";
  messageCategoriesApiCallId: string = "";
  allMessageCategoriesApiCallId: string = "";
  updateAppearanceApiCallId: string = "";
  verifyPasswordApiCallId: string = "";
  customHeaderAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      passwordModal: false,
      newPassword: "",
      confirmNewPassword: "",
      userProfile: {},
      loading: false,
      charLength: false,
      upperChar: false,
      lowerChar: false,
      specialChar: false,
      numberChar: false,
      phoneVerificationModal: false,
      otpToken: "",
      otpInput: "",
      verifyOtpErrorMsg: "",
      verifiedModal: false,
      passwordSuccessModal: false,
      passwordFieldModal: false,
      phoneFieldModal: false,
      updatePhoneSuccessModal: false,
      phoneNumber: "",
      passwordErrorMsg: "",
      phoneErrorMsg: "",
      enableNewPasswordField: true,
      enableConfirmPasswordField: true,
      enableSecurity: true,
      enableMessage: false,
      enableAppearance: false,
      messageCategoriesList: [],
      urlLocation: "",
      messageHeading: { 'id': "", 'attributes': {} },
      selectedAppearance: "",
      selectedFontSize: "",
      selectedTheme: false,
      sidOpen: false,
      ModalOpen: false,
      selectedColor:"",
      fontSizeModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const receivedMessage = message.getData(getName(MessageEnum.SessionResponseData));
      setStorageData('route', receivedMessage.data)
      this.checkNavigate(receivedMessage.data);
    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if (responseJson.error) {
        this.setState({ loading: false })
        toast.error(responseJson.error)
      }
      else if (apiRequestCallId === this.getProfileCallId) {
        this.setState({ loading: false })
        if (responseJson.data) {
          this.setState({
            userProfile: responseJson.data.attributes,
            selectedAppearance: responseJson.data.attributes.selected_theme,
            selectedFontSize: responseJson.data.attributes.font_size
          });
        }
        else if (responseJson?.errors[0]?.token) {
          toast.error("Token has Expired");
          this.goToLogin()
        }
      }
      this.checkVerifyConfirmResponses(message);
      this.checkUpdatePhoneResponses(message);
      this.checkVerificationCodeResponses(message);
      this.checkUpdatePasswordResponses(message);
      this.checkMessageResponses(message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.chooseTheme()

    let menuState:any= localStorage.getItem('open');
    if(menuState!=null){
     this.setState({sidOpen:JSON.parse(menuState)})
    }
    const route = await getStorageData('route')
    this.checkNavigate(route);
    this.getProfile();
    this.getMessageList();
  }

  chooseTheme = async () => {
    const mode = await getStorageData("darkMode");
    if (mode === 'true') {
      this.setState({ selectedTheme: true })
    } else {
      this.setState({ selectedTheme: false })
    }
  }

  checkNavigate = (route: string | null) => {
    this.setState({ urlLocation: route })
    if (route === 'message') {
      this.setState({ enableMessage: true, enableAppearance: false, enableSecurity: false })
    }
    if (route === 'appearance') {
      this.setState({ enableAppearance: true, enableSecurity: false, enableMessage: false })
    }
    if (route === 'security') {
      this.setState({ enableSecurity: true, enableAppearance: false, enableMessage: false })
    }
  }

  apiCall = async (data: { [key: string]: FieldValue }) => {
    const { apiMethod, apiEndPoint, apiBody, apiType, apiContentType } = data;
    let body = apiBody;
    if (apiType === '') {
      body = JSON.stringify(apiBody);
    }
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethod
    );

    apiBody &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  checkVerificationCodeResponses = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (apiRequestCallId === this.sendVerificationCodeApiCallId) {
      if (responseJson.errors) {
        toast.error(responseJson.errors[0].full_phone_number, { containerId: 'C' })
        this.setState({ loading: false, phoneVerificationModal: false })
      } else {
        toast.success(responseJson.message)
        this.setState({ loading: false, otpToken: responseJson.token })
      }
    }
  }

  checkUpdatePhoneResponses = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (apiRequestCallId === this.updatePhoneNumberApiCallId) {
      if (responseJson.message) {
        this.setState({
          phoneFieldModal: false, phoneNumber: "", phoneErrorMsg: "",
          passwordFieldModal: false, passwordErrorMsg: "", newPassword: "",
          updatePhoneSuccessModal: true
        }, () => this.getProfile())
      }
      else if (responseJson.errors[0].profile) {
        if (responseJson.errors[0].profile === "New phone number cannot be the same as the current phone number") {
          this.setState({
            phoneErrorMsg: responseJson.errors[0].profile
          })
        }
        else {
          this.setState({
            phoneFieldModal: false,
            passwordFieldModal: true,
            passwordErrorMsg: responseJson.errors[0].profile
          })
        }
      }
    }
    if (apiRequestCallId === this.verifyPasswordApiCallId) {
      if (responseJson.error) {
        this.setState({ passwordErrorMsg: responseJson.error })
      }
      else if (responseJson.message) {
        this.setState({ phoneFieldModal: true, passwordFieldModal: false })
      }
    }
  }

  checkUpdatePasswordResponses = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (apiRequestCallId === this.updatePasswordApiCallId) {
      if (responseJson.message) {
        this.setState({
          loading: false,
          newPassword: "",
          confirmNewPassword: "",
          passwordSuccessModal: true
        }, () => this.closePasswordModal())
      }
      else if (responseJson.errors[0].profile) {
        this.setState({ loading: false })
        toast.error(responseJson.errors[0].profile)
      }
    }
  }

  checkVerifyConfirmResponses = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (apiRequestCallId === this.verifyOtpApiCallId) {
      if (responseJson.message) {
        this.setState({ loading: false, verifiedModal: true }, () => this.closePhoneVerificationModal())
      }
      else if (responseJson.errors[0].pin) {
        this.setState({ loading: false, otpInput: "" })
        toast.error(responseJson.errors[0].pin, { containerId: 'B' })
      }
    }
  }

  checkMessageResponses = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (apiRequestCallId === this.getMessageListApiCallId) {
      if (responseJson.message_categories) {
        this.setState({ loading: false, messageCategoriesList: responseJson.message_categories, messageHeading: responseJson.message_setting.data })
      }
    }
    if (apiRequestCallId === this.messageCategoriesApiCallId) {
      this.setState({ loading: false }, () => this.getMessageList());
    }
    if (apiRequestCallId === this.allMessageCategoriesApiCallId) {
      this.setState({ loading: false }, () => this.getMessageList());
    }
    if (apiRequestCallId === this.updateAppearanceApiCallId) {
      this.setState({ loading: false })
      toast.success("Appearance updated", { containerId: 'F' })
    }
  }

  goToLogin = () => {
    removeStorageData('authToken')
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getProfile = async () => {
    this.setState({ loading: true })
    this.getProfileCallId = await this.apiCall({
      apiContentType: configJSON.validationApiContentType,
      apiMethod: configJSON.validationApiMethodType,
      apiEndPoint: configJSON.getProfileApiEndPoint,
    });
  }

  getMessageList = async () => {
    this.setState({ loading: true })
    this.getMessageListApiCallId = await this.apiCall({
      apiContentType: configJSON.validationApiContentType,
      apiMethod: configJSON.validationApiMethodType,
      apiEndPoint: configJSON.getMessageListEndPoint,
    });
  }

  updateMessageCategory = async (categoryId: number) => {
    this.setState({ loading: true })
    this.updateCategoryApiCallId = await this.apiCall({
      apiContentType: configJSON.validationApiContentType,
      apiMethod: configJSON.httpPutApiMethod,
      apiEndPoint: `${configJSON.updateMessageCategory}/${categoryId}`,
    });
  }

  openPasswordModal = () => {
    this.setState({ passwordModal: true })
  }

  closePasswordModal = () => {
    this.setState({
      passwordModal: false,
      newPassword: '', confirmNewPassword: '', phoneNumber: '', passwordErrorMsg: '', phoneErrorMsg: '',
      passwordFieldModal: false, phoneFieldModal: false,
      phoneVerificationModal: false, updatePhoneSuccessModal: false,
      numberChar: false, charLength: false, specialChar: false, lowerChar: false, upperChar: false,
      enableNewPasswordField: true, enableConfirmPasswordField: true
    })
  }

  closeVerifiedModal = () => {
    this.setState({ verifiedModal: false, passwordSuccessModal: false, updatePhoneSuccessModal: false, newPassword: "", phoneNumber: "", otpInput: "" }, () => this.getProfile())
  }

  changeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ passwordErrorMsg: "" })
    const value = event?.target.value
    if (/\d/.test(value)) {
      this.setState({ numberChar: true })
    } else {
      this.setState({ numberChar: false })
    }
    if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
      this.setState({ specialChar: true })
    } else {
      this.setState({ specialChar: false })
    }
    if (/\p{Lu}/u.test(value)) {
      this.setState({ upperChar: true })
    } else {
      this.setState({ upperChar: false })
    }
    if (value !== value.toUpperCase()) {
      this.setState({ lowerChar: true })
    } else {
      this.setState({ lowerChar: false })
    }
    if (value.length >= 8) {
      this.setState({ charLength: true })
    } else {
      this.setState({ charLength: false })
    }
    this.setState({ newPassword: value })
  }

  spaceReturn = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }

  changeConfirmNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmNewPassword: event.target.value })
  }

  changePassword = async () => {
    if (!this.state.newPassword || !this.state.confirmNewPassword) {
      toast.error("All field are mandatory", { containerId: 'A' })
      return
    }
    if (this.state.newPassword !== this.state.confirmNewPassword) {
      toast.error("Password and confirm password not same", { containerId: 'A' })
      return
    }
    let body = {
      "data": {
        "new_password": this.state.newPassword,
        "confirm_password": this.state.confirmNewPassword
      }
    }
    this.updatePasswordApiCallId = await this.apiCall({
      apiContentType: configJSON.validationApiContentType,
      apiMethod: configJSON.httpPutApiMethod,
      apiEndPoint: configJSON.updatePasswordApiEndPoint,
      apiBody: JSON.stringify(body)
    });
  }

  openPhoneVerificationModal = () => {
    if (this.state.userProfile.phone_number_verified) {
      this.setState({ passwordFieldModal: true })
    } else {
      this.setState({ phoneVerificationModal: true }, () => this.sendVerificationCode())
    }
  }

  closePhoneVerificationModal = () => {
    this.setState({ phoneVerificationModal: false, otpInput: "" })
  }

  sendVerificationCode = async () => {
    this.setState({ loading: true })
    let body = {
      "data": {
        "attributes": { "full_phone_number": this.state.userProfile.full_phone_number }
      }
    }
    this.sendVerificationCodeApiCallId = await this.apiCall({
      apiContentType: configJSON.validationApiContentType,
      apiMethod: configJSON.exampleAPiMethod,
      apiEndPoint: configJSON.sendOtpApiEndPoint,
      apiBody: JSON.stringify(body)
    });
  }
  changeOtpInput = (event: string) => {
    this.setState({ otpInput: event })
  }

  verifyOtp = async () => {
    this.setState({ loading: true })
    let body = {
      "pin": this.state.otpInput,
      "otp_token": this.state.otpToken
    }
    this.verifyOtpApiCallId = await this.apiCall({
      apiContentType: configJSON.validationApiContentType,
      apiMethod: configJSON.exampleAPiMethod,
      apiEndPoint: configJSON.verifyOtpApiEndPoint,
      apiBody: JSON.stringify(body)
    });
  }

  verifyPassword = async () => {
    if (this.state.newPassword === "") {
      toast.error("Please enter password", { containerId: 'D' })
    }
    else {
      let body = {
        "data": {
          "current_password": this.state.newPassword
        }
      }
      this.verifyPasswordApiCallId = await this.apiCall({
        apiContentType: configJSON.validationApiContentType,
        apiMethod: configJSON.exampleAPiMethod,
        apiEndPoint: configJSON.verifyPasswordApiEndPoint,
        apiBody: JSON.stringify(body)
      });
    }
  }

  changePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ phoneNumber: event.target.value, phoneErrorMsg: "" })
  }

  acceptPhoneNumber = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
  }

  updatePhoneNumber = async () => {
    if (this.state.phoneNumber === "") {
      toast.error("Please Enter Phone Number", { containerId: "E" })
      return
    }
    if (this.state.phoneNumber.length < 11 || this.state.phoneNumber.length > 15) {
      toast.error("Please Enter valid Phone Number", { containerId: "E" })
      return
    }
    let body = {
      "data": {
        'current_password': this.state.newPassword,
        'new_phone_number': this.state.phoneNumber
      }
    }
    this.updatePhoneNumberApiCallId = await this.apiCall({
      apiMethod: configJSON.httpPutApiMethod,
      apiContentType: configJSON.validationApiContentType,
      apiEndPoint: configJSON.updatePhoneApiEndPoint,
      apiBody: JSON.stringify(body)
    });
  }

  showNewPassword = () => {
    this.setState({ enableNewPasswordField: !this.state.enableNewPasswordField })
  }

  showConfirmPassword = () => {
    this.setState({ enableConfirmPasswordField: !this.state.enableConfirmPasswordField })
  }

  changePush = async (event: React.ChangeEvent<HTMLInputElement>, value: { data: { id: string, attributes: { [key: string]: FieldValue } } }, type: string) => {
    let body = {
      "message_category": {
        "push": type === 'push' ? event.target.checked : value.data.attributes.push,
        "email": type === 'push' ? value.data.attributes.email : event.target.checked
      }
    }
    this.setState({ loading: true })
    this.messageCategoriesApiCallId = await this.apiCall({
      apiMethod: configJSON.httpPutApiMethod,
      apiContentType: configJSON.validationApiContentType,
      apiEndPoint: `${configJSON.updateMessageCategory}/${value.data.id}`,
      apiBody: JSON.stringify(body)
    });
  }

  changeAllPush = async (event: React.ChangeEvent<HTMLInputElement>, value: { id: string, attributes: { [key: string]: FieldValue } }, type: string) => {
    let body =
    {
      "message_setting": (type === 'push') ? { "push": event.target.checked } : { "email": event.target.checked }
    }
    this.setState({ loading: true })
    this.allMessageCategoriesApiCallId = await this.apiCall({
      apiContentType: configJSON.validationApiContentType,
      apiMethod: configJSON.httpPutApiMethod,
      apiEndPoint: `${configJSON.updateAllMessageEndPoint}/${value.id}`,
      apiBody: JSON.stringify(body)
    });
  }

  selectTheme = (value: string) => {
    this.setState({ selectedAppearance: value }, () => this.updateProfile())
    if (value === 'system') {
      const darkTheme = window.matchMedia("(prefers-color-scheme: dark)");
      darkTheme.addEventListener('change', event => {
        if (event.matches) {
          this.setState({ selectedTheme: true })
        } else {
          this.setState({ selectedTheme: false })
        }
      });
    } else if (value === "dark") {
      setStorageData('darkMode', 'true')
      this.setState({ selectedTheme: true })
    } else {
      setStorageData('darkMode', 'false')
      this.setState({ selectedTheme: false })
    }
  }

  selectFontSize = (value: string) => {
    this.setState({ selectedFontSize: value }, () => this.updateProfile())
  }

  resetAppearance = async () => {
    toast.success("Your appearance settings have been reset to default.", { containerId: 'F' });
    this.setState({ selectedAppearance: "system", selectedFontSize: 'default_size' }, () => this.updateProfile());
  }

  updateProfile = async () => {
    this.setState({ loading: true })
    let body = {
      'selected_theme': this.state.selectedAppearance, 'font_size': this.state.selectedFontSize
    }
    this.updateAppearanceApiCallId = await this.apiCall({
      apiContentType: configJSON.validationApiContentType,
      apiMethod: configJSON.httpPutApiMethod,
      apiEndPoint: configJSON.updateProfileEndPoint,
      apiBody: JSON.stringify(body)
    });
  }

  toggleSideMenu = () => {
    this.setState({ sidOpen: !this.state.sidOpen })
    setStorageData('open', JSON.stringify(!this.state.sidOpen));
  }

  changeTheme = () => {
    this.setState({ selectedTheme: !this.state.selectedTheme })
    setStorageData("darkMode", `${!this.state.selectedTheme}`)
  }

  getCustomHeaderDataRequest = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.customHeaderAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCustomHeaderApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };  
  handleClose= () => {
    this.setState({ModalOpen: false})
  }
  
  handleOpenModal=()=>{
    this.setState({ModalOpen: true})
  }

  selectColor = (color:string) => {
    this.setState({ selectedColor: color });
  }
  
  handleFontSizeModal=()=>{
    this.setState({fontSizeModal: !this.state.fontSizeModal})
  }
  
  // Customizable Area End
}
