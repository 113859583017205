// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  styled,
  InputAdornment,
  Grid,TextField,Badge,Paper,Divider,
  Modal,
  Menu,MenuItem
} from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import HamburgerMenu from "../../hamburgermenu/src/HamburgerMenu.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { profileImage,addSqureIcon, moreIcon, editIcon, trashIcon, minimiseIcon, courseVideo, playIcon, downloadIcon, playButton, downloaded, closeIcon, submittedIcon,deleteWarningIcon, reviewAddIcon, starRatingIcons } from "./assets";
import { NotesData,Note } from "./ProjectnotesController";
import ReactQuill from 'react-quill';
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import ProjectnotesController, {
  Props,
  configJSON,
} from "./ProjectnotesController";

export default class Projectnotes extends ProjectnotesController {
  constructor(props: Props) {
    super(props);
  }


  renderVideoContainer=()=>{
    return (
      <EachVideoBox>
      <VideoContainer style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
        <Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
          <img src={playButton} style={{width:"24px",height:"24px"}}/>
          <VideoNameTypo>Intro 1</VideoNameTypo>
        </Box>
        <Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
          <VideoTimer>04:23</VideoTimer>
        </Box>
      </VideoContainer>
      <img src={downloadIcon} style={{width:"20px",height:"20px"}}/>
      </EachVideoBox>
    )
  }
  renderEachNoteBox=(allNotes:NotesData,editPost:any,handleDeleteModal:any)=>{
    return (<>
      {allNotes.data.map((note:Note)=>(
        <SingleNoteBox>
        <Box style={{width:"230px",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
        <ProfileImageNameBox style={{justifyContent:"flex-start"}}>
          <img src={profileImage} style={{width:"40px",height:"40px"}} />
          <NameBox>
            <NameTypography>{note.attributes.preferred_name}</NameTypography>
            <PositionTypography>{note.attributes.role_id}</PositionTypography>
          </NameBox> 
        </ProfileImageNameBox>
        <img src={moreIcon} style={{width:"18px",height:"36px"}} data-test-id="moreMenuTest" onClick={()=>this.handleMoreMenu(note.id)}/>
        <MoreOptionsMenu open={this.state.openMoreMenu} onClose={this.closeMoreMenu}>
          <MenuItem data-test-id="editTest" onClick={()=>{editPost(note)}}>
            <img src={editIcon} style={{width:"24px",height:"24px"}}/><EditTypo>Edit Post</EditTypo>
          </MenuItem>
          <MenuItem data-test-id="deleteTest" onClick={()=>{handleDeleteModal(note.id)}}>
            <img src={trashIcon} style={{width:"24px",height:"24px"}}/><DeleteTypo>Delete Post</DeleteTypo>
          </MenuItem>
        </MoreOptionsMenu>
        </Box>
        <Box style={{width:"210px",display:"flex",flexDirection:"column",justifyContent:"space-between",alignItems:"center"}}>
          <NoteHeadTypo data-test-id="noteTitleTest">
           {note.attributes.title}
          </NoteHeadTypo>
          <NoteDetailTypo>
            {note.attributes.note_content}
          </NoteDetailTypo>
        </Box>
        <Box style={{width:"210px",height:"19px",display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center"}}>
        <TimeTypo>{note.attributes.created_date} {note.attributes.created_time}</TimeTypo>
        </Box>
      </SingleNoteBox>
    
     ))}
     </>
     )
  }
  render() {
    return (
      <ThemeProvider theme={this.state.darkTheme ? darkTheme : lightTheme}>
        <HamburgerMenu {...this.props} open={this.state.open} handleMenu={this.handleMenu}  themeDark={this.state.darkTheme} handleTheme={this.handleTheme}/>
        <MainContainer data-test-id="mainContainer" style={{paddingLeft: this.state.open ? 190 : 50}}>
          <AddNoteModal open={this.state.openNoteModal} onClose={this.closeNoteModal}>
            <ModalPaper>
            <AddNoteBox>
              <AddNoteHeaderBox>
                <AddNoteHeaderTypo>{ this.state.enableEdit ? "Edit Note": "Add Note"}</AddNoteHeaderTypo>
                <AddHeaderDivider></AddHeaderDivider>
              </AddNoteHeaderBox>
              <Box style={{margin:"15px"}}>
              <NoteTitleTypo>
                Note Title
              </NoteTitleTypo>
              <InputField onChange={this.handleNoteTitle} value={this.state.title} data-test-id="addTitleInput" defaultValue="Add Note"></InputField>
              <NoteTitleTypo>
                Note Description
              </NoteTitleTypo>
              <ReactQuill 
                ref={this.quillRef}
                data-test-id="addNoteInput"
                placeholder={"Add your Note Description"}
                value={this.state.note}
                onChange={(event) => this.handleNoteDescription(event)}            
                formats={formats} 
                style={{width: "604px",
                height: "215px",
                padding: "10px 12px 10px 16px",
                gap: "4px",
                borderRadius: "8px",
                opacity: "0px",
                backgroundColor: theme.palette.background.default,
                fontFamily: "Rubik",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "22px",
                textAlign: "left",
                color: theme.palette.text.hint}}/>
                </Box>
                <ButtonDetailBox>
                <ProfileImageNameBox style={{justifyContent:"flex-start"}}>
                    <img src={profileImage} style={{width:"40px",height:"40px"}}/>
                    <NameBox>
                      <NameTypography>Ibrahim M.</NameTypography>
                      <PositionTypography>Instructor</PositionTypography>
                    </NameBox> 
                  </ProfileImageNameBox>
                  <ButtonBox>
                    <CancelButton onClick={this.closeNoteModal}>Cancel</CancelButton>
                   {(this.state.enableEdit ? <AddButton onClick={this.handleEditProjectNote} data-test-id="editPostTest">Save</AddButton>:<AddButton onClick={this.handlePostProjectNote} data-test-id="addPostTest">Save</AddButton> )} 
                  </ButtonBox>
                </ButtonDetailBox>
            </AddNoteBox>
            </ModalPaper>
          </AddNoteModal>
          <SubmittedNoteModal open={this.state.submitNoteModal} onClose={this.closeSubmitModal}>
            <SubmittedModalPaper>
              <Box style={{display:"flex",flexDirection:"row",alignItems:"flex-end",justifyContent:"flex-end"}}>
                <img src={closeIcon} style={{width:"32px",height:"32px",gap:"10px",padding:"4px",margin:"20px"}} onClick={this.closeSubmitModal}/>
              </Box>
              <Box style={{width:"640px",height:"320px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start",gap:"2px"}}>
                <img src={submittedIcon} style={{
                  width: "420px",
                  height: "176px",
                  padding: "0px 88px 0px 88px",
                  gap: "44px"
                }}/>
                <SubmittedTypo>Your note has been saved Successfully</SubmittedTypo>
                <DoneButton onClick={this.closeSubmitModal}>Done</DoneButton>
              </Box>
            </SubmittedModalPaper>
          </SubmittedNoteModal>

          <SubmittedNoteModal open={this.state.editModal} onClose={this.closeEditModal}>
            <SubmittedModalPaper>
              <Box style={{display:"flex",flexDirection:"row",alignItems:"flex-end",justifyContent:"flex-end"}}>
                <img src={closeIcon} style={{width:"32px",height:"32px",gap:"10px",padding:"4px",margin:"20px"}} onClick={this.closeEditModal}/>
              </Box>
              <Box style={{width:"640px",height:"320px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start",gap:"2px"}}>
                <img src={submittedIcon} style={{
                  width: "420px",
                  height: "176px",
                  padding: "0px 88px 0px 88px",
                  gap: "44px"
                }}/>
                <SubmittedTypo>Your note has been edited Successfully</SubmittedTypo>
                <DoneButton onClick={this.closeEditModal} data-test-id="doneEditedTest">Done</DoneButton>
              </Box>
            </SubmittedModalPaper>
          </SubmittedNoteModal>

          <SubmittedNoteModal open={this.state.deleteSuccessfulModal} onClose={this.closeDeleteSuccessfulModal}>
            <SubmittedModalPaper>
              <Box style={{display:"flex",flexDirection:"row",alignItems:"flex-end",justifyContent:"flex-end"}}>
                <img src={closeIcon} style={{width:"32px",height:"32px",gap:"10px",padding:"4px",margin:"20px"}} onClick={this.closeDeleteSuccessfulModal}/>
              </Box>
              <Box style={{width:"640px",height:"320px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start",gap:"2px"}}>
                <img src={submittedIcon} style={{
                  width: "420px",
                  height: "176px",
                  padding: "0px 88px 0px 88px",
                  gap: "44px"
                }}/>
                <SubmittedTypo>Your note has been deleted Successfully</SubmittedTypo>
                <DoneButton onClick={this.closeDeleteSuccessfulModal} data-test-id="doneDeletedTest">Done</DoneButton>
              </Box>
            </SubmittedModalPaper>
          </SubmittedNoteModal>

          <DeleteNoteModal open={this.state.deleteModal} onClose={this.closeDeleteModal}>
            <DeleteModalPaper>
            <PopupHeaderBox data-test-id="popup-header">
                <DeleteTypography data-test-id="popup-warning-title">Delete</DeleteTypography>
                <Divider style={{  width: "92.5px",
                  height: "2px",
                  backgroundColor: "#D7D7D7" }}/>
                <SearchTypography id="alert-dialog-title" data-test-id="popup-title">Delete Note</SearchTypography>
                <Divider style={{  width: "92.5px",
                  height: "2px",
                  backgroundColor: "#D7D7D7" }}/>
                  <img src={closeIcon} style={{width:"32px",height:"32px"}} onClick={this.closeDeleteModal}/>
            </PopupHeaderBox>
            <TextColor style={{ display: "flex", alignItems: "center", padding: 0, margin: "10px 0" }} data-test-id="popup-warning-text">
              <img src={deleteWarningIcon} alt="delete_warning" style={{width:"130px",height:"130px"}}/>
              <DeleteDetailTypography>Deleting this post will cause your content to be lost. Are you sure you want to delete?</DeleteDetailTypography>
            </TextColor>
            <Footer data-test-id="popup-footer">
              <CancelButton onClick={this.closeDeleteSuccessfulModal} data-test-id="popup-cancel-button">
                Cancel
              </CancelButton>
              <DeleteButton onClick={this.handleDeleteProjectNote} data-test-id="popup-delete-button">
                Delete
              </DeleteButton>
            </Footer>
            </DeleteModalPaper>
          </DeleteNoteModal>
          <NavigationMenu navigation={undefined} id={""} open={this.state.open} theme={this.state.darkTheme}/>
          <Grid container style={{width:"80%",margin:"46px 26px"}}>
                <Grid item md={3} lg={3}>
                  <AboutCoursesBox>
                    <Box style={{display:"flex",justifyContent:"center",flexDirection:"row",alignItems:"center",width:"265px",gap:"5px"}}>
                    <CourseTabSection>
                      <SelectedFilter><SelectedFilterTypo>About</SelectedFilterTypo></SelectedFilter>
                      <DeselectedFilter><DeselectedFilterTypo>Media</DeselectedFilterTypo></DeselectedFilter>
                      <DeselectedFilter><DeselectedFilterTypo>Tests</DeselectedFilterTypo></DeselectedFilter>
                    </CourseTabSection>
                    <img src={minimiseIcon} style={{width:"34px",height:"34px"}}/>
                    </Box>
                    <Box>
                    <ProfileImageNameBox style={{justifyContent:"flex-start"}}>
                    <img src={profileImage} style={{width:"40px",height:"40px"}}/>
                    <NameBox>
                      <NameTypography>Ibrahim M.</NameTypography>
                      <PositionTypography>Instructor</PositionTypography>
                    </NameBox> 
                  </ProfileImageNameBox>
                  </Box>
                  <Box>
                    <InstructorTypo>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</InstructorTypo>
                  </Box>
                  <Box style={{display:"flex",flexDirection:"row",alignItems:"end",justifyContent:"space-between", borderTop: "1px solid #D7D7D7",width: "266px",height: "39.47px",}}>
                    <DownloadTypo>Downloaded By</DownloadTypo>
                    <img src={downloaded} style={{  width: "66.87px",height: "23.47px",}}/>
                  </Box>
                  <Divider style={{  width: "266px",
                  height: "1px",
                  backgroundColor: "#D7D7D7" }}/>
                  <VideoIntroBox>
                    <IntroTitleTypo>Intro 1</IntroTitleTypo>
                    <IntroDetailTypo>This lesson covers the basics of renewable energy sources such as solar, wind, and hydroelectric power. You'll learn how these technologies work and their environmental benefits. Discover why renewable energy is crucial for a sustainable future!</IntroDetailTypo>
                  </VideoIntroBox>
                  <Divider style={{  width: "266px",
                  height: "1px",
                  backgroundColor: "#D7D7D7" }}/>
                  <CourseReviewBox>
                    <CourseReviewHeadBox>
                      <CourseReviewSideBox>
                        <ReviewTitle>Course Review</ReviewTitle>
                        <ReviewDetails>You can add your review also after finishing the course</ReviewDetails>
                      </CourseReviewSideBox>
                      <img src={reviewAddIcon} style={{width:"34px",height:"34px"}}/>
                    </CourseReviewHeadBox>
                  </CourseReviewBox>
                  <ScrollableReviewBox>
                    <EachReviewBox>
                      <SmallProfileBox>
                        <img src={profileImage} style={{width:"23.47px",height:"23.47px"}}/>
                        <SmallProfileNameBox>
                          <SmallName>Ibrahim M.</SmallName>
                          <SmallPosition>Manager</SmallPosition>
                        </SmallProfileNameBox>
                      </SmallProfileBox>
                      <CommentsTypo>This lesson covers the basics of renewable energy sources such as solar, wind, and hydroelectric </CommentsTypo>
                      <RatingsTimeBox>
                        <ReviewTimeTypo>02/10/2024</ReviewTimeTypo>
                        <Box style={{width:"103px",height:"15px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                          <RatingTypo>4.5</RatingTypo>
                          <img src={starRatingIcons} style={{width:"74px",height:"15px"}}/>
                        </Box>
                      </RatingsTimeBox>
                    </EachReviewBox>
                  </ScrollableReviewBox>
                  </AboutCoursesBox>
                </Grid>
                <Grid item md={6} lg={6} style={{display:"flex",flexDirection:"column",gap:"20px"}}>
                  <MainVideoCourseBox>
                    <CourseDetailBox>
                    <CourseTimeBox>
                      <CourseHeadingTypography>Course Name</CourseHeadingTypography>
                      <CourseTimeTypo>Category of the course - 1h 05m - 06/26/2024 1:45 PM</CourseTimeTypo>
                    </CourseTimeBox>
                      <AddCourseButton><img src={addSqureIcon} style={{width:"20px",height:"20px"}}/>Assign Course</AddCourseButton>
                    </CourseDetailBox>
                    <CourseVideoContainer>
                      <img src={courseVideo} style={{width:"587px",height:"311px",borderRadius:"18px"}}/>
                      <img src={playIcon} style={{width:"54px",height:"40px", position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: theme.spacing(1),
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',}}/>
                    </CourseVideoContainer>
                  </MainVideoCourseBox>
                  <TableBox>
                  <CourseDetailBox>
                    <CourseTimeBox>
                      <CourseHeadingTypography>Course Name</CourseHeadingTypography>
                      <CourseTimeTypo>12 Lessons</CourseTimeTypo>
                    </CourseTimeBox>
                      <AddCourseButton><img src={downloadIcon} style={{width:"20px",height:"20px"}}/>Download Course</AddCourseButton>
                      <img src={minimiseIcon} style={{width:"34px",height:"34px"}}/>
                    </CourseDetailBox>
                    <AllViewContainerBox>
                      <EachVideoBox>
                      <SelectedVideoContainer style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                        <Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                          <img src={playButton} style={{width:"24px",height:"24px"}}/>
                          <VideoNameTypo>Intro 1</VideoNameTypo>
                        </Box>
                        <Box style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                          <VideoTimer>04:23</VideoTimer>
                        </Box>
                      </SelectedVideoContainer>
                      <img src={downloadIcon} style={{width:"20px",height:"20px"}}/>
                      </EachVideoBox>
                      {this.renderVideoContainer()}
                      {this.renderVideoContainer()}
                      {this.renderVideoContainer()}
                    </AllViewContainerBox>
                  </TableBox>
                </Grid>
                <Grid item md={3} lg={3}>
                  <NotesBox>
                  <Box style={{display:"flex",justifyContent:"center",flexDirection:"row",alignItems:"center",width:"265px",gap:"5px"}}>
                    <CourseTabSection>
                      <SelectedFilter style={{width:"109px",alignItems:"center",justifyContent:"center"}}><SelectedFilterTypo>Notes</SelectedFilterTypo></SelectedFilter>
                      <DeselectedFilter style={{width:"109px",alignItems:"center",justifyContent:"flex-start"}}><DeselectedFilterTypo>Comments</DeselectedFilterTypo></DeselectedFilter>
                    </CourseTabSection>
                    <img src={minimiseIcon} style={{width:"34px",height:"34px"}}/>
                  </Box>
                  <AllNotes>
                      {this.renderEachNoteBox(this.state.allNotes,this.editPost,this.handleDeleteModal)}
                  </AllNotes>
                    <AddNotesButton data-test-id="addNewNoteButton" onClick={this.handleNoteModal}><img src={addSqureIcon} style={{width:"20px",height:"20px"}}/>Add Notes</AddNotesButton>
                  </NotesBox>
                </Grid>
          </Grid>
        </MainContainer>
      </ThemeProvider>
    );
  }
}
const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
];
const ScrollableReviewBox = styled(Box)(({ theme }) => ({
  width: "266px",
  height: "137px",
  gap: "10px",
  opacity: "0px"
}))

const EachReviewBox = styled(Box)(({ theme }) => ({
  width: "243px",
  height: "100.67px",
  padding: "8px 16px 8px 16px",
  gap: "6px",
  borderRadius: "18px",
  opacity: "0px",
  backgroundColor: theme.palette.background.default,
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  flexDirection:"column",
}))

const SmallProfileBox = styled(Box)(({ theme }) => ({
width:"211px",  
height: "26.4px",
  padding: "0.23px 139.84px 0.36px 0px",
  gap: "0px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",

}))

const SmallProfileNameBox = styled(Box)(({ theme }) => ({
 width: "43px",
  height: "25.81px",
  gap: "4.69px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
}))

const RatingsTimeBox = styled(Box)(({ theme }) => ({
  width: "211px",
  height: "14.67px",
  gap: "0px",
  justifyContent: "space-between",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  margin:"10px",
}))

const SmallName = styled(Typography)(({ theme }) => ({
  width: "43px",
  height: "16px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "8.21px",
  fontWeight: 700,
  lineHeight: "10.56px",
  textAlign: "left",
  color: theme.palette.info.main
}))
const SmallPosition = styled(Typography)(({ theme }) => ({
  width: "43px",
  height: "16px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "8.21px",
  fontWeight: 700,
  lineHeight: "10.56px",
  textAlign: "left",
  color: theme.palette.info.light
}))




const CommentsTypo = styled(Typography)(({ theme }) => ({
 width: "211px",
  height: "21.6px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "10.8px",
  letterSpacing: "0.41111114621162415px",
  textAlign: "left",
  color: theme.palette.text.disabled
}))

const ReviewTimeTypo = styled(Typography)(({ theme }) => ({
  width: "58px",
  height: "12px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "12px",
  textAlign: "left",
  color: theme.palette.text.disabled
}))

const RatingTypo = styled(Typography)(({ theme }) => ({
    width: "21px",
  height: "12px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "12px",
  textAlign: "left",
  color: theme.palette.text.disabled
}))
const CourseReviewBox = styled(Box)(({ theme }) => ({
  width: "266px",
  height: "205.53px",
  gap: "0px",
  opacity: "0px"
}))

const CourseReviewHeadBox = styled(Box)(({ theme }) => ({
  width: "266px",
  height: "55px",
  gap: "0px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  justifyContent:"space-between",
  alignItems:"center"
}))

const CourseReviewSideBox = styled(Box)(({ theme }) => ({
  width: "170px",
  height: "55px",
  gap: "0px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  justifyContent:"flex-start",
  alignItems:"center"
}))


const ReviewTitle = styled(Typography)(({ theme }) => ({
 width: "138px",
  height: "26px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "26px",
  textAlign: "left",
  color: theme.palette.text.hint,
}))
const ReviewDetails = styled(Typography)(({ theme }) => ({
    width: "161px",
  height: "27px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "12px",
  textAlign: "left",
  color: theme.palette.text.disabled
}))

const VideoIntroBox = styled(Box)(({ theme }) => ({
  width: "266px",
  height: "158px",
  gap: "8px",
  opacity: "0px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}))

const IntroTitleTypo = styled(Typography)(({ theme }) => ({
  width: "266px",
  height: "24px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.text.hint
}))
const IntroDetailTypo = styled(Typography)(({ theme }) => ({
  width: "266px",
  height: "126px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "17.5px",
  letterSpacing: "0.41111114621162415px",
  textAlign: "left",
  color: theme.palette.action.hover,
}))

const Footer = styled('div')(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));
const DeleteDetailTypography = styled(Typography)(({ theme }) => ({
  width: "361px",
  height: "80px",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color: "#000000",
}))
const DeleteButton = styled(Button)({
  width: "318px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  borderRadius: "30px",
  backgroundColor: theme.palette.action.selected,
  cursor:"pointer",
  "& .MuiButton-label":{
    width: "52px",
  height: "24px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "left",
  color: "#F8FAFC",
  },
  "&:hover": {
    background: "#1A469C",  
    color: "#F8FAFC"
  }
});
const TextColor = styled(Typography)(({ theme }) => ({
  color: theme.palette.action.selected
}))
const PopupHeaderBox = styled(Box)(({ theme }) => ({
  width: "515px",
  height: "38px",
  gap: "16px",
  opacity: "0px",
  display: "flex",
  flexDirection:"row", 
  justifyContent: "space-between", 
  alignItems: "center"
}));
const SearchTypography = styled(Typography)(({ theme }) => ({
  width: "200px",
  height: "26px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "22px",
  fontWeight: 400,
  lineHeight: "26.07px",
  textAlign: "left",
  color: theme.palette.info.contrastText
}))
const DeleteTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "32px",
  fontWeight: 500,
  lineHeight: "37.92px",
  textAlign: "center",
  width: "101px",
  height: "38px",
  gap: "0px",
  opacity: "0px",
  color: theme.palette.secondary.dark,
}))
const NoteHeadTypo = styled(Typography)(({ theme }) => ({
  width: "210px",
 height: "22px",
 gap: "0px",
 opacity: "0px",
 fontFamily: "Rubik",
 fontSize: "14.01px",
 fontWeight: 500,
 lineHeight: "21.01px",
 textAlign: "left",
 color: theme.palette.text.hint
}))
const NoteDetailTypo = styled(Typography)(({ theme }) => ({
 width: "210px",
 height: "36.77px",
 gap: "0px",
 opacity: "0px",
 fontFamily: "Rubik",
 fontSize: "12.26px",
 fontWeight: 400,
 lineHeight: "12.26px",
 textAlign: "left",
 color: theme.palette.text.primary
}))




const TimeTypo = styled(Typography)(({ theme }) => ({
 width: "232px",
 height: "19px",
 gap: "0px",
 opacity: "0px",
 fontFamily: "Rubik",
 fontSize: "10px",
 fontWeight: 400,
 lineHeight: "19px",
 textAlign: "right",
 color: theme.palette.action.hover
}))

const SingleNoteBox = styled(Box)(({ theme }) => ({
  width: "260px",
 height: "155.69px",
 padding: "12px 12px 12px 16px",
 gap: "8px",
 borderRadius: "18px",
 border: "0px 0px 0px 4px",
 opacity: "0px",
 backgroundColor: theme.palette.background.default,
 display: "flex",
 flexDirection: "column",
 justifyContent: "flex-start",
 alignItems: "flex-start"
}))
const ButtonDetailBox = styled(Box)(({ theme }) => ({
  width: "573px",
    height: "44px",
    justify: "space-between",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin:"20px",
  }))
  const ButtonBox = styled(Box)(({ theme }) => ({
    width: "183px",
    height: "44px",
    gap: "25px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }))
  const DoneButton = styled(Button)(({ theme }) => ({
    width: "529px",
      height: "46px",
      padding: "10px 16px 10px 16px",
      gap: "8px",
      borderRadius: "30px",
      backgroundColor: theme.palette.action.selected, 
     "& .MuiButton-label":{
         width: "47px",
         height: "26px",
         gap: "0px",
         opacity: "0px",
        fontFamily: "Rubik",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26px",
        textAlign: "left",
        color: "#F8FAFC"
      },
      ".MuiButton-root:hover": {
        textDecoration: "none",
        backgroundColor: "#1A469C", 
      }
    }))
    const AllNotes = styled(Box)(({ theme }) => ({
      width: "260px",
      height: "616px",
      gap: "8px",
      opacity: "0px",
      display:"flex",
      flexDirection:"column",
      overflowY:"auto",
      "&::-webkit-scrollbar": {
        width: "13px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#EDF1F6",
        height: "43px",
        width:"13px",  
        borderRadius: "30px 30px 90px 30px", 
        border: "1px solid  #94A3B8", 
        opacity: "1",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        opacity: 1,
      },
      "&::-webkit-scrollbar-track": {
        background: "transparent", 
        borderRadius: "30px 30px 90px 30px", 
        border: "1px solid  #94A3B8", 
        opacity:"1"
      },
      
      scrollbarWidth: "thin",  
      scrollbarColor: "var(--Slider-Slider-Bg, #EDF1F6) transparent", 
    }))
  const SubmittedTypo = styled(Typography)(({ theme }) => ({
    width: "480px",
     height: "40px",
     fontFamily: "Rubik",
     fontSize: "24px",
     fontWeight: 600,
     lineHeight: "40px",
     letterSpacing: "-0.005em",
     textAlign: "center",
     color: theme.palette.secondary.dark
   }))
const DownloadTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "left",
  width: "87px",
  height: "18px",
  gap: "0px",
  opacity: "0px",
  color: theme.palette.text.hint
}))
const AllViewContainerBox = styled(Box)(({ theme }) => ({
  width: "564px", height: "197px", gap: "4px",padding:"10px 16px",
  }))
const VideoContainer = styled(Box)(({ theme }) => ({
    width: "564px", height: "197px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    opacity: "0px",
}))
const EachVideoBox = styled(Box)(({ theme }) => ({
  width: "564px", height: "44px", display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"
  }))
  
const VideoNameTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.action.active
}))
const VideoTimer = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.text.disabled
}))
const SelectedVideoContainer = styled(Box)(({ theme }) => ({
  width: "532px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  border: `1px solid ${theme.palette.action.active}`
}))
  
const CourseDetailBox = styled(Box)(({ theme }) => ({
  width:"587px",
  height:"54px",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"space-between",
  margin:"30px 38px",
 }))
 
 const CourseHeadingTypography = styled(Typography)(({ theme }) => ({
   width: "116px",
   height: "26px",
   gap: "0px",
   opacity: "0px",
   fontFamily: "Rubik",
   fontSize: "18px",
   fontWeight: 500,
   lineHeight: "26px",
   textAlign: "left",
   color: theme.palette.text.hint
 }))
 const CourseTimeBox = styled(Box)(({ theme }) => ({
  display:"flex",
  flexDirection:"column",
  gap:"5px",
  
 }))
 const CourseTimeTypo = styled(Typography)(({ theme }) => ({
  width: "276px",
   height: "12px",
   gap: "0px",
   opacity: "0px",
   fontFamily: "Rubik",
   fontSize: "10px",
   fontWeight: 400,
   lineHeight: "12px",
   textAlign: "left",
   color: theme.palette.text.disabled
 }))
 const CourseVideoContainer = styled(Box)(({ theme }) => ({
  width: "587px", height: "311px", borderRadius: "18px",position:"relative",
  margin:"30px 24px"
 }))
 const AddCourseButton = styled(Button)(({ theme }) => ({
   width: "180px",
   height: "24px",
   gap: "8px",
   opacity: "0px",
   border: "none",
   "& .MuiButton-label":{
      width: "178px", 
      height: "22px",
      fontFamily: "Rubik",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      color: theme.palette.action.active
   }
})) 
const AddNotesButton = styled(Button)(({ theme }) => ({
  width: "150px", 
  height: "26px", 
  gap: "8px",
  border: "none",
  marginLeft:"140px",
  "& .MuiButton-label":{
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left",
    color: theme.palette.action.active,
    width: "130px",
    height: "26px"
  }
})) 

const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: theme.palette.background.default,
  height: 'inherit',
  '& .MuiPaper-root': {
      backgroundColor: theme.palette.primary.main
  }
}))
const AboutCoursesBox = styled(Box)(({ theme }) => ({
  width: "326px",
  height: "753px",
  borderRadius: "18px",
  backgroundColor: theme.palette.background.paper,
  gap:"20px",
  padding:"28px 34px",
  display:"flex",
  justifyContent:"flex-start",
  flexDirection:"column",
}))
const InstructorTypo = styled(Typography)(({ theme }) => ({
  width: "266px",
  height: "143px",
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "12.96px",
  letterSpacing: "0.41111114621162415px",
  textAlign: "left",
  color: theme.palette.text.disabled
}))
const MainVideoCourseBox = styled(Box)(({ theme }) => ({
  width: "647px",
  height: "450px",
  left: "349px",
  borderRadius: "18px",
  backgroundColor: theme.palette.background.paper,
}))
const TableBox = styled(Typography)(({ theme }) => ({
  width: "647px",
  height: "310px",
  borderRadius: "18px",
  backgroundColor: theme.palette.background.paper,
}))
const NotesBox =styled(Box)(({ theme }) => ({
  width: "326px",
  height: "753px",
  borderRadius: "18px",
  backgroundColor: theme.palette.background.paper,
  padding:"28px 34px",
  display:"flex",
  flexDirection:"column",
  justifyContent:"space-between",
  alignItems:"center",
}))
const NavBar = styled(Box)(({ theme }) => ({
  width: "80%",
  height: "75px",
  padding: "15.79px 36.45px 15.79px 33.5px",
  borderRadius: "18px",
  border:"1px",
  background: theme.palette.background.paper,
  margin:"48px 26px",
}))
const MoreOptionsMenu = styled(Menu)(({ theme }) => ({
  width: "192px",
  height: "85px",
  padding: "4px 0px 0px 0px",
  gap: "2px",
  borderRadius: "8px",
  opacity: "0px",
  border: "1px solid #FFFFFF",
  boxShadow: "0px 2px 4px 0px #00000026",
  backgroundColor: theme.palette.background.default,
  top:"33% !important",
  left:"72% !important",
  "& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded":{
    maxWidth: "192px",
    maxHeight: "85px",
    width: "192px",
    height: "85px",
    top:"0px !important",
    left:"0px !important",
    transition: "none",
    transformOrigin: "none",
    boxShadow: " 0px 2px 4px 0px #00000026 !important",
    backgroundColor: `${theme.palette.background.default} !important`,
    borderRadius:"8px"
  }
}))
const AddNoteModal = styled(Modal)(({ theme }) => ({
  width: "832px",
  height: "480px",
  padding: "35px 34px 35px 34px",
  gap: "10px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"center",
  backgroundColor:theme.palette.background.default,
  border:`1px solid ${theme.palette.background.default}`,
  borderRadius:"18px",
  top:"25% !important",
  left:"22% !important",
}))
const SubmittedNoteModal = styled(Modal)(({ theme }) => ({
  width: "585px",
  height: "388px",
  gap: "24px",
  borderRadius: "30px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"center",
  backgroundColor:theme.palette.background.default,
  border:`1px solid ${theme.palette.background.default}`,
  top:"25% !important",
  left:"22% !important",
}))
const DeleteNoteModal = styled(Modal)(({ theme }) => ({
  width: "611px",
  height: "356px",
  padding: "48px",
  gap: "24px",
  borderRadius: "30px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"center",
  backgroundColor:theme.palette.background.default,
  border:`1px solid ${theme.palette.background.default}`,
  top:"25% !important",
  left:"22% !important",
}))

const ModalPaper = styled(Paper)(({ theme }) => ({
  width: "662px",
  height: "583px",
  padding: "38px 25px",
  gap: "10px",
  backgroundColor:theme.palette.background.default,
  border:`1px solid ${theme.palette.background.default}`,
  borderRadius:"18px",
}))
const SubmittedModalPaper = styled(Paper)(({ theme }) => ({
  width: "620px",
  height: "388px",
  gap: "24px",
  borderRadius: "30px",
  opacity: "0px",
  backgroundColor:theme.palette.background.default,
  border:`1px solid ${theme.palette.background.default}`,
}))
const DeleteModalPaper = styled(Paper)(({ theme }) => ({
  width: "611px",
  height: "356px",
  top: "290px",
  left: "414px",
  padding: "48px",
  gap: "24px",
  borderRadius: "30px",
  backgroundColor:theme.palette.background.default,
  border:`1px solid ${theme.palette.background.default}`,
}))
const CancelButton = styled(Button)(({ theme }) => ({
  width: "183px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  opacity: "0px",
  border: `1px solid ${theme.palette.action.active}`,
  textTransform:"capitalize",
  "& .MuiButton-label":{
    width: "55px",
  height: "24px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.action.active
  }
}))

const AddButton = styled(Button)(({ theme }) => ({
 width: "71px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "30px",
  textTransform:"capitalize",
   backgroundColor: "#1A469C",  
 "& .MuiButton-label":{
     width: "39px",
     height: "24px",
     fontFamily: "Rubik",
     fontSize: "16px",
     fontWeight: 700,
     lineHeight: "24px",
     textAlign: "left",
     color: "#F8FAFC"
  },
      ".MuiButton-root:hover": {
        textDecoration: "none",
        backgroundColor: "#1A469C", 
      }
}))
const EditTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  width: "67px",
  height: "24px",
  gap: "0px",
  opacity: "0px",
  color: "#1A469C"
}))
const DeleteTypo = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  width: "86px",
  height: "24px",
  gap: "0px",
  opacity: "0px",
  color: "#DC2626"
}))
const CourseTabSection = styled(Box)(({ theme }) => ({
  width: "226px",
  height: "36px",
  padding: "4px",
  borderRadius: "50px",
  justifyContent: "space-between",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  backgroundColor:theme.palette.background.default
}))
const AddNoteBox = styled(Box)(({ theme }) => ({
  width: "612px",
  height: "467px",
  gap: "21px",
  opacity: "0px",
  display:"flex",
  flexDirection:"column",
  alignItems:"flex-start",
  justifyContent:"flex-start"
}))
const AddNoteHeaderBox = styled(Box)(({ theme }) => ({
  width: "595px", height: "38px", gap: "12px",
  opacity: "0px",
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"center"
}))
const AddNoteHeaderTypo = styled(Typography)(({ theme }) => ({
  width: "150px",
  height: "38px",
  left: "23px",
  gap: "0px",
  opacity: "0px",
  fontFamily: "Rubik",
  fontSize: "32px",
  fontWeight: 500,
  lineHeight: "37.92px",
  textAlign: "center",
  color: theme.palette.secondary.dark
}))
const AddHeaderDivider = styled(Divider)(({ theme }) => ({
  width: "387px",
 height: "2px",
 gap: "0px",
 opacity: "0px",
 background: "#D7D7D780"
}))
const NoteTitleTypo = styled(Typography)(({ theme }) => ({
  width: "120px",
  height: "22px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "22px",
  textAlign: "left",
  color: theme.palette.text.primary
}))
const InputField = styled('input')(({ theme }) => ({
  width: "595px",
  height: "44px",
  padding: "10px 12px 10px 16px",
  gap: "8px",
  borderRadius: "8px",
  opacity: "0px",
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.info.dark}`,
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.text.hint,
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid #D7D7D7'
  },
  '& .MuiInput-underline:after': {
      borderBottom: '1px solid #D7D7D7'
  }
}))
const NavGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center"
}))
const SearchBar = styled(TextField)(({ theme }) => ({
  width: '257px',
  height: '44px',
  padding: '10px 16px',
  gap: '8px',
  borderRadius: '98px',
  border: `1px solid ${theme.palette.info.dark}`,
  backgroundColor: theme.palette.background.default,
  '& .MuiInputBase-root': {
    padding: 0,
    height: '100%',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputAdornment-root': {
    marginRight: '8px',
  },
}))
const NavLinkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "12px",
  alignItems: "center",
  justifyContent: "center"
}))
const NavLinkTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: theme.palette.text.secondary,
  "& .active":{
    borderBottom: `2px solid ${theme.palette.text.secondary}`,
    padding: "8px",
    gap: "10px",
    borderRadius: "0px 0px 8px 8px"
  }
}))
const NavLinkActiveTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "16px",
  textAlign: "left",
  color: theme.palette.action.selected,
  borderBottom: `2px solid ${theme.palette.action.selected}`,
  padding: "8px",
  gap: "10px",
  borderRadius: "0px 0px 8px 8px"
}))
const ProfileBox = styled(Box)(({ theme }) => ({
  display: "flex", flexDirection: "row", gap: "8px"
}))
const ProfileImageNameBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px"
}))
const BadgeContainer = styled(Badge)(({ theme }) => ({
  width: "44px",
  height: "44px",
  padding: "10px",
  gap: "4px",
  borderRadius: "8px",
  opacity: "0px",
}))
const NameBox = styled(Box)(({ theme }) => ({

}))
const NameTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.main
}))
const PositionTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "18px",
  textAlign: "left",
  color: theme.palette.info.light,
  textTransform:"capitalize",
}))
const SelectedFilter = styled(Box)(({ theme }) => ({
  width: "72.67px",
  height: "28px",
  padding: "6px 12px 6px 12px",
  gap: "6px",
  borderRadius: "50px",
  backgroundColor: theme.palette.action.focus,
  display:"flex",
  alignItems:"center"
}))
const SelectedFilterTypo = styled(Typography)(({ theme }) => ({
  width: "42px",
  height: "22px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  textAlign: "left",
  color: theme.palette.action.active
}))
const DeselectedFilter = styled(Box)(({ theme }) => ({
  width: "72.67px",
  height: "28px",
  padding: "6px 12px 6px 12px",
  gap: "6px",
  borderRadius: "50px",
  display:"flex",
  alignItems:"center",
}))
const DeselectedFilterTypo = styled(Typography)(({ theme }) => ({
  width: "40px",
  height: "22px",
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "22px",
  textAlign: "left",
  color: "#475569",
}))
const FontStyle = {
  fontFamily: 'Rubik',
  h1: { fontSize: '32px' },
  h2: { fontSize: '30px' },
  h3: { fontSize: '28px' },
  h4: { fontSize: '26px' },
  h5: { fontSize: '24px' },
  h6: { fontSize: '22px' },
  subtitle1: { fontSize: '20px' },
  subtitle2: { fontSize: '18px' },
  body1: { fontSize: '16px' },
  body2: { fontSize: '14px' }
}

export const lightTheme = createTheme({
  palette: {
      primary: {
          main: '#FFFFFF',
          light:"010101"
      },
      secondary: {
          main: '#FFFFFF',
          light:"#D1FAE5",
          dark:"#0F172A"
      },
      background: {
          default: '#FFFFFF',
          paper: 'rgba(238,238,238,1)'
      },
      text: {
          primary: '#334155',
          secondary: '#475569',
          hint: '#0F172A',
          disabled:"#475569",
      },
      action: {
          active: '#1A469C',
          selected: '#1A469C',
          focus: '#EDF1F6',
          disabled: '#475569',
          hover:"#475569"
        },
      info: {
          main: "#21272A",
          light: '#848484',
          dark: '#CBD5E1',
          contrastText:'#334155',
        },
  },
  typography: FontStyle
});
export const darkTheme = createTheme({
  palette: {
      primary: {
          main: '#161616',
          light:"#BDCFF4"
      },
      secondary: {
          main: '#2F2F2F',
          light:"#074525",
          dark:"#F2F2F4"
      },
      background: {
          default: '#0D0D0D',
          paper: 'rgba(26,26,26,1)',
      },
      text: {
          primary: '#909EB2',
          secondary: '#93A1B5',
          hint: '#D5DDF0',
          disabled:"#99A7BB",
        },
      action: {
          active: '#70A1FF',
          selected: '#12326F',
          focus: '#121B29',
          disabled: '#D0DBEB',
          hover:"#96A4B8"
      },
      info: {
          main: "#D5DDF0",
          light: '#99A7BB',
          dark: '#273444',
          contrastText:'#D5DDF0',
      },
  },
  typography: FontStyle
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

// Customizable Area End
