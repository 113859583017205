export const logoLight = require("../assets/logo.png");
export const logoDark = require("../assets/logo_dark.png");
export const toggleLightLogo = require("../assets/lightToggleLogo.svg");
export const toggleDarkLogo = require("../assets/darkToggleLogo.svg");
export const backgroundWelcome = require("../assets/background_grid.svg");
export const backgroundOvercome = require("../assets/background_overcome.svg");
export const darkBackgroundWelcome = require("../assets/darkBackground.svg");
export const darkBackgroundOvercome = require("../assets/darkBackgroundOvercome.svg");
export const welcomeImage = require("../assets/welcome_photo.svg");
export const overcomeImage = require("../assets/overcome_image.svg");
export const innovativeImage = require("../assets/innovative_image.svg");
export const leftCircleIcon = require("../assets/leftcircleicon.svg");
export const rightCircleIcon = require("../assets/rightcircleicon.svg");
export const accessIcon = require("../assets/access_image.svg");
export const aiImage = require("../assets/ai_image.svg");
export const anytimeImage = require("../assets/anytime_image.svg");
export const continueImage = require("../assets/continous_image.svg");
export const imporveIcon = require("../assets/improve_icon.svg");
export const petropalIcon = require("../assets/petrolpal_icon.png");
export const qrImage = require("../assets/qr_image.svg");
export const solutionImage = require("../assets/solution_image.svg");
export const upwardIcon = require("../assets/upward_arrow.svg");
export const cocentricImage = require("../assets/co-centric_image.svg");
export const aiDark = require("../assets/ai_dark_image.svg");
export const anytimeDark = require("../assets/realtime_dark_image.svg");
export const continueDark = require("../assets/continous_dark_image.svg");
export const imporveDark = require("../assets/improve_dark_image.svg");
export const qrDark = require("../assets/qr_dark_image.svg");
export const greenTick = require("../assets/greenTick.png");