import React from "react";

import {
  Box,
  // Customizable Area Start
  Breadcrumbs,
  Link as BreadcrumbLink,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Link } from "react-router-dom";
import { NavigateNext } from "@material-ui/icons";
// Customizable Area End

import BreadcrumbNavigationPageController, {
  Props,
} from "./BreadcrumbNavigationPageController.web";

export default class BreadcrumbNavigationPage extends BreadcrumbNavigationPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <MainBox>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNext fontSize="small" />}
          >
            {this.props.breadcrumbs.map((crumb: string, index: number) => (
              <BreadLink
                key={index}
                data-test-id="breadClick"
                className={index === this.props.breadcrumbs.length - 1 ? "inherit" : "textPrimary"}
                onClick={() => this.props.gotoPage(crumb)}
              >
                {crumb}
              </BreadLink>
            ))}
          </Breadcrumbs>
        </MainBox>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  fontFamily: "Rubik",
  flexDirection: "column",
  alignItems: "center",
  "& .textPrimary": {
    color: theme.palette.info.light,
    fontWeight: 400
  },
  "& a:hover": {
    color: theme.palette.info.main,
    textDecoration: 'none'
  }
}))

const BreadLink = styled(BreadcrumbLink)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: '22px',
  color: theme.palette.info.main,
}))
// Customizable Area End
