import React, { useState } from 'react';
import {
    Box,
    Button,
    createStyles,
    IconButton,
    makeStyles,
    Modal,
    Typography, styled
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import { ChevronLeft } from "@material-ui/icons";

const useStyles = makeStyles(theme =>
    createStyles({
        paper: {
            backgroundColor: theme.palette.background.default,
            borderRadius: "40px",
            textAlign: "center",
            maxWidth: '669px',
            padding: '30px 36px',
            width: '100%',
            "& h1": {
                fontSize: "30px",
                color: "#700010",
                marginBottom: "5px",
                paddingBottom: "10px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif"
            }
        },
        dialogContent: {
            "& .MuiDialog-paper": {
                maxWidth: "669px",
                width: '100%',
                borderRadius: "40px",
            },
            "& .MuiDialog-paperScrollPaper": {
                maxHeight: 'auto',
            }
        },
        root: {
            maxWidth: "669px",
            width: '100%',
            margin: "auto"
        },
        success: {
            width: "300px",
            margin: "auto"
        },
        successIcon: {
            fontSize: "50px",
            color: "#a60018",
            marginBottom: "25px"
        },
        successContent: {
            fontSize: "24.96px",
            fontWeight: 500,
            fontFamily: "Rubik",
            minWidth: "fit-content",
        },
        successbtn: {
            borderRadius: "30px",
            padding: " 10px 55px",
            textDecoration: "none",
            textTransform: "unset",
            color: "#F8FAFC",
            fontWeight: 700,
            backgroundColor: "#1A469C",
            boxShadow: 'none',
            maxWidth: 429,
            fontSize: 16,
            width: '100%',
            fontFamily: "Rubik",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#1A469C",
            }
        },
        cancelbtn: {
            border: "1px solid #1A469C",
            borderRadius: "30px",
            padding: " 10px 55px",
            textDecoration: "none",
            textTransform: "capitalize",
            color: "#1A469C",
            fontWeight: 500,
            fontSize: 16,
            fontFamily: "Rubik",
            backgroundColor: "#FFFFFF",
            cursor: "pointer",
            maxWidth: 154,
            width: '100%',
            "&:hover": {
                backgroundColor: "transparent",
            }
        },
        iconClose: {
            position: "relative",
            top: "0px",
            left: "46%",
            "& svg ": {
                color: "#1A469C",
                fontSize: "14px"
            }
        },
        midcontent: {
            fontWeight: 400,
            fontFamily: "Rubik",
            fontSize: "17.16px",
            lineHeight: "20.34px",
            minWidth: "fit-content",
        },
        description: {
            fontWeight: 400,
            fontFamily: "Rubik",
            fontSize: "26px",
            lineHeight: 1.1,
            textAlign: 'left',
            maxWidth: '400px',
        },
    })
);

const DropdownBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    zIndex: 9999,
    boxShadow: '0px 2px 4px 0px #00000026',
    top: 28.3,
    right: 20,
    borderRadius: 20,
    minWidth: 200,
    padding: 2,
    background: theme.palette.background.default,
}))

const DayList = styled(Typography)(({ theme, themeDark }: any) => ({
    padding: '13px 16px',
    cursor: 'pointer',
    color: theme.palette.info.light,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "Rubik",
    '&:hover': {
        background: themeDark ? "#121B29" : "#E8EDF5",
        color: themeDark ? "#70A1FF" : "#1A469C",
        borderRadius: 8
    }
}))

const SubText = styled(Typography)(({ theme }) => ({
    borderRadius: 8,
    border: '1px solid #CBD5E1',
    padding: '5px 15px',
    color: theme.palette.info.main,
    lineHeight: 1,
    fontWeight: 400,
    fontFamily: "Rubik",
    fontSize: 16,
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer',
    '& .day-dropbox p': {
        cursor: 'pointer'
    },
}))

const CustomLogoModalWeb = (props: any) => {

    const classes = useStyles() as any;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedLogo, setSelectedLogo] = useState("");


    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleSelectedLogo = (logo: string) => {
        setSelectedLogo(logo);
        setDropdownOpen(false);
    }

    console.log("props", props)
    return (
        <Dialog className={classes.dialogContent} onClose={props.handleClose} open={props.isModalOpen}>
            <div className={classes.paper}>
                <Box style={{ display: 'flex', gap: 10, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h2" className={classes.successContent} style={{ color: props.theme ? "#F2F2F4" : "#0F172A" }}>
                        {props.logotitle}
                    </Typography>
                    <Typography style={{ maxWidth: 154, width: '100%', borderBottom: '2px solid #D7D7D780' }}></Typography>
                    <Typography className={classes.midcontent} style={{ color: props.theme ? "#D5DDF0" : "#334155" }}>{props.midContent}</Typography>
                    <Typography style={{ maxWidth: 154, width: '100%', borderBottom: '2px solid #D7D7D780' }}></Typography>
                    <Box>
                        <IconButton
                            onClick={props.handleClose}
                            className={classes.iconClose}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                    </Box>
                </Box>
                <Box style={{ display: 'flex', gap: 38, alignItems: 'center', paddingBlock: 18 }}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Typography>Filter:</Typography>
                        <SubText
                            className="day-dropdown"
                            onClick={handleDropdownToggle}
                            data-test-id="DropdownOpen"
                            style={{ display: 'flex', alignItems: 'center', gap: 10, position: 'relative' }}
                        >
                            <span data-test-id="Dropdownopen">
                                <span style={{ marginLeft: '8px', color: '#0F172A' }}>
                                    {selectedLogo || 'Logo Type'}
                                </span>
                                <ChevronLeft
                                    style={{ transform: "rotate(-90deg)", width: 20, height: 16, color: "#1A469C" }}
                                />
                            </span>
                            {dropdownOpen && (
                                <DropdownBox className="day-dropbox">
                                    {['Small logo', 'Big logo'].map((logo) => (
                                        <DayList
                                            key={logo}
                                            data-test-id="selectDay"
                                            className="dayList"
                                            onClick={() => handleSelectedLogo(logo)}
                                        >
                                            {logo}
                                        </DayList>
                                    ))}
                                </DropdownBox>
                            )}
                        </SubText>

                    </Box>
                </Box>
                <Box style={{ display: 'flex', gap: 14, alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        className={classes.cancelbtn}
                        onClick={props.handleClose}
                    >
                        {props.cancelbtnTitle}
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.successbtn}
                        onClick={props.handleButton}
                    >
                        {props.btnTitle}
                    </Button>
                </Box>
            </div>
        </Dialog>
    );
};

export default CustomLogoModalWeb