import React from 'react';
// Customizable Area Start
import {
    IoChatbubblesSharp,
    IoCalendarClearOutline,
    IoBusinessSharp,
    IoPeople,
    IoSettingsSharp,
    IoChevronForward,IoLogOutOutline
  } from 'react-icons/io5';
  import { MdWork } from 'react-icons/md';
import HamburgerMenuController, {colors, configJSON} from "./HamburgerMenuController.web";
import { Typography,ListItemIcon,ListItem,Divider,Drawer,IconButton,Box,styled,Switch} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  imgSmallLogo, settingImg, logoutImg, KeyImg, notificationImg, chatImg, paintImg, Img, fullLogo, darkLogo,activeLibraryIcon,bulletinIcon,activebulletinIcon,
  rememberIcon, OverviewIcon, coursesIcon, certificatesIcon, bulletinBoard, settingsIcon, activeMsg, activeProfile, activeSecurity, activeAppearnace, activenotificationImg, modalLogoutImg,activeCoursesIcon,
  teamMemberImg,FeedbackImg,stu_EducatorProgressImg,activestu_EducatorImg, activeTeamMemberImg, activeBulletinBoard,activeCertificatesIcon,activeFeedbackImg,activeRememberIcon,lightMyTaskIcon,lightAllocateTaskIcon,
  TaskListIcon,mytaskIcon,allocateTaskIcon,activeReminderIcon,activemyTaskIcon,activeAllocateTaskIcon,activeLogoutImg,LibraryIcon,activeOverviewIcon,lightDashboardIcon,lightFeedbackIcon,lightMsgIcon,lightAppearanceIcon,
  lightBulletinIcon,lightCourseIcon,lightCertificatesIcon,lightDocumentIcon,lightLibraryIcon,lightProgressBarIcon,lightLogoutIcon,lightReminderIcon,lightTeamMembersIcon,lightSettingsIcon,lightProfileIcon,lightNotificationIcon,lightSecurityIcon,
}
  from './assets'
import CustomModalWeb from '../../../components/src/CustomModal.web';

const drawerWidth = 240;


const ListParentBox = styled(Box)(({ themeDark, open }: any) => ({
  '& .MuiListItem-button': {
    width: 'unset',
    borderRadius: 30,
    transition: 'all 200ms linear',
    '&:hover':{
    paddingLeft: open,
    background: themeDark ? "#121B29" : "#EDF1F6",
    '& .list-text': {
      color:"#1A469C"
    }
    }
  },
  '& .MuiListItem-button.active': {
    borderRadius: 30,
    background: themeDark ? "#121B29" : "#EDF1F6",
    paddingLeft: open,
    '& .list-text':{
      color:"#1A469C !important"
    }
  },
  '& .MuiListItem-button.newactive': {
    borderLeft: '3px solid #1A469C',
    borderRadius: 10,
    color:"#1A469C",
    '&:hover': {
      paddingLeft: '15px !important',
      background:  'transparent',
    },
    '& .list-text': {
      color:"#1A469C !important"
    }
  },
  '& .list-text': {
    color: themeDark ? "#D0DBEB": "#475569",fontWeight:500,fontSize:16,fontFamily: "Rubik",
  },
  '& .MuiListItem-button.highlightedSettings': {
    borderLeft: '3px solid #1A469C',
    borderRadius: 10,
    color:"#1A469C",
    '&:hover': {
      paddingLeft: '15px !important',
      background:  'transparent',
      cursor: 'default'
    },
    '& .list-text': {
      color:"#1A469C !important"
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    }
  },
}))

const classes = 
    {
      root: {
        display: 'flex',
      },
      appBar: {
        zIndex: 1200 + 1,
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
      },
      menuButton: {
      },
      hide: {
        display: 'none',
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
      },
      drawerOpen: {
        width: drawerWidth,
      },
      drawerClose: {
        overflowX: 'hidden',
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 30,
        padding: '20px 0 10px',
        flexDirection: 'column'
      },
      content: {
        flexGrow: 1,
      },
    } as any;

const drawerItems = [
  { icon: settingImg,themeIcon: settingImg, name: 'Settings', routeName: '', routes: "", route: "settings",activeIcon: settingImg},
  { icon: Img, activeIcon: activeProfile,themeIcon: lightProfileIcon, name: 'My Profile', routeName: "goToCustomizableUserProfileScreen", routes: "/CustomisableUserProfiles", route: "CustomisableUserProfiles" },
  { icon: KeyImg, activeIcon: activeSecurity,themeIcon: lightSecurityIcon, name: 'Security', routeName: "gotoSecurity", routes: "/Settings2", route: "security" },
  { icon: notificationImg, activeIcon: activenotificationImg,themeIcon: lightNotificationIcon, name: 'Notification', routeName: "goToNotificationSettings", routes: "/notificationsetting", route: "notification" },
  { icon: chatImg, activeIcon: activeMsg,themeIcon: lightMsgIcon, name: 'Messages', routeName: "gotoMessage", routes: "/Settings2?route=message", route: "message" },
  { icon: paintImg, activeIcon: activeAppearnace,themeIcon: lightAppearanceIcon, name: 'Appearance', routeName: "gotoAppearance", routes: "/Settings2?route=appearance", route: "appearance" },
];

const StudentdrawerItems = [
  { icon: OverviewIcon, name: 'Dashboard',themeIcon: lightDashboardIcon,routeName:"goToDashboardScreen",activeIcon: activeOverviewIcon,route: "Dashboard",routes:"/Dashboard"},
  { icon: coursesIcon, name: 'My Courses', activeIcon: activeCoursesIcon,themeIcon: lightCourseIcon},
  { icon: bulletinIcon, name: 'Bulletin Board', routeName:'goToBulletinBoardPage',route: "BulletinBoard",activeIcon: activebulletinIcon,themeIcon: lightBulletinIcon,routes:"/BulletinBoard"},
  { icon: bulletinBoard, name: 'Company Documents', activeIcon: activeBulletinBoard,themeIcon: lightDocumentIcon},
  { icon: certificatesIcon, name: 'Certificates',  routeName:'goToCertificateTrackingPage', activeIcon: activeCertificatesIcon,themeIcon: lightCertificatesIcon,route: "CertificationTracking",routes:"/CertificationTracking"},
  { icon: FeedbackImg, name: 'Support', activeIcon: activeFeedbackImg,themeIcon: lightFeedbackIcon},
  { icon: rememberIcon, name: 'Reminder',activeIcon:activeReminderIcon,themeIcon: lightReminderIcon},
];

const EducatordrawerItems = [
  { icon: OverviewIcon, name: 'Dashboard',themeIcon: lightDashboardIcon,routeName:"goToDashboardScreen",activeIcon: activeOverviewIcon,route: "Dashboard",routes:"/Dashboard"},
  { icon: coursesIcon, name: 'Courses',activeIcon: activeCoursesIcon,themeIcon: lightCourseIcon},
  { icon: LibraryIcon, name: 'My Library', activeIcon: activeLibraryIcon,themeIcon: lightLibraryIcon},
  { icon: stu_EducatorProgressImg, name: 'Worker Progress',activeIcon: activestu_EducatorImg,themeIcon: lightProgressBarIcon,routeName:'goToWorkerProgressPage',route: "PerformanceTracker",routes:"/PerformanceTracker" },
  { icon: bulletinIcon, name: 'Bulletin Board',routeName: 'goToBulletinBoardPage',activeIcon: activebulletinIcon ,themeIcon: lightBulletinIcon,routes:"/BulletinBoard",route: "BulletinBoard"},
  { icon: bulletinBoard, name: 'Document Storage', activeIcon: activeBulletinBoard,themeIcon: lightDocumentIcon},
  { icon: rememberIcon, name: 'Reminder', activeIcon: activeRememberIcon,themeIcon: lightReminderIcon},
];

const ManagerdrawerItems = [
  { icon: OverviewIcon, name: 'Dashboard',themeIcon: lightDashboardIcon,routeName:"goToDashboardScreen",activeIcon: activeOverviewIcon,route: "Dashboard",routes:"/Dashboard"},
  { icon: teamMemberImg, name: 'Team Members', activeIcon: activeTeamMemberImg,themeIcon: lightTeamMembersIcon,route: "Teammembers", routes:"/TeamMembers", routeName:'goToTeamMemberScreen'},
  { icon: stu_EducatorProgressImg, name: 'Worker Progress',activeIcon: activestu_EducatorImg ,themeIcon: lightProgressBarIcon,routeName:'goToWorkerProgressPage',route: "PerformanceTracker",routes:"/PerformanceTracker"},
  { icon: bulletinIcon, name: 'Bulletin Board',routeName: 'goToBulletinBoardPage',activeIcon: activebulletinIcon,themeIcon: lightBulletinIcon,routes:"/BulletinBoard",route: "Bulletinboard"},
  { icon: bulletinBoard, name: 'Company Documents', activeIcon: activeBulletinBoard,themeIcon: lightDocumentIcon},
  { icon: FeedbackImg, name: 'Support', activeIcon: activeFeedbackImg,themeIcon: lightFeedbackIcon},
  { icon: rememberIcon, name: 'Reminder',activeIcon: activeRememberIcon,themeIcon: lightReminderIcon},
];


const ButtonDiv = styled('div')({
  '& .MuiIconButton-edgeStart': {
    marginLeft: 'unset'
  },
  '& button': {
    padding: 0
  }
})
const CustomIconButton = styled(IconButton)({
  '& .MuiTouchRipple-root': {
    display: 'none'
  },
})
const BottomNavitemBox = styled(Box)({
  '& .bottom-navitem:hover .MuiTypography-root': {
    color: '#1A469C !important' 
  },
  '& .setting-second': {
    display: 'none'
  },
  '& .bottom-navitem:hover .setting-second': {
    display: 'block !important',
  },
  '& .bottom-navitem:hover .setting-first': {
    display: 'none !important'
  },
})
const SwitchTheme = styled(Box)({
  "& .MuiSwitch-thumb": {
    backgroundColor: 'white',
    height: 16,
    width: 16,
    position: 'relative',
    top: 4,
    left: 4,
    boxShadow: 'none'
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    left: 'unset'
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: '#18408E  !important',
    opacity: 1
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#E2E8F0',
    opacity: 1,
    height: '18px',
    borderRadius: '15px',
  },
  '& .MuiSwitch-root': {
    height: 42,
    width: 46,
    padding: '12px 0px 12px 12px'
  }
})

// Customizable Area End


export default class HamburgerMenu extends HamburgerMenuController {

 
  // Customizable Area Start
  //removed makeactive and added to controller
  handleMarginLeft=(open: boolean)=>{
    if(open){
      return 0;
    }
    return 18 
  }
  handleMarginRight=(open: boolean)=>{
    if(open){
      return 0;
    }
    return 45 
  }
  handleMargin =(open:boolean)=>{
    if(open){
      return 14;
    }
    return 31
  }
   
  handleLogoutMargin =(open:boolean)=>{
    if(open){
      return 10;
    }
    return 45
  }

  handleThemePart =(themeDark?:boolean) =>{
    if(themeDark){
      return '#121B29';
    }
      return '#EDF1F6'
  }

  handleOpenTheme =(open?:boolean) =>{
    if(open){
      return '40px !important'
    } 
      return  '15px !important'
  }

  handleBackground=(themeDark?: boolean) =>{
    if(themeDark){
      return "rgb(18, 18, 18)"
    }
    return  "#F9F9F9"
  }
  
  handlePartionColor =(themeDark?: boolean) =>{
    if(themeDark){
      return "rgba(215,215,215,20)" 
    }
    return  "rgba(215,215,215,50)"
  }

  handleLogo =(themeDark?: boolean) =>{
    if(themeDark){
      return darkLogo
    }
    return fullLogo
  }

  handleColor =(themeDark?: boolean) =>{
    if(themeDark){
      return '#93A1B5'
    }
    return "black"
  }
  
  handleIconColor =(themeDark?: boolean) =>{
    if(themeDark){
      return 'black'
    }
    return "white"
  }

  handleColorText =(themeDark?: boolean) =>{
    if(themeDark){
      return "#D0DBEB"
    }
    return "#475569"
  }

  handleLogoutImage = (themeDark?: boolean) => {
    if(themeDark){
      return lightLogoutIcon
    }
    return logoutImg
  }

  handleSettingsImage = (themeDark?: boolean) => {
    if(themeDark){
      return lightSettingsIcon
    }
    return settingsIcon
  }

  handleDrawerWidth =(open?: boolean) =>{
    if(open){
      return drawerWidth
    } 
      return  120
  }

  getIconSource = (item:any,themeDark: boolean | undefined) => {
    const { activePath } = this.state;
    const currentPath = window.location.pathname;
    if (activePath === "") {
      return item.routes === currentPath ? item.activeIcon : item.icon;
    } else {
      if(activePath === item.route||this.state.isHovered==item.name){
        return item.activeIcon
      }else {
        if(themeDark){
          return item.themeIcon;
        }else{
          return item.icon;
        } 
      } 
    }
  };

  handleDrawerItems =()=>{
    return(
      drawerItems.map((item, index) => (
        <ListItem
          data-testid={`hover-item-${index}`}
          onMouseEnter={() => this.setState({ isHovered: item.name })}
          onMouseLeave={() => this.setState({ isHovered: "" })}
          className={`${this.state.activePath === item.route ? "active" : ""} ${item.name === "Settings" ? "highlightedSettings" : ""}`}
          button key={index} style={{ height: 44, marginBottom: 14, paddingInline: 15, marginInline: 15 }} data-test-id="drawerItem" onClick={() => this.handleNavigate(item.routeName, item.route)}>
          <ListItemIcon style={{ minWidth: 38 }}><img  src={this.getIconSource(item,this.props.themeDark)} style={{ width: 28, height: 28, marginLeft: this.handleMarginLeft(this.props.open), marginRight: this.handleMarginRight(this.props.open)}} /></ListItemIcon>
          <Typography className='list-text' >
            {item.name}
          </Typography>
        </ListItem>
      ))
    )
  }

  handleWorkerDrawerItems=()=>{
    return(
      StudentdrawerItems.map((item, index) => (
        <ListItem 
        onMouseEnter={() => this.setState({ isHovered: item.name })}
        onMouseLeave={() => this.setState({ isHovered: "" })}
        className={this.state.activePath === item.route ? "newactive" : ""}
        button key={index} data-test-id="studentdrawerItem" style={{  marginBottom: 8, height: 44, marginLeft: 15, paddingInline: 15}} onClick={() => this.handleNavigate(item.routeName, item.route)}
        >
          <ListItemIcon style={{ minWidth: 38 }}><img style={{ marginLeft: this.handleMarginLeft(this.props.open), width: 28, height: 28, marginRight: this.handleMarginRight(this.props.open)}} src={this.getIconSource(item,this.props.themeDark)} /></ListItemIcon>
          <Typography className='list-text' >
            {item.name}
          </Typography>
        </ListItem>
      ))
    )
  }

  handleManagerDrawerItems=()=>{
    return(
      ManagerdrawerItems.map((value, index) => (
        <ListItem button 
        style={{ height: 44, marginInline: 15, marginBottom: 14, paddingInline: 15 }} key={index} 
        onMouseEnter={() => this.setState({ isHovered: value.name })}
        className={this.state.activePath === value.route ? "newactive" : ""}
        onMouseLeave={() => this.setState({ isHovered: "" })}
        data-test-id="managerdrawerItem"
        onClick={() => this.handleNavigate(value.routeName, value.route)}
        >
          <ListItemIcon style={{ minWidth: 38 }}><img src={this.getIconSource(value,this.props.themeDark)} style={{ width: 28, marginLeft: this.handleMarginLeft(this.props.open), height: 28, marginRight: this.handleMarginRight(this.props.open)}} /></ListItemIcon>
          <Typography className='list-text' >
            {value.name}
          </Typography>
        </ListItem>
      ))
    )
  }

  handleInstructorDrawerItems=()=>{
    return(
      EducatordrawerItems.map((list, index) => (
        <ListItem button key={index} 
        style={{ height: 44, marginBottom: 14, paddingInline: 15, marginInline: 15 }} 
        data-test-id="educatordrawerItem"
        className={this.state.activePath === list.route ? "newactive" : ""}
        onClick={() => this.handleNavigate(list.routeName, list.route)}
        onMouseEnter={() => this.setState({ isHovered: list.name })}
        onMouseLeave={() => this.setState({ isHovered: "" })}
        >
          <ListItemIcon style={{ minWidth: 38 }}><img src={this.getIconSource(list,this.props.themeDark)} style={{ height: 28, marginLeft: this.handleMarginLeft(this.props.open), width: 28, marginRight: this.handleMarginRight(this.props.open)}} /></ListItemIcon>
          <Typography className='list-text' >
            {list.name}
          </Typography>
        </ListItem>
      ))
    )
  }

  handleToggleIcon=(open: boolean, themeDark: boolean | undefined)=>{
    return(
      open ? 
        (<div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", margin: "0px 16px" }}>
          <Typography style={{ color: this.handleColorText(themeDark), fontWeight: 500, fontSize: 16, fontFamily: "Rubik", marginLeft: this.handleLogoutMargin(this.props.open) }}>Dark Theme</Typography>
              <Switch
                onChange={this.props.handleTheme}
                checked={this.props.themeDark}
                data-test-id="toggle-field"
             />
        </div>):
              <Switch
                onChange={this.props.handleTheme}
                checked={this.props.themeDark}
                data-test-id="toggle-field"
              />
    )
  }
  
  // Customizable Area End

  render() {

    const {
      logOutBlockInnerStyle,
      logOutBlockContainorStyle,
      logOutBlock,
    } = this.props;
    
    return (
      // Customizable Area Start
      
      <div style={{ ...classes.root }}>
         <CustomModalWeb
                isModalOpen={this.state.isModalOpen}
                handleClose={this.handleClose}
                title="Logging out"
                description="Are you sure you want to log out of your account ?"
                btnTitle="Yes, Log out"
                cancelbtnTitle="Cancel"
                handleButton={this.handleButton}
                statusImage={modalLogoutImg} 
                theme={this.props.themeDark}
            />
         <Drawer
          variant="permanent"
          PaperProps={{
            style: {
              borderRadius:"0 18px 18px 0",
              width: this.handleDrawerWidth(this.props.open),
              transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
              overflowX: 'hidden',
              backgroundColor: this.handleBackground(this.props.themeDark),
              color: this.handleBackground(this.props.themeDark),
            },
          }}
        >
          <ButtonDiv 
            style={{
              ...classes.toolbar,
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              style={{
                ...classes.menuButton
              }}
              onClick={this.goToDashboardScreen}
              data-test-id="dashboardTest"
            >
              {this.props.open ? <img src={this.handleLogo(this.props.themeDark)} style={{width: 187}}/> : <img src={imgSmallLogo} style={{width: 50}}/>}
              
            </IconButton>
            <CustomIconButton  className="close_text" style={{color: this.handleColor(this.props.themeDark), marginLeft: 'auto', marginRight: 24,padding: 8,
             background: this.handleIconColor(this.props.themeDark)}}>
              {this.props.open ? <ChevronLeftIcon  onClick={this.props.handleMenu}/> : <ChevronRightIcon data-test-id="openMenu" onClick={this.props.handleMenu}/>}
            </CustomIconButton>
          </ButtonDiv>
          <Box style={{minHeight: 'calc(-135px + 100vh)', display: 'flex', justifyContent: 'space-between', flexDirection: 'column'
          }}>
            <ListParentBox themeDark={this.props.themeDark} open={this.handleOpenTheme(this.props.open)}>
              {this.state.toggleDrawer === "mainDrawer" ? (
                <>
                  {this.state.getRole === "worker" && (
                    <>
                      {this.handleWorkerDrawerItems()}
                    </>
                  )}
                  {this.state.getRole === "instructor" && (
                    <>
                      {this.handleInstructorDrawerItems()}
                    </>
                  )}
                  {this.state.getRole === "supervisor" && (
                    <>
                     {this.handleManagerDrawerItems()}
                    </>
                  )}
                </>
              )
                :
               this.handleDrawerItems()
              }

            </ListParentBox>
            <BottomNavitemBox>
              {this.state.toggleDrawer === "mainDrawer" ?
                <ListItem className='bottom-navitem' style={{ height: 44, display: "flex", alignItems: 'center' }}>
                  <img src={this.handleSettingsImage(this.props.themeDark)}  onClick={() => this.handleSettingsPart()} className="settingIcon_btn setting-first" style={{ width: 28, height: 28, marginLeft: this.handleMargin(this.props.open),cursor: "pointer"}} />
                  <img src={settingImg} className="settingIcon_btn setting-second" onClick={() => this.handleSettingsPart()} style={{ width: 28, height: 28, marginLeft: this.handleMargin(this.props.open),cursor: "pointer"}} />
                  <Typography className="logout_button" style={{ fontWeight: 500, fontSize: 16, color: this.handleColorText(this.props.themeDark), fontFamily: "Rubik", marginLeft: this.handleLogoutMargin(this.props.open), cursor: "pointer" }} onClick={() => this.handleSettingsPart()}>
                    Settings
                  </Typography>
                </ListItem> : ""
              }

              <ListItem className='bottom-navitem' style={{ height: 44, marginBottom: 14, display: "flex", alignItems: 'center' }}>
                <img src={this.handleLogoutImage(this.props.themeDark)} onClick={this.handleLogout} className="settingIcon_btn setting-first" style={{ width: 28, height: 28, cursor:"pointer",marginLeft: this.handleMargin(this.props.open) }} />
                <img src={activeLogoutImg} className="settingIcon_btn setting-second" onClick={this.handleLogout} style={{ width: 28, height: 28, cursor:"pointer",marginLeft: this.handleMargin(this.props.open) }} />
                <Typography className="logout_button" style={{ color: this.handleColorText(this.props.themeDark), fontWeight: 500, fontSize: 16, fontFamily: "Rubik", marginLeft: this.handleLogoutMargin(this.props.open), cursor: "pointer" }} onClick={this.handleLogout}>
                  Logout
                </Typography>
              </ListItem>
              <Divider style={{ width: '90%', margin: 'auto', backgroundColor: this.handlePartionColor(this.props.themeDark) }} />
              <SwitchTheme style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', height: 38, marginLeft: this.handleMargin(this.props.open) }}>
                {this.handleToggleIcon(this.props.open,this.props.themeDark)}
              </SwitchTheme>
            </BottomNavitemBox>
          </Box>
        </Drawer>

      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
