import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  TextField,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { styled, ThemeProvider } from "@material-ui/core/styles";
import { Select } from "antd";
import {
  EditButtonSection,
  EditHeading,
  EditDescription,
  MainContainer,
  FieldModal,
  FormDiv,
} from "../../customisableuserprofiles/src/CustomisableUserProfiles.web";
import {
  editIcon,
  alarmImage,
} from "../../customisableuserprofiles/src/assets";
import { mailIcon } from "../../email-account-registration/src/assets";
import { Pagination } from "@material-ui/lab";
import Loader from "../../../components/src/Loader.web";
import { order, search, AddWhite } from "./assets";
import { ToastContainer } from "react-toastify";
import {
  BackButton,
  NextButton,
  lightTheme,
} from "../../email-account-registration/src/EmailAccountRegistration.web";
// Customizable Area End

import UserProfileBasicController, {
    Props,
} from "./UserProfileBasicController";

// Customizable Area Start
const EditButtons = styled(Box)({
  marginLeft: "15px",
  width: "325px",
  display: "flex",
  "& .MuiButton-root:hover": {
    backgroundColor: "#1A469C",
    color: "#FFF",
  },
});

const EditProfileButton = styled(Button)({
  width: "140px",
  height: "44px",
  padding: "10px 16px",
  gap: "8px",
  borderRadius: "30px",
  border: "1px solid #1A469C",
  textTransform: "capitalize",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  color: "#1A469C",
});

const AssignProfileButton = styled(Button)({
  marginLeft: "10px",
  minWidth: "180px",
  height: "44px",
  padding: "10px 16px",
  gap: "8px",
  borderRadius: "30px",
  background: "#1A469C",
  color: "#FFF",
  textTransform: "capitalize",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textAlign: "left",
});

export const FilterSelect = styled(Select)(({ theme }) => ({
  marginLeft: "6px",
  width: "auto",
  maxWidth: "130px",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  color: theme.palette.info.main
}));

export const FilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "10px",
  "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
    borderRadius: "30px",
    height: "43px",
    paddingTop: "5px",
    background: theme.palette.background.default,
    border: "none",
    minWidth: "auto"
  },
  "& .MuiInput-underline::before": {
    borderBottom: "transparent",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
    borderBottom: "transparent",
  },
  "& .MuiInput-underline::after": {
    borderBottom: "transparent",
  },
  "& .ant-select-arrow": {
    color: theme.palette.info.main
  },
  "& .selectedOption": {
    background: '#1A469C !important',
    color: '#F8FAFC !important'
  },
  "& .selectFilter .ant-select-selector": {
    background: `${theme.palette.background.paper} !important`,
    color: `${theme.palette.info.contrastText} !important`
  }
}));

const SearchBox = styled(TextField)({
  minWidth: "230px",
  height: "44px",
  padding: "8px",
  borderRadius: "18px",
  border: "1px solid  #CBD5E1",
  backgroundColor: "#FFF",
  fontFamily: "Rubik",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
});

export const WorkerGrid = styled(Grid)(({ theme }) => ({
  padding: "5px 16px",
  background: theme.palette.background.default,
  borderRadius: "18px",
  border: `0.5 solid ${theme.palette.text.hint}`,
  "& .MuiCircularProgress-colorPrimary": {
    color: theme.palette.info.contrastText
  }
}));

export const GridTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "12px",
  lineHeight: "18px",
  fontWeight: 400,
  color: theme.palette.info.light
}));

export const GridValue = styled(Typography)(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: 700,
  wordBreak: "break-all",
  overflowX: "auto",
  color: theme.palette.info.main
}));

const ClearAllText = styled("span")({
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#1A469C",
  cursor: "pointer",
  marginLeft: "5px",
});

const InnerContainer = styled("div")({
  background: "white",
  width: "100%",
  maxWidth: "1070px",
  alignSelf: "center",
  borderRadius: "0px 0px 80px 0px",
  maxHeight: "678px",
  padding: "0px 20px 20px 0px",
  "& .MuiPaginationItem-page.Mui-selected": {
    color: "#FFF",
    background: "#1A469C",
  },
  "& .MuiPaginationItem-root": {
    background: "#F1F5F9",
    fontFamily: "Rubik",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "18px",
  },
});

const ViewOptions = [
  { value: "just_added", label: "Just Added" },
  { value: "one_month_ago", label: "1 Month Ago" },
  { value: "two_month_ago", label: "2 Month Ago" },
  { value: "three_month_ago", label: "3 Month Ago" },
  { value: "four_month_ago", label: "4 Month Ago" },
];

const roleOptions = [
  { value: "student", label: "Student" },
  { value: "educator", label: "Educator" },
];

const sortOptions = [
  { value: "asc", label: "Ascending" },
  { value: "desc", label: "Descending" },
  { value: "role_id", label: "SORT PER ROLE" },
];
// Customizable Area End

export default class UserProfileBasicBlock extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const open = Boolean(this.state.anchorEl);
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={lightTheme}>
        <Loader loading={this.state.loading} />
        <EditButtonSection
          style={{
            width: "100%",
            maxWidth: "1070px",
            alignSelf: "center",
            padding: "30px",
            background: "#f9f9f9",
          }}
        >
          <div>
            <EditHeading>Profiles</EditHeading>
            <EditDescription>
              Manage user profiles for workers and managers. Edit their details,
              assign them to companies, and organize them into groups.
            </EditDescription>
          </div>
          <EditButtons>
            <EditProfileButton
              data-test-id={"editProfileButton"}
              variant="outlined"
              onClick={this.enableEditAssignProfile}
            >
              Edit Profile
            </EditProfileButton>
            <AssignProfileButton
              data-test-id="openAssignProfileModal"
              startIcon={<img src={AddWhite} />}
              onClick={this.openAssignProfileModal}
            >
              Assign Profile
            </AssignProfileButton>
          </EditButtons>
        </EditButtonSection>
        <div
          style={{
            background: "#f9f9f9",
            width: "100%",
            maxWidth: "1070px",
            alignSelf: "center",
            borderRadius: "8px 8px 50px 8px",
            maxHeight: "698px",
            padding: "0px 3px 3px 0px",
          }}
        >
          <InnerContainer>
            <MainContainer
              style={{
                background: "#f9f9f9",
                borderRadius: "8px 8px 60px 8px",
                flexDirection: "column",
                padding: "20px",
              }}
            >
              <FilterContainer>
                <div>
                  <span
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      marginRight: "5px",
                    }}
                  >
                    Filter by:
                  </span>
                  <FilterSelect
                    data-test-id="view"
                    value={this.state.filterView || undefined}
                    onChange={(event) => this.changeView(event)}
                    placeholder="View"
                    dropdownMatchSelectWidth={false}
                  >
                    {ViewOptions?.map((item) => (
                      <Select.Option value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </FilterSelect>
                  <FilterSelect
                    data-test-id="role"
                    value={this.state.filterRole || undefined}
                    onChange={(event) => this.changeRole(event)}
                    placeholder="Role"
                    dropdownMatchSelectWidth={false}
                  >
                    {roleOptions?.map((item) => (
                      <Select.Option value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </FilterSelect>
                  <FilterSelect
                    data-test-id="company"
                    value={this.state.filterCompany || undefined}
                    onChange={(event) => this.changeCompanyName(event)}
                    placeholder="Company"
                    dropdownMatchSelectWidth={false}
                  >
                    {this.state.allCompanyName?.map((item) => (
                      <Select.Option value={item.company_name}>
                        {item.company_name}
                      </Select.Option>
                    ))}
                  </FilterSelect>
                  <FilterSelect
                    data-test-id="group"
                    value={this.state.filterGroup || undefined}
                    onChange={(event) => this.changeGroupName(event)}
                    placeholder="Group"
                    dropdownMatchSelectWidth={false}
                  >
                    {this.state.allGroupName?.map((item) => (
                      <Select.Option value={item.group_name}>
                        {item.group_name}
                      </Select.Option>
                    ))}
                  </FilterSelect>
                  <ClearAllText
                    data-test-id="clearAll"
                    onClick={this.clearFilter}
                  >
                    Clear All
                  </ClearAllText>
                </div>
                <div>
                  <SearchBox
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={search} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search"
                  />
                  <img
                    src={order}
                    alt="sort"
                    style={{ cursor: "pointer" }}
                    data-test-id="openMenu"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={this.openSortMenu}
                  />
                  <Menu
                    data-test-id="closeSortMenu"
                    id="basic-menu"
                    anchorEl={this.state.anchorEl}
                    open={open}
                    onClose={this.closeSortMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    style={{ top: "40px" }}
                  >
                    {sortOptions.map((item) => (
                      <MenuItem data-test-id="removeImage">
                        <span
                          data-test-id="sortEmployee"
                          style={{
                            color: "#1A469C",
                            fontFamily: "Rubik",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "22px",
                          }}
                          onClick={() => this.sortEmployee(item.value)}
                        >
                          {item.label}
                        </span>
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </FilterContainer>
              {this.state.allWorkers?.map((element) => (
                <WorkerGrid container spacing={1}>
                  <Grid item xs={"auto"} style={{ marginRight: "15px" }}>
                    <GridTitle>ID</GridTitle>
                    <GridValue>{element?.attributes?.account_id}</GridValue>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={element.attributes?.image?.toString()}
                        style={{
                          width: "34px",
                          height: "34px",
                          marginRight: "5px",
                          marginTop: "5px",
                          borderRadius: "50%",
                        }}
                      />
                      <div>
                        <GridTitle>Preferred Name</GridTitle>
                        <GridValue>
                          {element.attributes?.preferred_name}
                        </GridValue>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    <GridTitle>Role</GridTitle>
                    <GridValue>{element.attributes?.role_id}</GridValue>
                  </Grid>
                  <Grid item xs={2}>
                    <GridTitle>Company Name</GridTitle>
                    <GridValue>{element.attributes?.company_name}</GridValue>
                  </Grid>
                  <Grid item xs={2}>
                    <GridTitle>Group Name</GridTitle>
                    <GridValue>{element.attributes?.group_name}</GridValue>
                  </Grid>
                  <Grid item xs={3}>
                    <GridTitle>Email</GridTitle>
                    <GridValue>{element.attributes?.email}</GridValue>
                  </Grid>
                  <Grid item xs={"auto"}>
                    <img
                      data-test-id="editSection"
                      src={editIcon}
                      onClick={() =>
                        this.openEmployeeDetailModal(element.attributes)
                      }
                    />
                  </Grid>
                </WorkerGrid>
              ))}
            </MainContainer>
            <Pagination
              data-test-id="changePageNumber"
              count={this.state.totalPage}
              page={this.state.currentPage}
              onChange={this.changePageNumber}
            />
          </InnerContainer>
        </div>
        <FieldModal
          data-test-id="assignModal"
          open={this.state.assignRoleModal}
          onCancel={this.closeAssignRoleModal}
          title={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "28px",
                  fontWeight: 500,
                  fontFamily: "Rubik",
                  textAlign: "center",
                }}
              >
                {this.state.enableEditAssignProfile ? "Edit " : "Add "} Profile
              </h1>
              <hr
                style={{
                  width: "150px",
                  height: "2px",
                  color: "rgba(215, 215, 215, 0.5)",
                }}
              />
              <span
                style={{
                  fontSize: "22px",
                  fontWeight: 400,
                  fontFamily: "Rubik",
                }}
              >
                {this.state.enableEditAssignProfile ? "Search " : "Add Worker"}
              </span>
              <hr
                style={{
                  width: "150px",
                  height: "2px",
                  color: "rgba(215, 215, 215, 0.5)",
                }}
              />
            </div>
          }
          centered
          closable={false}
          footer={null}
          destroyOnClose
          width={766}
          style={{ height: "auto" }}
        >
          <TextField
            data-test-id="email"
            label="Email"
            value={this.state.email}
            onChange={this.changeEmail}
            type="text"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={mailIcon} />
                </InputAdornment>
              ),
            }}
            onKeyPress={this.searchByEmail}
            placeholder="Enter Worker Email"
            helperText={this.state.emailErrorMsg}
          />
          {this.state.searchedProfile?.email && (
            <div>
              <Grid
                container
                spacing={1}
                className="employeeDetail"
                style={{ minWidth: "auto", margin: "10px" }}
              >
                <Grid item xs={"auto"} className="profileEmployeeAlign">
                  <img
                    src={this.state.searchedProfile?.image?.toString()}
                    className="profileAvatar"
                  />
                </Grid>
                <Grid item xs={2} className="profileEmployeeAlign">
                  <span className="formHeading">{"Preferred Name"}</span>
                  <span className="formValue1">
                    {this.state.searchedProfile?.preferred_name}
                  </span>
                </Grid>
                <Grid item xs={"auto"} className="profileEmployeeAlign">
                  <span className="formHeading">Role</span>
                  <span className="formValue1">
                    {this.state.searchedProfile?.role_id}
                  </span>
                </Grid>
                <Grid item xs={"auto"} className="profileEmployeeAlign">
                  <span className="formHeading">Group</span>
                  <span className="formValue1">
                    {this.state.searchedProfile?.group_name}
                  </span>
                </Grid>
                <Grid item xs={4} className="profileEmployeeAlign">
                  <span className="formHeading">Email</span>
                  <span className="formValue1">
                    {this.state.searchedProfile?.email}
                  </span>
                </Grid>
              </Grid>
              <FilterSelect
                value={this.state.filterCompany || undefined}
                data-test-id="company"
                placeholder="Company"
                onChange={this.changeCompanyName}
                style={{ width: "47%", margin: "10px" }}
              >
                {this.state.allCompanyName?.map((item) => (
                  <Select.Option value={item.company_name}>
                    {item.company_name}
                  </Select.Option>
                ))}
              </FilterSelect>
              <FilterSelect
                onChange={this.changeGroupName}
                value={this.state.filterGroup || undefined}
                placeholder="Group"
                data-test-id="group"
                style={{ width: "47%", margin: "10px" }}
              >
                {this.state.allGroupName?.map((item) => (
                  <Select.Option value={item.group_name}>
                    {item.group_name}
                  </Select.Option>
                ))}
              </FilterSelect>
            </div>
          )}
          <BackButton
            onClick={this.openNotSavedModal}
            style={{ marginBottom: "20px" }}
          >
            Cancel
          </BackButton>
          <NextButton
            data-test-id="assignProfile"
            onClick={this.assignProfile}
            style={{
              width: "65%",
              color: "#F8FAFC",
              marginLeft: "10px",
              marginBottom: "20px",
            }}
            disabled={!this.state.searchedProfile?.email}
          >
            {this.state.enableEditAssignProfile ? "Save" : "Add"}
          </NextButton>
          <ToastContainer containerId="B" position="top-right" />
        </FieldModal>
        <FieldModal
          data-test-id="notSaveModal"
          open={this.state.notSavedModal}
          onCancel={this.closeNotSavedModal}
          title={
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h1
                style={{
                  fontSize: "28px",
                  fontWeight: 500,
                  fontFamily: "Rubik",
                }}
              >
                Not Saved
              </h1>
              <hr
                style={{
                  width: "60px",
                  height: "2px",
                  color: "rgba(215, 215, 215, 0.5)",
                }}
              />
              <span
                style={{
                  fontSize: "19px",
                  fontWeight: 400,
                  fontFamily: "Rubik",
                }}
              >
                Assign Profile
              </span>
              <hr
                style={{
                  width: "60px",
                  height: "2px",
                  color: "rgba(215, 215, 215, 0.5)",
                }}
              />
            </div>
          }
          closable={false}
          centered
          destroyOnClose
          footer={null}
          width={531}
          style={{ height: "309px", padding: "30px" }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img
              style={{ width: "112px", height: "112px", marginRight: "10px" }}
              src={alarmImage}
            />
            <span
              style={{
                color: "#0F172A",
                fontFamily: "Rubik",
                fontWeight: 500,
                fontSize: "18px",
              }}
            >
              Are you sure you want to cancel this? The information you provided
              will be permanently deleted.
            </span>
          </div>
          <BackButton onClick={this.closeNotSavedModal}>Cancel</BackButton>
          <NextButton
            onClick={this.closeAssignRoleModal}
            style={{ width: "65%", color: "#F8FAFC", marginLeft: "10px" }}
          >
            Delete
          </NextButton>
        </FieldModal>
        <FieldModal
          data-test-id="employeeDetailModal"
          open={this.state.employeeDetailModal}
          onCancel={this.closeEmployeeDetailModal}
          title={
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h1
                style={{
                  fontSize: "28px",
                  fontWeight: 500,
                  fontFamily: "Rubik",
                }}
              >
                Edit Employee Profile
              </h1>
              <hr
                style={{
                  width: "100px",
                  height: "2px",
                  color: "rgba(215, 215, 215, 0.5)",
                }}
              />
              <span
                style={{
                  fontSize: "19px",
                  fontWeight: 400,
                  fontFamily: "Rubik",
                }}
              >
                {this.state.singleEmployeeDetail.preferred_name}
              </span>
              <hr
                style={{
                  width: "100px",
                  height: "2px",
                  color: "rgba(215, 215, 215, 0.5)",
                }}
              />
            </div>
          }
          centered
          closable={true}
          footer={null}
          destroyOnClose
          width={766}
          style={{ height: "536px" }}
        >
          <TextField
            disabled
            data-test-id="employee_email"
            label="Email"
            value={this.state.singleEmployeeDetail.email}
            type="text"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            data-test-id="employee_role"
            label="Role"
            value={this.state.singleEmployeeDetail.role_id}
            onChange={(event) =>
              this.updateEmployeeField("role_id", event.target.value)
            }
            type="text"
            InputLabelProps={{ shrink: true }}
            placeholder="Enter Role"
            disabled
            style={{ textTransform: "capitalize" }}
          />
          <TextField
            data-test-id="employee_preferred_name"
            label="Preferred name"
            value={this.state.singleEmployeeDetail.preferred_name}
            onChange={(event) =>
              this.updateEmployeeField("preferred_name", event.target.value)
            }
            type="text"
            InputLabelProps={{ shrink: true }}
            placeholder="Enter preferred name"
          />
          <FormDiv>
            <TextField
              data-test-id="employee_company_name"
              label="Company Name"
              value={this.state.singleEmployeeDetail.company_name}
              onChange={(event) =>
                this.updateEmployeeField("company_name", event.target.value)
              }
              type="text"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter Company Name"
            />
            <TextField
              data-test-id="Employee_number"
              label="Employee Number"
              value={this.state.singleEmployeeDetail.employee_number}
              onChange={(event) =>
                this.updateEmployeeField("employee_number", event.target.value)
              }
              type="text"
              InputLabelProps={{ shrink: true }}
              placeholder="Enter Employee Number"
            />
          </FormDiv>
          <BackButton
            data-test-id="openNotSavedModal"
            onClick={this.openNotSavedModal}
          >
            Cancel
          </BackButton>
          <NextButton
            data-test-id="saveSelectedEmployeeDetail"
            onClick={() =>
              this.updateEmployeeDetail(this.state.singleEmployeeDetail.id)
            }
            style={{ width: "65%", color: "#F8FAFC", marginLeft: "10px" }}
          >
            Save
          </NextButton>
          <ToastContainer position="top-right" containerId="C" />
        </FieldModal>
        <ToastContainer position="top-right" />
      </ThemeProvider>
            // Customizable Area End
        );
    }
}
