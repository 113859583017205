Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.getSearchbarApiEndPoint = 'bx_block_search_history/search_historys/'
exports.getSearchHistoryApiEndPoint = 'bx_block_search_history/search_historys'
exports.deleteSearchHistoryApiEndPoint = 'bx_block_search_history/search_historys'
exports.getAdvacedSearchApiEndPoint = 'bx_block_advanced_search/advanced_search'
exports.errorTitle = 'Error'
exports.profileGetURL = "bx_block_profile/courses/user_role";
exports.addSearchHistoryEndPoint = "bx_block_search_history/search_historys"
// Customizable Area End
