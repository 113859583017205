// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import ImagePicker from "react-native-image-crop-picker";
import { RichEditor } from "react-native-pell-rich-editor";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { FieldValue } from "../../customisableuserprofiles/src/CustomisableUserProfilesController";
import { RadioChangeEvent } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

export interface ItemType {
  id: string;
  type: string;
  attributes: {
    item: string;
    created_at: string;
    content_type: string;
  };
}
export interface ItemTypeFlatList {
  item: ItemType;
}

export interface DocObj {
  id?: number,
  url?: string
}
export interface BulletinType {
  "id": string;
  "type": string;
  "attributes": {
    "id": number;
    "name": string;
    "description": string;
    "body": string;
    "location": string;
    "account_id": number;
    "preferred_name": string;
    "role_id": string;
    "created_at": string;
    "updated_at": string;
    "images": DocObj[];
    "videos": DocObj[];
    "documents": DocObj[];
    "link": string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  itemList: ItemType[];
  isModalVisible: boolean;
  isEditModal: boolean;
  editItemId: null | string;
  editItemData: ItemType | null;
  isCalenderOpen: boolean;
  currentDate: string;
  isLoading: boolean;
  themeStatus: boolean;
  menuStatus: boolean;
  totalPage: number;
  currentPage: number;
  filterDate: string;
  anchorElNav: null | HTMLElement;
  bulletinModal: boolean;
  publishTypeKey: string;
  selectedGroupIds: number[];
  uploadedImages: File[];
  uploadedVideos: File[];
  description: string;
  title: string;
  link: string;
  publishTypeModal: boolean;
  reportBulletinModal: boolean;
  reportReason: string;
  specificGroupModal: boolean;
  apiSuccessModal: boolean;
  deleteModal: boolean;
  bulletinList: BulletinType[];
  editBulletinKey: boolean;
  editBulletinId: number;
  profileId: number;
  deleteBulletinKey: boolean;
  viewBulletinKey: boolean;
  reportBulletinKey: boolean;
  successMessage: string;
  document: File[];
  groupNames: { "attributes": { [key: string]: FieldValue } }[];
  companyNames: { "attributes": { [key: string]: FieldValue } }[];
  managerNames: { "attributes": { [key: string]: FieldValue } }[];
  searchGroupKey: string;
  userProfile: { "attributes": { [key: string]: FieldValue } };
  selectedBulletin: BulletinType;
  dateFilterValue: string;
  roleFilterValue: string;
  openImageViewer: boolean;
  currentImageIndex: number;
  previewImages: string[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BulletinBoardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  richText: React.RefObject<RichEditor>;
  getItemsAPICallId: string = "";
  editItemAPICallId: string = "";
  postItemAPICallId: string = "";
  deleteItemAPICallId: string = "";
  addBulletinApiCallId: string = "";
  getBulletinApiCallId: string = "";
  editBulletinApiCallId: string = "";
  deleteBulletinApiCallId: string = "";
  reportBulletinAPiCallId: string = "";
  getGroupNamesCallId: string = "";
  getCompaniesCallId: string = "";
  getManagersCallId: string = "";
  getUserProfileCallId: string = "";
  getFilterBulletinCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      itemList: [],
      isModalVisible: false,
      isEditModal: false,
      editItemId: null,
      editItemData: null,
      currentDate: "",
      isCalenderOpen: false,
      isLoading: false,
      themeStatus: false,
      menuStatus: false,
      totalPage: 1,
      currentPage: 1,
      filterDate: "all",
      anchorElNav: null,
      bulletinModal: false,
      publishTypeKey: "",
      selectedGroupIds: [],
      uploadedImages: [],
      uploadedVideos: [],
      description: "",
      title: "",
      link: "",
      publishTypeModal: false,
      reportBulletinModal: false,
      reportReason: "",
      specificGroupModal: false,
      apiSuccessModal: false,
      deleteModal: false,
      bulletinList: [],
      editBulletinKey: false,
      deleteBulletinKey: false,
      viewBulletinKey: false,
      reportBulletinKey: false,
      editBulletinId: 1,
      profileId: 1,
      successMessage: "",
      document: [],
      groupNames: [],
      managerNames: [],
      companyNames: [],
      searchGroupKey: "",
      userProfile: { "attributes": {} },
      selectedBulletin: {
        "id": "",
        "type": "",
        "attributes": {
          "id": 1,
          "name": "",
          "description": "",
          "body": "",
          "location": "",
          "account_id": 0,
          "preferred_name": "",
          "role_id": "",
          "created_at": "",
          "updated_at": "",
          "images": [],
          "videos": [],
          "documents": [],
          "link": "",
        }
      },
      dateFilterValue: "",
      roleFilterValue: "",
      openImageViewer: false,
      currentImageIndex: 0,
      previewImages: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.richText = React.createRef<RichEditor>();
    // Customizable Area End
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.setState({ isLoading: false });

    if (responseJson && !responseJson.errors) {
      if (apiRequestCallId === this.getItemsAPICallId) {
        this.setState({
          isLoading: false,
          isModalVisible: false,
          itemList: responseJson.data,
        });
      } else if (apiRequestCallId === this.postItemAPICallId) {
        this.setState({
          isLoading: false,
          isModalVisible: false,
          txtInputValue: "",
        });
        this.getItemsApi();
      } else if (apiRequestCallId === this.deleteItemAPICallId) {
        this.setState({ isLoading: false, isModalVisible: false });
        this.getItemsApi();
        this.showAlert("Success", responseJson.message);
      } else if (apiRequestCallId === this.editItemAPICallId) {
        this.getItemsApi();
        this.setState({
          editItemData: null,
          isEditModal: false,
          isModalVisible: false,
          editItemId: null,
          txtInputValue: "",
          isLoading: false,
          itemList: [],
        });
      }
      else {
        this.bulletinResponses(message);
      }
    } else {
      this.setState({ isLoading: false, bulletinModal: false });
      if (responseJson.errors[0].token) {
        toast.error(responseJson.errors[0].token)
        const msg: Message = new Message(
          getName(MessageEnum.NavigationEmailLogInMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (textValue: string) => {
      this.setState({ txtInputValue: textValue });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let messageData = new Message(getName(MessageEnum.AccoutLoginSuccess));
    messageData.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(messageData);
  }

  // web events
  setInputValue = (textValue: string) => {
    this.setState({ txtInputValue: textValue });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  openModal = () => this.setState({ isModalVisible: true, isLoading: true });

  openEditModal = (item: ItemType, currentItemId: string) => {
    this.setState({
      isEditModal: true,
      editItemData: item,
      isModalVisible: true,
      editItemId: currentItemId,
      txtInputValue: item.attributes.item,
      isLoading: true,
    });
  };

  uploadPhoto = async () => {
    try {
      const selectedImage = await ImagePicker.openPicker({});
      const imageData = {
        uri: selectedImage.path,
        name: selectedImage.modificationDate,
        type: selectedImage.mime,
      };
      this.addItemApi({ type: "image", imageData });
    } catch (errorMessage) {
      this.showAlert(configJSON.errorLable, JSON.stringify(errorMessage));
    }
  };

  returnDate = (dateString: string) => {
    const currentDate = new Date(dateString);
    const dayDate = currentDate.getDate();
    const monthIndex = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const months = configJSON.listOfMonths;
    const monthName = months[monthIndex];
    return `${dayDate} ${monthName} ${year}`;
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getBulletin()
    this.getUserProfile()
    this.checkTheme();
    window.addEventListener('storage', this.handleStorageChange)

    let menuState: any = localStorage.getItem('open');
    if (menuState != null) {
      this.setState({ menuStatus: JSON.parse(menuState) })
    }
    let currentDate = new Date().toISOString().split("T")[0];
    this.setState({ currentDate }, () => this.getItemsApi());
    this.btnShowHideProps.onPress();
  }

  handleStorageChange(event :any) {
    if (event.key === 'darkMode') {
      this.checkTheme();
    }
  }

  removeHTML = (textString: string) =>
    textString
      .replace(/<[^>]*>/g, "")
      .replace(/&([^\s&;]+);/g, "")
      .trim();

  saveBtnPress = () => {
    const { txtInputValue } = this.state;
    if (this.removeHTML(txtInputValue).length > 0) {
      if (this.state.isEditModal) {
        this.editItemApi();
      } else {
        this.addItemApi({ type: "text" });
      }
    }
  };

  checkTheme = async () => {
    const mode = await getStorageData("darkMode");
    if (mode === 'true') {
      this.setState({ themeStatus: true })
    }
    else {
      this.setState({ themeStatus: false })
    }
  }

  getItemsApi = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getItemsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getItemsAPiEndPoint + "?date=" + this.state.currentDate
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteItemApi = (deleteItemId: string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteItemAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mainAPiEndPoint + deleteItemId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  editItemApi = () => {
    const header = {
      "Content-Type": configJSON.formDataAPIType,
    };
    let formData = new FormData();

    formData.append("item", this.state.txtInputValue);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editItemAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mainAPiEndPoint + this.state.editItemId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addItemApi = (itemData: {
    type: string;
    imageData?: {
      uri: string;
      name: string | undefined;
      type: string;
    };
  }) => {
    const header = {
      "Content-Type": configJSON.formDataAPIType,
    };

    let formData = new FormData();

    if (itemData.imageData) {
      formData.append("content_type", "image");
      formData.append("image", JSON.parse(JSON.stringify(itemData.imageData)));
    } else {
      formData.append("content_type", "text");
      formData.append("item", this.state.txtInputValue);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postItemAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mainAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onCalendarPress = (selectedDay: { dateString: string }) => {
    this.setState(
      { currentDate: selectedDay.dateString, isCalenderOpen: false },
      () => this.getItemsApi()
    );
  };

  openCalendar = () => this.setState({ isCalenderOpen: true });

  bulletinResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.addBulletinApiCallId) {
      if (responseJson?.error) {
        toast.error(responseJson.error)
      } else {
        this.setState({
          title: "", selectedGroupIds: [], description: "", link: "", uploadedImages: [],
          document: [], uploadedVideos: [], isLoading: false,
          successMessage: 'publish', apiSuccessModal: true, publishTypeKey: "",
        }, () => this.getBulletin());
      }
    } else if (apiRequestCallId === this.getBulletinApiCallId) {
      this.setState({
        totalPage: responseJson.meta.total_pages,
        bulletinList: responseJson.data
      })
    } else if (apiRequestCallId === this.editBulletinApiCallId) {
      if (responseJson.hasOwnProperty("message")) {
        toast.error(responseJson.message)
      } else {
        this.setState({
          bulletinModal: false, apiSuccessModal: true, specificGroupModal: false,
          successMessage: 'edited', publishTypeKey: "",
          selectedGroupIds: [], title: "", description: "", link: "",
          uploadedImages: [], uploadedVideos: [], document: [], isLoading: false
        }, () => this.getBulletin());
      }
    } else if (apiRequestCallId === this.deleteBulletinApiCallId) {
      this.setState({
        apiSuccessModal: true, deleteModal: false, successMessage: 'deleted'
      }, () => this.getBulletin());
    } else if (apiRequestCallId === this.getGroupNamesCallId) {
      this.setState({ groupNames: responseJson.data })
    } else if (apiRequestCallId === this.getCompaniesCallId) {
      this.setState({ companyNames: responseJson.data })
    } else if (apiRequestCallId === this.getManagersCallId) {
      this.setState({ managerNames: responseJson.data })
    } else if (apiRequestCallId === this.getUserProfileCallId) {
      this.setState({ userProfile: responseJson.data })
    } else if (apiRequestCallId === this.getFilterBulletinCallId) {
      this.setState({ totalPage: responseJson.meta.total_pages, bulletinList: responseJson.data })
    }
  }

  toggleMenu = () => {
    this.setState({ menuStatus: !this.state.menuStatus })
    setStorageData('open', JSON.stringify(!this.state.menuStatus))
  }

  toggleTheme = () => {
    this.setState({ themeStatus: !this.state.themeStatus })
    setStorageData('darkMode', `${!this.state.themeStatus}`)
  }

  changePageNumber = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value }, () => this.getBulletin())
  }

  changeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: event.target.value })
  }

  changeLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ link: event.target.value })
  }

  OpenNavMenu = (event: React.MouseEvent<HTMLElement>, id: number, profileId: number) => {
    this.setState({ anchorElNav: event.currentTarget, editBulletinId: id, profileId: profileId });
  };

  closeNavMenu = () => {
    this.setState({ anchorElNav: null })
  }

  openBulletinModal = () => {
    this.setState({ bulletinModal: true })
  }

  closeBulletinModal = () => {
    this.setState({
      bulletinModal: false, deleteModal: false, title: "", description: "",
      uploadedImages: [], uploadedVideos: [], document: [], link: ""
    })
  }

  changePublishType = (event: RadioChangeEvent) => {
    this.setState({ publishTypeKey: event.target.value })
  }

  changeReportType = (event: RadioChangeEvent) => {
    this.setState({ reportReason: event.target.value })
  }

  selectGroup = (event: CheckboxChangeEvent, Value: FieldValue) => {
    let newArray = this.state.selectedGroupIds;
    if (event.target.checked) {
      newArray.push(Number(Value))
      this.setState({ selectedGroupIds: newArray })
    } else {
      newArray = newArray.filter((item) => item != Value)
      this.setState({ selectedGroupIds: newArray })
    }
  }

  removeGroup = (itemId: FieldValue) => {
    let newArray = this.state.selectedGroupIds.filter((item) => item != itemId)
    this.setState({ selectedGroupIds: newArray })

  }

  uploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    let existImages = this.state.uploadedImages
    let existVideos = this.state.uploadedVideos
    if (file) {
      if (file && file.type.startsWith('image/')) {
        existImages.push(file)
        this.setState({ uploadedImages: existImages });
      } else {
        existVideos.push(file)
        this.setState({ uploadedVideos: existVideos })
      }
    }
  }

  uploadDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    let existDoc = this.state.document
    if (file && file.type.startsWith('application/')) {
      existDoc.push((file))
      this.setState({ document: existDoc });
    }
  }

  removeDocument = (index: number) => {
    let existDoc = this.state.document
    let newDocs = existDoc.filter((item, indexData) => index != indexData)
    this.setState({ document: newDocs })
  }

  removeImage = (index: number) => {
    let existImages1 = this.state.uploadedImages
    let newImages1 = existImages1.filter((item, indexData) => index != indexData)
    this.setState({ uploadedImages: newImages1 })
  }

  removeVideo = (index: number) => {
    let existVideos1 = this.state.uploadedVideos
    let newVideos1 = existVideos1.filter((item, indexData) => index != indexData)
    this.setState({ uploadedVideos: newVideos1 })
  }

  apiCall = async (data: { [key: string]: string | FormData }) => {
    const { method3, endPoint3, body3, type3, contentType3 } = data;
    let apiBody = body3;
    if (type3 === '') {
      apiBody = JSON.stringify(body3);
    }
    const header = {
      token: await getStorageData("authToken"),
      "Content-Type": contentType3,
    };
    const requestMessage2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint3
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.baseURL
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method3
    );

    body3 &&
      requestMessage2.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );

    runEngine.sendMessage(requestMessage2.id, requestMessage2);

    return requestMessage2.messageId;
  };

  addNewBulletin = async () => {
    this.setState({
      bulletinModal: false, publishTypeModal: false, specificGroupModal: false, isLoading: true
    })

    let formData = new FormData()
    formData.append("[name]", this.state.title)
    formData.append("[description]", this.state.description)

    this.state.uploadedImages?.forEach((file) => {
      formData.append(`[images][]`, file);
    });

    this.state.uploadedVideos?.forEach((file) => {
      formData.append(`[videos][]`, file);
    });

    this.state.document?.forEach((file) => {
      formData.append(`[documents][]`, file);
    });

    this.state.selectedGroupIds.forEach((value: number) => {
      formData.append('publish_to_ids[]', value.toString());
    });

    formData.append("[link]", this.state.link)
    formData.append("[publish_to]", this.state.publishTypeKey)

    this.addBulletinApiCallId = await this.apiCall({
      method3: configJSON.exampleAPiMethod,
      endPoint3: configJSON.createBulletinEndPoint,
      body3: formData
    });
  }

  getBulletin = async () => {
    this.getBulletinApiCallId = await this.apiCall({
      method3: configJSON.validationApiMethodType,
      contentType3: configJSON.exampleApiContentType,
      endPoint3: `${configJSON.createBulletinEndPoint}?per_page=3&page=${this.state.currentPage}`
    });
  }

  editBulletin = async () => {
    this.setState({
      editBulletinKey: true,
      bulletinModal: true,
      anchorElNav: null,
    })
    this.state.bulletinList?.map(async (item) => {
      if (item.attributes.id === this.state.editBulletinId) {

        const filePromises = item.attributes.images.map((file1: any) =>
          this.convertToFile(file1.url, file1.filename, file1.content_type)
        );

        const videoPromises = item.attributes.videos.map((file1: any) =>
          this.convertToFile(file1.url, file1.filename, file1.content_type)
        );

        const docPromises = item.attributes.documents.map((file1: any) =>
          this.convertToFile(file1.url, file1.filename, file1.content_type)
        );

        this.setState({
          title: item.attributes.name,
          description: item.attributes.description,
          link: item.attributes.link
        })

        const image = await Promise.all(filePromises);
        this.setState({ uploadedImages: image.length > 0 ? image : [] })

        const video = await Promise.all(videoPromises);
        this.setState({ uploadedVideos: video.length > 0 ? video : [] })

        const doc = await Promise.all(docPromises);
        this.setState({ document: doc.length > 0 ? doc : [] })

      }
    })
  }

  convertToFile = async (imageUrl: string, filename: string, contentType: string): Promise<File> => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new File([blob], filename, { type: blob.type });
  };

  editBulletinApi = async () => {
    let formData = new FormData()
    formData.append("[name]", this.state.title)
    formData.append("[description]", this.state.description)

    this.state.uploadedImages?.forEach((file1) => {
      formData.append(`[images][]`, file1);
    });

    this.state.uploadedVideos?.forEach((file1) => {
      formData.append(`[videos][]`, file1);
    });

    this.state.document?.forEach((file1) => {
      formData.append(`[documents][]`, file1);
    });

    this.state.selectedGroupIds.forEach((value: number) => {
      formData.append('publish_to_ids[]', value.toString());
    });

    formData.append("[publish_to]", this.state.publishTypeKey)
    formData.append("[link]", this.state.link)

    this.editBulletinApiCallId = await this.apiCall({
      endPoint3: `${configJSON.createBulletinEndPoint}/${this.state.editBulletinId}`,
      method3: configJSON.PUT,
      body3: formData
    });
  }

  deleteBulletin = () => {
    this.setState({
      deleteBulletinKey: true,
      anchorElNav: null,
      deleteModal: true,
    })
  }

  viewBulletin = () => {
    this.setState({
      viewBulletinKey: true,
      anchorElNav: null
    })
    this.state.bulletinList?.map((item) => {
      if (item.attributes.id === this.state.editBulletinId) {
        this.setState({ selectedBulletin: item })
      }
    })
  }

  reportBulletinApi = async () => {
    this.setState({ reportBulletinModal: false })
    this.reportBulletinAPiCallId = await this.apiCall({
      endPoint3: `${configJSON.createBulletinEndPoint}/${this.state.editBulletinId}`,
      method3: configJSON.PUT,
      body3: ""
    });
  }

  reportBulletin = () => {
    this.setState({ reportBulletinKey: true, anchorElNav: null, reportBulletinModal: true })
  }

  closeReportBulletinModal = () => {
    this.setState({ reportBulletinModal: false, reportBulletinKey: false, reportReason: "" })
  }

  deleteBulletinApi = async () => {
    this.deleteBulletinApiCallId = await this.apiCall({
      method3: configJSON.DELETE,
      contentType3: configJSON.exampleApiContentType,
      endPoint3: `${configJSON.createBulletinEndPoint}/${this.state.editBulletinId}`,
    });
  }

  openPublishTypeModal = () => {
    if (!this.state.title) {
      toast.error("Please Add Title", { containerId: "A" })
    } else if (!this.state.description) {
      toast.error("Please Add Description", { containerId: "A" })
    } else if (this.state.userProfile.attributes.role_id === 'student') {
      this.addNewBulletin()
    } else {
      this.setState({ publishTypeModal: true }, () => {
        this.getGroupNames();
        this.getCompanies();
        this.getManagers();
      })
    }
  }

  openSpecificGroupModal = () => {
    this.setState({ specificGroupModal: true })
  }

  closeSpecificGroupModal = () => {
    this.setState({ specificGroupModal: false, selectedGroupIds: [] })
  }

  closePublishTypeModal = () => {
    this.setState({ publishTypeModal: false, apiSuccessModal: false, reportBulletinKey: false })
  }

  getGroupNames = async () => {
    this.getGroupNamesCallId = await this.apiCall({
      contentType3: configJSON.exampleApiContentType,
      method3: configJSON.validationApiMethodType,
      endPoint3: `${configJSON.getGroupNamesEndPoint}?query=${this.state.searchGroupKey}`
    });
  }

  getCompanies = async () => {
    this.getCompaniesCallId = await this.apiCall({
      contentType3: configJSON.exampleApiContentType,
      method3: configJSON.validationApiMethodType,
      endPoint3: `${configJSON.getCompaniesEndPoint}?query=${this.state.searchGroupKey}`
    });
  }

  getManagers = async () => {
    this.getManagersCallId = await this.apiCall({
      contentType3: configJSON.exampleApiContentType,
      method3: configJSON.validationApiMethodType,
      endPoint3: `${configJSON.getManagersEndPoint}?query=${this.state.searchGroupKey}`
    });
  }

  getUserProfile = async () => {
    this.getUserProfileCallId = await this.apiCall({
      contentType3: configJSON.exampleApiContentType,
      method3: configJSON.validationApiMethodType,
      endPoint3: configJSON.getUserProfileEndPoint
    });
  }

  changeSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchGroupKey: event.target.value })
  }

  searchGroup = (event: React.KeyboardEvent | { key: string }) => {
    if (event.key === 'Enter') {
      if (this.state.publishTypeKey === "specific_groups") {
        this.getGroupNames()
      }
      if (this.state.publishTypeKey === "specific_companies") {
        this.getCompanies()
      }
      if (this.state.publishTypeKey === "specific_managers") {
        this.getManagers()
      }
    }
  }

  changeDateFilterValue = async (event: string) => {
    this.setState({ dateFilterValue: event })
    this.getFilterBulletinCallId = await this.apiCall({
      method3: configJSON.validationApiMethodType,
      endPoint3: `${configJSON.createBulletinEndPoint}?role=${this.state.roleFilterValue}&date=${event}`,
      contentType3: configJSON.exampleApiContentType,
    });
  }

  changeRoleFilterValue = async (event: string) => {
    this.setState({ roleFilterValue: event })
    this.getFilterBulletinCallId = await this.apiCall({
      contentType3: configJSON.exampleApiContentType,
      endPoint3: `${configJSON.createBulletinEndPoint}?role=${event}&date=${this.state.dateFilterValue}`,
      method3: configJSON.validationApiMethodType,
    });
  }

  countChar = (event: React.KeyboardEvent) => {
    if (this.state.description.length >= 500) {
      event.preventDefault()
    }
  }

  backSpecificModal = () => {
    this.setState({
      specificGroupModal: false,
      searchGroupKey: "",
      selectedGroupIds: [],
    }, () => {
      this.getGroupNames();
      this.getCompanies();
      this.getManagers();
    })
  }

  openImageViewer = (index: number, images: any[]) => {
    let Images = images.map(image => image.url)
    this.setState({ openImageViewer: true, currentImageIndex: index, previewImages: Images })
  };

  closeImageViewer = () => {
    this.setState({ openImageViewer: false, currentImageIndex: 0 })
  };
  // Customizable Area End
}

// Customizable Area End
