export const edit = require("../assets/edit.png");
export const All = require("../assets/All.svg");
export const group = require("../assets/group.svg");
export const specific = require("../assets/specific.svg");
export const lightVerify = require("../assets/verificationModal.svg");
export const darkVerify = require("../assets/verification_dark.svg");
export const allBlack = require("../assets/allBlack.svg");
export const groupBlack = require("../assets/groupBlack.svg");
export const allManagerWhite = require("../assets/allManagerWhite.svg");
export const allManagersBlack = require("../assets/allManagersBlack.svg");
export const SpecificBlack = require("../assets/SpecificBlack.svg");
export const addFileIcon = require("../assets/addFileIcon.svg");
export const albumCollection = require("../assets/album-collection.svg");
export const chartUser = require("../assets/chart-user.svg");
export const circleMicrophone = require("../assets/circle-microphone.svg");
export const document = require("../assets/document.svg");
export const graphicStyle = require("../assets/graphic-style.svg");
export const screenPlay = require("../assets/screen-play.svg");
export const Vector = require("../assets/Vector.png");
export const wpfSurvey = require("../assets/wpf_survey.svg");
export const iIcon = require("../assets/iIcon.svg");
export const copyIcon = require("../assets/copyIcon.svg");
export const addIcon = require("../assets/addIcon.png");
export const smallEdit = require("../assets/smallEdit.png");
export const smallBin = require("../assets/smallBin.png");
export const addChapterIcon = require("../assets/addChapterIcon.png");
export const nextIcon = require("../assets/nextIcon.png");
export const handbrakeIcon = require("../assets/handbrakeIcon.png");