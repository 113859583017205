export const mailIcon = require("../assets/mailIcon.svg");
export const bellIcon = require("../assets/bellIcon.svg");
export const profileImage = require("../assets/profilePic.svg");
export const progressIcon = require("../assets/progress.svg");
export const timeIcon = require("../assets/timeIcon.svg");
export const averageScoreIcon = require("../assets/averageScoreIcon.svg");
export const completedIcon = require("../assets/completedIcon.svg");
export const certificateIcon = require("../assets/certificateIcon.svg");
export const courseOne = require("../assets/courseOne.png");
export const courseTwo = require("../assets/courseTwo.png");
export const courseThree = require("../assets/courseThird.png");
export const arrowView = require("../assets/arrowViewIcon.png");
export const personsIcons = require("../assets/personsIcons.png");
export const chatIcon = require("../assets/chatIcon.png");
export const person1 = require("../assets/person1.png");
export const person2 = require("../assets/person2.png");
export const starRatingIcon = require("../assets/starRatingIcons.png");
export const closeIcon = require("../assets/closeIcon.png");
export const pinIcon = require("../assets/pinIcon.png");
export const sendIcon = require("../assets/sendIcon.png");
export const sortIcon = require("../assets/sortIcon.png");
export const templateOne = require("../assets/templateOne.png");
export const templateTwo = require("../assets/templateTwo.png");
export const templateThree = require("../assets/templateThree.png");
export const templateFour = require("../assets/templateFour.png");
export const templateFive = require("../assets/templateFive.png");
export const moreIcon = require("../assets/moreIcon.png");
export const oneStarIcon = require("../assets/oneStarIcon.png");
export const twoStarIcon = require("../assets/twoStarIcon.png");
export const threeStarIcon = require("../assets/threeStarIcon.png");
export const fourStarIcon = require("../assets/fourStarIcon.png");
export const fiveStarIcon = require("../assets/fiveStarIcon.png");
export const selectedStarIcon = require("../assets/selectedStarIcon.png");
export const messageIcon = require("../assets/messageManagerIcon.png");
export const order = require("../assets/order.svg");
export const arrowRight = require("../assets/arrowRight.png");
