Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.httpPutApiMethod = "PUT";

exports.btnExampleTitle = "CLICK ME";
exports.getProfileApiEndPoint = "bx_block_profile/profiles/show_profile";
exports.updatePasswordApiEndPoint = "bx_block_profile/change_password";
exports.sendOtpApiEndPoint = 'bx_block_profile/send_sms_otp';
exports.verifyOtpApiEndPoint = 'bx_block_profile/verify_sms_otp';
exports.updatePhoneApiEndPoint = 'bx_block_profile/change_phone_number';
exports.getMessageListEndPoint = 'bx_block_settings/message_settings';
exports.updateMessageCategory = 'bx_block_settings/message_categories';
exports.updateAllMessageEndPoint = 'bx_block_settings/message_settings';
exports.updateProfileEndPoint = 'bx_block_profile/profiles/update_appearance';
exports.verifyPasswordApiEndPoint = 'bx_block_profile/verify';
exports.getCustomHeaderApiEndPoint = 'bx_block_landingpage2/custom_header'
// Customizable Area End